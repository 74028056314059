import { Button, Dropdown, Menu, Select, Space } from "antd";
import { Option } from "antd/lib/mentions";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_filters.css";
import { ReactComponent as DropDownIcon } from "../../../ArrowDownIcon.svg";

class AppDropdownFilter extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    //console.log(value);
    this.props.onSelectedDateFilter(value);
  }

  renderDateByCaption() {
    let filters = [];
    if (this.props.dataDateByCaption.length > 0) {
      for (let i = 0; i < this.props.dataDateByCaption.length; i++) {
        let record = {};
        record = {
          caption: this.props.dataDateByCaption[i],
          value: this.props.dataDateByValue[i],
        };
        filters.push(record);
      }
    }

    return filters.map((item) => {
      return (
        <Option value={item.value} className="dropdown-filter__item">
          {item.caption}
        </Option>
      );
    });
  }

  render() {
    return (
      <>
        <div>
          <div className="dropdown-filter d-flex justify-content-center align-items-center">
            <div className="pl-3 dropdown-filter__label">Show Within :</div>
            <Select
              style={{
                width: 200,
              }}
              size={"large"}
              placeholder={this.props.placeholder}
              onChange={this.handleChange}
              bordered={false}
              suffixIcon={<DropDownIcon />}
              defaultValue={this.props.defaultValue}
              value={this.props.value}
            >
              {this.renderDateByCaption()}
            </Select>
          </div>
        </div>
      </>
    );
  }
}

export default AppDropdownFilter;
