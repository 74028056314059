import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import animationData from '../../../../lottie/splash_lottie.json';
import AppLottie from '../../../component/lottie/app_lottie';

class SplashScreen extends React.Component {
  
  constructor(props) {
    super(props)
  }

    render() {
      return (
        <div
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh'
        }}>
          <div
            style={{
              maxWidth: '200px',
              maxHeight: '200px',
            }}
          >
            <AppLottie
                    width={200}
                    height={200}
                    animationData={animationData}
                  />
          </div>
        </div>
      );
    }
}
export default withRouter(SplashScreen)
