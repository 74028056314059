import { Col, Pagination, Row } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AnnouncementsBloc from "../../../bloc/announcements_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import App_announcement from "../../component/app_announcement/app_announcement";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import { connect } from "react-redux";
import HomeBloc from "../../../bloc/home_bloc";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import Base_screen from "../base/base_screen/base_screen";
import { setFilterBy } from "../../../data/redux/reducers/filterReducer";
import { setUserData } from "../../../data/redux/reducers/userDataReducer";
import { setFilterBy1 } from "../../../data/redux/reducers/filterReducer1";
import { setSearchBy } from "../../../data/redux/reducers/searchByReducer";
import { setUserConfig } from "../../../data/redux/reducers/userConfigReducer";
import { ReactComponent as LeftArrowIcon } from "../../../Arrow_Left.svg";
import { ReactComponent as RightArrowIcon } from "../../../Arrow_Right.svg";
import "./announcements_screen.css";

const showTotal = (total) => `1 - 10 of ${total} items`;

class AnnouncementsScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      client: Config.context,
      route: routeParams,
      announcementData: [],
      userData: {},
      loading: true,
      currentPage: 1,
      totalData: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  stateCurrentPage() {
    return this.state.currentPage || 1;
  }

  stateTotalData() {
    return this.state.totalData || 0;
  }

  async componentDidMount() {

  }

  async handleChange(page) {
    await this.setState({
      currentPage: page
    })
    await this.getAnnouncementData(page);
  }

  renderAnnouncements() {
    return this.state.announcementData && this.state.announcementData.map((item) => {      
      return (
        <Col span={12} className="mb-2">
          <App_announcement
            data={item}
            route={this.state.route.entity}
            client={this.state.client}
          />
        </Col>
      );
    });
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  async getAnnouncementData(page) {
    this.setState({
      loading: true
    })
    let announcementList = await HomeBloc.getAnnouncementBanners(
      page
    )
    let userData = this.props.userDataGlobal.data;
    this.setState({
      announcementData: AnnouncementsBloc.getBeanAnnouncementList(announcementList[0]),
      totalData: announcementList[1].recCount,
      userData,
      loading: false
    });
  }

  async init() {
    this.getAnnouncementData(this.stateCurrentPage())
  }

  handleShowIndexOfPages() {
    let startItem = (this.stateCurrentPage() - 1) * 10 + 1;
    let endItem = startItem - 1 + this.state.announcementData.length;
    let record = {
      startItem: startItem,
      endItem: endItem,
    };
    return record;
  }

  render() {
    return (
      <>
        <Base_screen
          listener={() => {
            this.init();
          }}
        >
          {this.state.loading && this.loadingContent()}
          <App_header data={this.state.userData} route={this.state.route.entity} />
          <App_sidebar route={this.state.route.entity}>
            <App_header_title_simple title="All Announcements" />
            <div
              style={{
                paddingTop: "32px",
              }}
            >
              <Row
                style={{
                  marginBottom: "60px",
                }}
                gutter={32}
              >
                {this.renderAnnouncements()}
              </Row>
              <div>
                {this.state.announcementData.length ? (
                  <div style={{ padding: 16 }}
                  className="align-items-center d-flex justify-content-between">
                    <div>
                      Showing {this.handleShowIndexOfPages().startItem} -{" "}
                      {this.handleShowIndexOfPages().endItem} of{" "}
                      {this.stateTotalData()} items
                    </div>
                    <div className="d-flex align-items-center" style={{marginRight: "-35px"}} >
                      <div className="page_title_paggination">Page</div>
                      <Pagination
                        current={this.stateCurrentPage()}
                        total={this.stateTotalData()}
                        onChange={this.handleChange}
                        style={{ bottom: "0px" }}
                        nextIcon={<RightArrowIcon />}
                        prevIcon={<LeftArrowIcon />}                        
                        showLessItems
                        defaultPageSize={6}
                        size="default"                                             
                      />
                      <div
                        className="page_total_paggination"
                      >
                        of {Math.ceil(this.stateTotalData() / 6)}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </App_sidebar>
        </Base_screen>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userGlobal: state.user.value,
    globalFilterBy: state.filter.value,
    globalSearchBy: state.searchBy.value,
    userConfigGlobal: state.userConfig.value,
    userDataGlobal: state.userData.value
    //globalFilterByUpcoming: state.filter1.value,
  };
};

const mapDispatchToProps = { setFilterBy, setSearchBy, setFilterBy1, setUserData, setUserConfig };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnnouncementsScreen)
);
