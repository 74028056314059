import React from "react";
import "./app_content_field_item.css";
import GeneralBloc from "../../../bloc/general_bloc";

class AppContentFieldItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      value: props.value,
    };
  }

  stateTitle() {
    let title = this.state.title;

    // if (this.state.title == "Risk Commencement Date") {
    //   title = `Covered Period`;
    // }
    return title;
  }
  stateValue() {
    let value = this.state.value;
    if (this.state.title.includes("Age")) {
      if(value != "N/A" ) {
        value = `${value} Years Old`;
      }
    }

    if (
      this.state.title.includes("Sum Covered") ||
      this.state.title == "Basic Contribution" ||
      this.state.title == "Contribution" ||
      this.state.title == "Rider Contribution" ||
      this.state.title == "Annualised Contribution" ||
      this.state.title == "Total Collected Contribution" ||
      this.state.title == "Regular Topup Contribution" ||
      this.state.title == "Total Adhoc Topup Contribution" ||
      this.state.title == "Regular Payout Amount" ||
      this.state.title == "Contribution Due Amount" ||
      this.state.title == "Maturity Payout Amount" ||
      this.state.title == "Critical Illness Rider Sum Covered"
    ) {
      if (this.state.value == "N/A") {
        value = this.state.value;
      } else {
        let val = GeneralBloc.convertToCurrencyRM(value);
        value = val;
      }
    }

    // if (this.state.title == "Risk Commencement Date") {
    //   value = `${this.props.data.riskCommencementDate.value} to ${this.props.data.riskCeaseDate.value}`;
    // }
    return value;
  }

  componentDidMount() {}

  render() {
    return (
      <div className="app-content-field">
        <div className="app-content-field-tag">{this.stateTitle() || ""}</div>
        <div>{this.stateValue() || ""}</div>
      </div>
    );
  }
}

export default AppContentFieldItem;
