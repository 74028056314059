import React from "react";
import AppContentFieldItem from "./app_content_field_item";
import { Col, Collapse, Row } from "antd";
import "./customer_details_card.css";
import Config from "../../../Config";

const { Panel } = Collapse;

class ClaimDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  stateData() {
    return this.props.data;
  }

  stateModule() {
    return this.props.module || "";
  }

  stateEntity() {
    return this.props.entity;
  }

  stateFields() {
    console.log("check fields", this.props.entity);
    return (
      Config[this.stateEntity()][this.stateModule()][
        this.stateNameOfComponent()
      ] || []
    );
  }

  stateNameOfComponent() {
    return "claimDetails";
  }

  stateProductName() {
    return this.stateData().productName;
  }

  stateCertStatus() {
    return this.stateData().certStatus;
  }

  stateCertNum() {
    return this.stateData().certNum;
  }

  stateSumCovered() {
    return this.stateData().sumCovered;
  }

  stateCoverageTerm() {
    return this.stateData().coverageTerm;
  }

  statePaymentType() {
    return this.stateData().paymentType;
  }

  stateContribution() {
    return this.stateData().contribution;
  }
  stateContributionFreq() {
    return this.stateData().contributionFreq;
  }
  stateBasicContribution() {
    return this.stateData().basicContribution;
  }
  stateRiderContribution() {
    return this.stateData().riderContribution;
  }
  stateRegularTopupContribution() {
    return this.stateData().regularTopupContribution;
  }

  componentDidMount() {}

  renderField(field) {
    if (field)
      return (
        <AppContentFieldItem
          data={this.stateData()}
          title={field.caption}
          value={
            field.value == "" || field.value == 0.0 || field.value == null
              ? "N/A"
              : field.value
          }
        />
      );

    return <div />;
  }

  renderRow(item1, item2) {
    return (
      <Row>
        <Col span={12}>{item1}</Col>
        <Col span={12}>{item2}</Col>
      </Row>
    );
  }

  renderFielsFromObject() {
    return (
      <>
        {this.stateFields() &&
          this.stateFields().map((fields) => {
            return (
              <div>
                {this.renderRow(
                  this.renderField(this.stateData()[fields.item1]),
                  this.renderField(this.stateData()[fields.item2])
                )}
              </div>
            );
          })}
      </>
    );
  }

  render() {
    return (
      <Collapse
        className="customer-detail-card"
        bordered={false}
        expandIconPosition={"end"}
        style={{
          backgroundColor: "white",
          marginBottom: 16,
          marginRight: 16,
        }}
        defaultActiveKey={[1]}
      >
        <Panel
          key={1}
          header={this.stateData().caption || ""}
          className="customer-detail-title"
        >
          {this.renderFielsFromObject()}
        </Panel>
      </Collapse>
    );
  }
}

export default ClaimDetailsCard;
