import { Button, Divider, Image } from "antd";
import React, { Component } from "react";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_dialog_filter.css";

class AppDialogFilter extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.onSelectedDateFilter(value);
  }

  render() {
    return (
      <>
        <div onClick={this.props.disabled ? null : this.props.onClick} className="dialog-filter-container">
          <div
            className="p-3 dialog-filter d-flex justify-content-center align-items-center"
            disabled={this.props.disabled}
          >
            <div
              className="dialog-filter__label"
              onChange={this.handleChange}
              style={{
                cursor: "pointer",
                color: this.props.disabled ? "grey" : "black",
              }}
            >
              <div className="d-flex">
                <div className="pr-2" style={{ fontWeight: 400 }}>
                  {this.props.title}
                </div>{" "}
                {this.props.default}
              </div>
            </div>
            <img
              src={
                GeneralBloc.getAssetsContextPath() +
                `/assets/icons/ArrowDownIcon.svg`
              }
              alt="Backward Icon"
            />

            {/* <div
              style={{
                width: 145,
                border: 0,
                alignItems: "start",
                cursor: "pointer",
              }}
              onChange={this.handleChange}
              onClick={this.props.onClick}
            >
              (All)
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default AppDialogFilter;
