import React, { Component } from "react";
import { Button } from "antd";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_header_title.css";
import Config from "../../../Config";


class AppHeaderTitleSimple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: Config.context,
      }
  }

  handleGoBack() {
    let dataListing = this.props.location.dataListing;
    let proposalStatuses = this.props.location.proposalStatuses;
    let statusFilter = this.props.location.statusFilter

    let listingRoute = this.props.location.detailRoute;

  
    let newLocation = { pathname:  `/${this.state.client}/${this.props.location.entity}/${listingRoute}`,
    dataListing: dataListing,
    proposalStatuses: proposalStatuses,
    statusFilter: statusFilter };


    if(this.props.location.dataListing) {
      return this.props.history.replace(
        newLocation
      );
    }
   
    else {
      return this.props.history.goBack();
    }
  }

  render() {
    return (
      <>
        <div className="header-title">
          <div className="d-flex">
            <div className="mr-3 mt-1 header-title__icon">
              <img
                src={
                  GeneralBloc.getAssetsContextPath() +
                  `/assets/icons/Backward.svg`
                }
                alt="Backward Icon"
                style={{ cursor: "pointer" }}
                onClick={()=> this.handleGoBack()}
              />
            </div>
            <div className="">
              <div className="header-title__title">{this.props.title}</div>
              <div className="header-title__description">
                {this.props.description}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AppHeaderTitleSimple);
