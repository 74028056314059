import Config from "../../../Config";
import Listing_screen from "../proposal_screen.js/listing_screen";
import React from "react";
import { withRouter } from "react-router-dom";

class CertificateCollectionStatusUpcomingScreenV2 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <Listing_screen
        listId={"df-cert-collection-status-list"} //To define which list this is loading for
        countId={"df-cert-collection-status-count"} //To define which count to retrieve
        module={"certificateCollection-upcoming"} //To define which module this list is for
        detailRoute={Config.routes.generic.certificateCollectionUpcoming} //Route to detail screen
        listingTitle={"Certificate Collection Status"} //Title of listing
        part={"event"}
        dateRange={"upcoming"}
      />
    );
  }
}

export default withRouter(CertificateCollectionStatusUpcomingScreenV2);
