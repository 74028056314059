import { Component } from "react";
import AppHeaderBean from "../data/bean/app_header_bean";

class ClaimsDetailBloc extends Component {
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }
}

export default ClaimsDetailBloc;
