import { Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProposalBloc from "../../../bloc/proposal_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import App_button2 from "../../component/app_button/app_button2";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import GeneralBloc from "../../../bloc/general_bloc";
import TermsAndConditionDialog from "../../component/terms_and_condition_dialog/terms_and_condition_dialog";
import HomeWebService from "../../../service/home_web_service";
import "./submitted_documents_screen.css";
import UtilBloc from "../../../bloc/util_bloc";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import AppEmptyData from "../../component/app_empty_data/app_empty_data";
import Base_screen from "../base/base_screen/base_screen";

class SubmittedDocumentsScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);    
    this.state = {
      route: routeParams,
      itemId: routeParams.itemId,
      docClass: routeParams.docClass,
      userData: {},
      submittedDocuments: [],
      showList: true,
      isOpenPDF: false,
      module: "casepedia",
      isLoading: true
    };
  }

  stateItemId() { return this.state.itemId };
  stateModule() { return this.state.module };
  stateDocClass() { return this.state.docClass };
  stateIsLoading() { return this.state.isLoading };

  async getSubmittedDocuments() {    
    ProposalBloc.getSubmittedDocuments(this.stateDocClass(), this.stateItemId()).then((result) => {
      if (result.success) {
        let submittedDocuments = GeneralBloc.getSubmittedDocuments(result.data);
        this.setState({
          submittedDocuments,
          showList: submittedDocuments ? true : false,
          isLoading: false
        });
      }
    }).catch(e => {
      this.setState({
        isLoading: false
      });
    })
  }

  //fileType is pdf and image

  async viewOrDownloadFile(id, fileType, view,docName) {
    this.setState({
      isLoading: true
    })
    let result = await ProposalBloc.downloadFile(this.stateModule(), id)
    if (result.success) {
      this.setState({
        isLoading: false
      })
      let typeFile = result.data.headers['content-type'].split("/")[1];
      if (view == "download") {
        let filename = `${docName}.${typeFile}`
        UtilBloc.downloadBinary(result.data.data, filename)
      }

      if (view == "view") {        
        if(typeFile=="pdf"){
          this.openViewDialog(typeFile, result.data.data);
        } else if(typeFile=="png"){
          let url=window.URL.createObjectURL(new Blob([result.data.data]))
          this.openViewDialog(typeFile,url);
        }        
      }
    }
  }

  async componentDidMount() {

  }

  openViewDialog(type, content) {
    this.setState({
      isOpenPDF: true,
      type: type,
      pdfContent: content
    })
  }

  closeViewDialog() {
    this.setState({
      isOpenPDF: false,
    })
  }

  loadingContent() {
    return (
      <div>
        <AppLoadingDialog isLoading={this.stateIsLoading()} />
        < div className="row landing_section">
          <div className="col-md-12 text-center">
          </div>
        </div>
      </div>
    );
  }

  init() {
    this.getSubmittedDocuments()
    this.setState({
      userData: ProposalBloc.getUserData(
        this.props.userGlobal.displayName.length > 0
          ? this.props.userGlobal.displayName
          : "",
        this.props.userGlobal.designationTitle.length
          ? this.props.userGlobal.designationTitle
          : ""
      ),
    });
  }

  render() {
    return (
      <>
        <TermsAndConditionDialog
          showClose={true}
          isOpen={this.state.isOpenPDF}
          content={this.state.pdfContent || null}
          onDialogClose={(e) => this.closeViewDialog()}
          type={this.state.type}
        />
        <Base_screen
          listener={() => {
            this.init();
          }}
        >
          {this.loadingContent()}
          <App_header
            data={this.state.userData}
            route={this.state.route.entity}
          />
          <App_sidebar route={this.state.route.entity}>
            <div className="d-flex">
              <App_header_title_simple
                menu={"menu2"}
                title={"All Submitted Documents"}
              />
            </div>
            <div className="proposal" style={{ marginTop: "32px" }}>
              <div className="proposal__customer">
                <Row>
                  {
                    this.state.submittedDocuments && this.state.submittedDocuments.length > 0 ?
                      <Col span={15}>
                        <div className="proposal__submitted-document">
                          <div className="proposal__customer-detail-title">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <div>
                                Submitted Documents
                              </div>
                              {
                                this.state.submittedDocuments && this.state.submittedDocuments.length > 0 && <img
                                  src={
                                    GeneralBloc.getAssetsContextPath() +
                                    `/assets/images/${this.state.showList ? "new_down_icon" : "new_up_icon"}.png`
                                  }
                                  className="img-fluid mr-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.setState({
                                      showList: !this.state.showList
                                    })
                                  }}
                                />
                              }
                            </div>
                          </div>
                          {
                            this.state.showList && this.state.submittedDocuments && this.state.submittedDocuments.map((ev, index) => {
                              return (
                                <div className="submitted__document-detail-content">
                                  <div>
                                    <div style={{ fontSize: "14px" }}><span className="mr-2">{index + 1}.</span>{ev.docName}</div>
                                    <div className="proposal__customer-detail-tag" style={{ fontSize: "12px" }}>
                                      {ev.docDesc}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {
                                      ev.canPreview && <img
                                        src={
                                          GeneralBloc.getAssetsContextPath() +
                                          "/assets/images/new_eye_icon.png"
                                        }
                                        style={{ cursor: "pointer", width: "16px", height: "13px" }}
                                        onClick={() => { this.viewOrDownloadFile(ev.docId, ev.docType, "view",ev.docName) }}
                                      />
                                    }
                                    {
                                      <img
                                        src={
                                          GeneralBloc.getAssetsContextPath() +
                                          "/assets/images/Download.png"
                                        }
                                        className="img-fluid ml-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { this.viewOrDownloadFile(ev.docId, ev.docType, "download",ev.docName) }}
                                      />
                                    }
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </Col> :
                      <Col className="col-12">
                        <AppEmptyData
                          description1={"No documents found."}
                          description2={"There is no submitted documents available at the moment."}
                        />
                      </Col>
                  }
                </Row>
              </div>
            </div>
          </App_sidebar>
        </Base_screen>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps)(SubmittedDocumentsScreen));
