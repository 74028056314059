import axios from "axios";
import BaseWebService from "./base_web_service";
import UtilBloc from "../bloc/util_bloc";

class GeneralWebService {
  static async getProductListDependOnPartner(session) {
    let headers = BaseWebService.headers(
      session.productCode,
      session.partnerCode,
      session.trxId
    );
    let resp = await axios.get(BaseWebService.baseUrl() + "/partner/products", {
      headers: headers,
    });
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getDateRangeFilter(token, id) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: !id ? "df-master-config-show-within" : id,
      },
      {
        headers: header,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getDateRangeUpcomingFilter(token, id) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: !id ? "df-master-config-show-within-upcoming" : id,
      },
      {
        headers: header,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getSearchBys(token, id) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: id,
      },
      {
        headers: header,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getModuleCount(
    module,
    token,
    entity,
    criParams,
    bypassRowsOfPage
  ) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: module,
        bypassRowsOfPage: bypassRowsOfPage || true,
        criParams: criParams,
      },
      {
        headers: header,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getBranchList(token, regions) {
    let header = await BaseWebService.headers();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: "df-branch-list",
        criParams: {
          regions: regions,
        },
      },
      {
        headers: header,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getDynamicFilter(token, endpoint, request) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/${endpoint}`,
      JSON.parse(request),
      {
        headers: header,
      }
    );
    return resp.data;
  }

  static async getSearchCriteriaList(token, regions, id) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: id,
        criParams: {
          regions: regions,
        },
      },
      {
        headers: header,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getAgentList(token, regions, branches) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/agentList`,
      {
        regions: regions,
        branches: branches,
      },
      {
        headers: header,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async exportFileByModule(token, entity, payload) {
    let header = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/export`,
      payload,
      {
        headers: header,
        responseType: "blob",
      }
    );
    return resp;
  }
}

export default GeneralWebService;
