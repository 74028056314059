import { Button } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./app_button.css";

class AppButton2 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <button className="button" onClick={(e)=>this.props.onClickAction(e)}>{this.props.title}</button>
      </>
    );
  }
}

export default withRouter(AppButton2);
