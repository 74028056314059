import React, { Component } from "react";
import AppClaimStatusBean from "../data/bean/app_claim_status_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import DataResult from "../data/bean/data_result";
import CertificateCollectionStatusWebServices from "../service/certificate_collection_status_web_service";
import HomeWebService from "../service/home_web_service";
import ProposalWebServices from "../service/proposal_web_services";
import AppTableStatus from "../ui/component/app_table_status/app_table_status";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";

class CertificateCollectionBloc extends Component {
  static getDummyCertificateCollectionStatuses() {
    return [
      new AppClaimStatusBean("Approved.svg", "In Force", 10, "in-force"),
      new AppClaimStatusBean(
        "InProgress.svg",
        "Contact Lapsed",
        30,
        "contract-lapsed"
      ),
      new AppClaimStatusBean("Closed.svg", "Lapsed", 10, "lapsed"),
      new AppClaimStatusBean(
        "Mixed Decisions.svg",
        "Regular Payment Rescind",
        30,
        "reinstatement"
      ),
    ];
  }

  static getCollumnTable() {
    return [
      {
        title: "Certificate Status",
        dataIndex: "certificateStatus",

        // specify the condition of filtering result
        // here is that finding the name started with `value`
      },
      {
        title: "Certificate Number",
        dataIndex: "certificateNumber",
      },
      {
        title: "Product Name",
        dataIndex: "productName",
      },
      {
        title: "Participant Name",
        dataIndex: "participantName",
      },
      {
        title: "Participant ID",
        dataIndex: "participantID",
      },
      {
        title: "Participant Mobile Number",
        dataIndex: "mobileNo",
      },
      {
        title: "Contribution Due Amount",
        dataIndex: "contributionDueAmount",
      },
      {
        title: "Contribution Due Date",
        dataIndex: "contributionDueDate",
      },
      {
        title: "Contribution Holiday Indicator",
        dataIndex: "contributionHolidayIndicator",
      },
    ];
  }
  static getDummyCertificateCollectionDataTable() {
    return [
      {
        key: "1",
        certificateStatus: (
          <AppTableStatus statusName="In Force" status={"green"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "2",
        certificateStatus: (
          <AppTableStatus statusName="Rescind" status={"orange"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "3",
        certificateStatus: (
          <AppTableStatus statusName="Lapsed" status={"grey"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "4",
        certificateStatus: (
          <AppTableStatus statusName="Rescind" status={"orange"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "5",
        certificateStatus: (
          <AppTableStatus statusName="In Force" status={"green"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "6",
        certificateStatus: (
          <AppTableStatus statusName="Contract" status={"yellow"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "7",
        certificateStatus: (
          <AppTableStatus statusName="Lapsed" status={"grey"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "8",
        certificateStatus: (
          <AppTableStatus statusName="In Force" status={"green"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "9",
        certificateStatus: (
          <AppTableStatus statusName="In Force" status={"green"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "10",
        certificateStatus: (
          <AppTableStatus statusName="Lapsed" status={"grey"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },

      {
        key: "11",
        certificateStatus: (
          <AppTableStatus statusName="Contract" status={"yellow"} />
        ),
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Gan Shee Yung",
        participantID: 50783259,
        mobileNo: "01283739832",
        contributionDueAmount: "RM. XXX.XX",
        contributionDueDate: "DD/MM/YY",
        contributionHolidayIndicator: "Lorem Ipsum",
      },
    ];
  }
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }

  static async getCertificateCollectionStatuses(
    entity,
    module,
    moduleId,
    dateFilter,
    fromDate,
    toDate,
    searchBy,
    keyword,
    regions,
    branches,
    agents
  ) {
    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let payload={
      "proposal-dt-range": dateFilter,
      dtFrom: getDateFilter.beginDate,
      dtTo: getDateFilter.endDate,
      "proposal-attr-search": searchBy,
      searchBy: keyword,
      regions: regions,
      branches: branches,
      agents: agents,
    }

    if(dateFilter!="range"){
      delete payload.dtFrom;
      delete payload.dtTo
    }


    let response = await GeneralBloc.getStatusBox(
      module,
      moduleId,
      false,
      false,
      payload
    );
    return response;
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getCertificateCollectionDataTable(
    entity,
    dateFilter,
    fromDate,
    toDate,
    regions,
    page,
    searchBy,
    keyword,
    branches,
    agents
  ) {
    let totalData = 0;

    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    //console.log("Getting regions:", regions);
    let response =
      await CertificateCollectionStatusWebServices.getCertificateCollection(
        token,
        entity,
        page,
        getDateFilter.beginDate,
        getDateFilter.endDate,
        regions,
        searchBy,
        keyword,
        branches,
        agents,
        dateFilter
      );

    let dataSet = {};
    if (response.success) {
      dataSet = await GeneralBloc.getListingTable(
        response,
        "certificateCollection"
      );
    }
    //console.log(totalData);
    let result = {
      dataCollumn: dataSet.columnTable || [],
      dataCertificateCollection: dataSet.data || [],
      totalData: dataSet.total || [],
    };
    return result;
  }
  static async getCertificateCollectionDetail(entity, recMap) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response =
      await CertificateCollectionStatusWebServices.getCertificateCollectionDetail(
        token,
        entity,
        recMap
      );

    if (response.success) {
      result = response.data;
    }
    return result;
  }

  // static async downloadFile(module, id) {
  //   let resp = await HomeWebService.downloadFile(module, id);
  //   if (resp.data) {
  //     return new DataResult(true, resp.message, resp);
  //   } else {
  //     return new DataResult(false, resp.message, null);
  //   }
  // }
}

export default CertificateCollectionBloc;
