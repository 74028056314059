import UserHierarchy from "../data/bean/hierarchy/user_hierarchy";
import SettingWebService from "../service/setting_web_service";
import AuthBloc from "./auth_bloc";
import RouteBloc from "./route_bloc";
import UtilBloc from "./util_bloc";

class UserAccessBloc {
  static async getUserSetting() {
    let token = "";
    const session = await AuthBloc.checkSession();
    // console.log(session);

    if (session) {
      token = session.accessToken;
    }

    let userSession = await AuthBloc.checkSession();
    let roleCode = "";
    let bankId = "";
    roleCode = userSession.roles[0].mapCode;
    bankId = RouteBloc.getSelectedBank().id;
    let response = await SettingWebService.getFEScreenSetting(token, bankId);
    let result = response.data;

    let resultColumn = result.resultColumns;

    let mappedData = [];
    for (var i = 0; i < resultColumn.length; i++) {
      let record = resultColumn[i];
      let columnKey = record.key;
      for (var j = 0; j < record.list.length; j++) {
        let recordValue = record.list[j];
        if (i == 0) {
          mappedData.push({});
        }
        mappedData[j][columnKey] = recordValue;
      }
    }

    for (var i = 0; i < mappedData.length; i++) {
      let mappedItem = mappedData[i];
      let itemBankId = mappedItem["bank_id"];
      let itemRoleCode = mappedItem["role_code"];
      let itemSetting = mappedItem["setting"];

      if (itemBankId == bankId && itemRoleCode == roleCode) {
        let parsedJSON = JSON.parse(itemSetting);
        this.saveUserSettings(parsedJSON);
        return parsedJSON;
      }
    }
    return null;
  }

  static saveUserSettings(object) {
    localStorage.setItem("userSettings", JSON.stringify(object));
  }

  static getUserSettings() {
    let userSettings = null;
    userSettings = localStorage.getItem("userSettings");
    if (userSettings) {
      return JSON.parse(userSettings);
    }
    return userSettings;
  }

  static showBankRepresentative(part) {
    let userSettings = null;
    userSettings = this.getUserSettings();
    return userSettings.module[part]?.detail?.bankRep === true ? true : false;
  }

  static showLetterOfAcceptance(part) {
    let userSettings = null;
    userSettings = this.getUserSettings();
    return userSettings.module[part]?.detail?.letterOfAcceptance === true
      ? true
      : false;
  }

  static getUserData() {
    let userData = null;
    userData = localStorage.getItem("dataUser");
    if (userData) {
      return JSON.parse(userData);
    }
    return userData;
  }

  static accessible(mod) {
    //console.log("Module:"+mod,this.getUserSettings().module[mod])
    return this.getUserSettings().module[mod];
  }

  //Deprecated, use getUserSetting() instead
  static getUserConfigByRole(role, entity) {
    //List of roles
    /*
        - SalesP
        - SalesM
        - BranchM
        - RegionalM
        - HOT
        - BDM
        */

    //List of entities
    /*
        - AIS (Alliance)
        - BCA (HSBC)
        */
    entity = RouteBloc.getSelectedBank().id;
    let foundEntity = UserHierarchy[entity];

    if (!foundEntity) {
      //console.log("User entity not found for entity = " + entity);
      return null;
    }

    let foundRole = foundEntity[role];
    if (!foundRole) {
      /* console.log(
        "User role not found for entity = " + entity + ", role = " + role
      ); */
      return null;
    }

    let parsedJSON = JSON.parse(foundRole);
    return parsedJSON;
  }

  static async getUserConfigForModule(role, entity, module, cache) {
    // console.log('Fetching user config for module = ' + module + ' with cache = ' + !(!cache));
    // let userConfig = this.getUserConfigByRole(role, entity);
    entity = RouteBloc.getSelectedBank().id;
    let userConfig;
    if (cache != null) {
      userConfig = cache;
    } else {
      userConfig = await this.getUserSetting(role, entity, cache);
    }

    if (!userConfig) {
      //console.log("User search criteria not found");
      return null;
    }

    let userModule = userConfig["module"][module];
    return userModule;
  }

  static async getUserSearchCriteriaForModule(role, entity, module, cache) {
    entity = RouteBloc.getSelectedBank().id;
    let userModule = await this.getUserConfigForModule(
      role,
      entity,
      module,
      cache
    );
    //console.log('Fetching user search critiria for module = ' + module + ' with cache = ' + (cache !== null));
    if (!userModule) {
      return null;
    }
    return userModule["searchCriteria"];
  }

  static async getUserDateRangeForModule(
    role,
    entity,
    module,
    userConfigCache
  ) {
    entity = RouteBloc.getSelectedBank().id;
    let userModule = await this.getUserConfigForModule(
      role,
      entity,
      module,
      userConfigCache
    );
    if (!userModule) {
      console.log(
        "Date Range: User module not found for role = " +
          role +
          ", entity = " +
          entity +
          ", module = " +
          module
      );
      return null;
    }
    //To get the default value (null/-30d), refer to 'default' field
    return userModule["dateRange"];
  }

  static showBeneficiaryDetails(data) {
    let result = [];
    result =
      data.length > 0
        ? data
        : [
            {
              name: {
                caption: "Beneficiary Name",
                value: null,
              },
              id: {
                caption: "Beneficiary ID",
                value: null,
              },
              relationship: {
                caption: "Relationship",
                value: null,
              },
              sharePercentage: {
                caption: "Share Percentage",
                value: null,
              },
            },
          ];
    return result;
  }
}
export default UserAccessBloc;
