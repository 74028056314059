import { Col, Collapse } from "antd";
import React from "react";
import AppContentFieldItem3 from "./app_content_field_item3";
import "./contribution_details_card.css";
import Config from "../../../Config";

const { Panel } = Collapse;

class ContributionDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
    };
  }

  stateData() {
    return this.props.data;
  }

  stateModule() {
    return this.props.module || "";
  }

  stateEntity(){
    return this.props.entity;
  }

  stateFields() {
    return Config[this.stateEntity()][this.stateModule()][this.stateNameOfComponent()] || [];
  }
  
  stateNameOfComponent() {
    return "contributionDueDetails";
  }

  componentDidMount() {    
  }

  renderField(field) {
    if (field)
      return (
        <AppContentFieldItem3
          title={field.caption}
          value={
            field.value == "" || field.value == 0.0 || field.value == null
              ? "N/A"
              : field.value
          }
        />
      );

    return <div />;
  }

  renderRow(item1) {
    return (
      <div>
        <Col>{item1}</Col>
      </div>
    );
  }

  renderFielsFromObject() {
    return <>
      {
        this.stateFields() && this.stateFields().map((field) => {
          return (
            <div>
              {this.renderRow(
                this.renderField(this.stateData()[field])
              )}
            </div>
          );
        })
      }
    </>
  }

  render() {
    return (
      <div
        className="contribution-detail-card"
        bordered={false}
        expandIconPosition={"end"}
        style={{
          backgroundColor: "white",
          marginBottom: 16,
          marginRight: 16,
        }}
        defaultActiveKey={[1]}
      >
        <div
          key={1}
          //   header={this.stateData().caption || ""}
          className="contribution-detail-title"
        >
          {this.stateData().caption || ""}
          {/* {this.renderRow(this.renderField(this.stateData().dueAmount))}
          {this.renderRow(this.renderField(this.stateData().dueDate))} */}
           {
            this.renderFielsFromObject()
          }
        </div>
      </div>
    );
  }
}

export default ContributionDetailsCard;
