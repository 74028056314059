import React from "react";
import { withRouter } from "react-router-dom";
import BaseWebService from "../../service/base_web_service";

class ConnectivityScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({});
  }

  handleLanguageChange(language) {
    this.setState({});
  }

  render() {
    const successStyle = {
      color: "#6FCF97",
      fontFamily: "Lato",
      fontSize: "24px",
      marginBottom: "16px",
      fontWeight: 600,
    };

    const unsuccessStyle = {
      color: "#F28E23",
      fontFamily: "Lato",
      fontSize: "24px",
      marginBottom: "16px",
      fontWeight: 600,
    };

    const imgMargins = {
      marginRight: 30,
      marginLeft: 10,
    };

    const tableLength = {
      tableLayout: "fixed",
    };

    return (
      <>
        <div className="row">
          {/* <div className="col-12 text-center">
                        <AppToolbar
                            backpage={'/'}
                            type={this.state.company}
                            disableLanguage={true}
                            language={LanguageBloc.getSelectedLanguage()}
                            hideMenu={true}
                            onLanguageChange={(language) => this.handleLanguageChange(language)}
                            cancel={false}
                            onButtonChange={(language) => {
                            }} />
                    </div> */}

          <div className="col-md-12 text-center" style={{ display: "block" }}>
            <div className="h-100 row d-flex align-items-center">
              <div className="col-md-3"></div>
              <div className="col-md-6 unsuccessful-height">
                <div className="unsuccessful-pads" />
                <div className="informationcontainer">
                  <div
                    className="align-self-center"
                    style={{ marginBottom: 41 }}
                  >
                    <div
                      className="list_advantage_item_bold text-center"
                      style={unsuccessStyle}
                    >
                      {"Connectivity"}
                    </div>
                    <p>{BaseWebService.baseUrl()}</p>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            "Successful connection to fwdbanca Portal.<br><br>Version " +
                            process.env.REACT_APP_RELEASE_VERSION +
                            "<br>Released on " +
                            process.env.REACT_APP_RELEASE_DATE,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ConnectivityScreen);
