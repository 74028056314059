import React from "react";
import { Steps } from "antd";
import "./app_steps.css";

class AppSteps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastValue: 0,
    };
  }

  propTitle() {
    return this.props.title;
  }

  propDirection() {
    return this.props.direction;
  }
  propCurrent() {
    return this.props.current;
  }

  render() {
    return (
      <div className="app-steps_container">
        <div className="app-steps-title">{this.propTitle() || ""}</div>
        <Steps
          direction={this.propDirection()}
          current={this.propCurrent()}
          items={this.props.items}
        />
      </div>
    );
  }
}

export default AppSteps;
