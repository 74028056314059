import { Component } from "react";
import AppHeaderBean from "../data/bean/app_header_bean";
import IssuedCasesWebService from "../service/issued_cases_web_service";
import ProposalWebServices from "../service/proposal_web_services";
import AuthBloc from "./auth_bloc";
import IssuedCasesBloc from "./issued_cases_bloc";

class IssuedCasesDetailBloc extends Component {
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getIssuedCaseDetail(entity, recMap) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response = await IssuedCasesWebService.getIssuedCaseDetail(
      token,
      entity,
      recMap
    );

    if (response.success) {
      result = response.data;
    }
    return result;
  }

  static async getFundDetail(entity, recMap) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response = await IssuedCasesWebService.getFundDetail(
      token,
      entity,
      recMap
    );

    if (response.success) {
      result = response.data;
    }
    return result;
  }
}

export default IssuedCasesDetailBloc;
