/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CertificateCollectionBloc from "../../../bloc/certiticate-collection_bloc";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import Config from "../../../Config";
import { setFilterBy } from "../../../data/redux/reducers/filterReducer";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import App_claim_status_mini from "../../component/app_claim_status/app_claim_status_mini";
import AppEmptyData from "../../component/app_empty_data/app_empty_data";
import App_daterange_filter from "../../component/app_filters/app_daterange_filter";
import AppDialogFilter from "../../component/app_filters/app_dialog_filter";
import App_dropdown_filter from "../../component/app_filters/app_dropdown_filter";
import App_dropdown_filter2 from "../../component/app_filters/app_dropdown_filter2";
import App_search_filter from "../../component/app_filters/app_search_filter";
import App_header from "../../component/app_header/app_header";
import App_header_title_filter from "../../component/app_header_title/app_header_title_filter";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import App_table from "../../component/app_table/app_table";
import App_searching_dialog from "../../dialog/app_searching_dialog/app_searching_dialog";
import "./certificate_collection_status_screen.css";
import UtilBloc from "../../../bloc/util_bloc";

class CertificateCollectionStatusScreen extends Component {
  constructor(props) {
    super(props);
    this.handleFilterDate = this.handleFilterDate.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleOnClickSearch = this.handleOnClickSearch.bind(this);
    this.handleCertificateCollectionStatusDetail =
      this.handleCertificateCollectionStatusDetail.bind(this);
    this.handleSelectedSearchType = this.handleSelectedSearchType.bind(this);
    this.handleChangeModule=this.handleChangeModule.bind(this);
    this.exportFile=this.exportFile.bind(this);
    const urlParams = new URLSearchParams(props.location.search); 
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);    
    this.state = {
      route: routeParams,
      client: Config.context,
      certificateCollectionStatusStatuses: [],
      certificateCollectionStatusData: {
        dataCollumn: [],
        dataCertificateCollection: [],
        totalData: 0,
      },
      userData: {},
      currentPage: 1,
      dateFilter:
        this.props.userGlobal.roles[0].mapCode !== "SalesP" ? null : "-30d",
      fromDate: "1900-01-01",
      toDate: "2050-01-01",
      searchBy: "certificateNumber",
      keyword: "",
      searchCriteria: [],
      isOpenSearchingAgentDialog: false,
      selections: [],
      regions: [],
      branches: [],
      agents: [],

      searchType: "byGroup",
      dialogRegions: [],
      dialogBranches: [],
      dialogStaff: [],
      toggleRegions: false,
      toggleBranch: false,
      toggleStaff: false,
      dashboardModule: urlParams.get("part")=="dashboard" ? "Overall Dashboard":"Upcoming Events",
      upcomingEventDropDown:null,      
      idModuleListing:"df-cert-collection-status-list"
    };
  }

  stateEntity() {return this.state.route.entity};
  stateIDModuleListing() { return this.state.idModuleListing}
  
  handleChangeModule(part){
    let newPath=GeneralBloc.getShortDashboardModule(part)
    window.location.href=GeneralBloc.encodeAndDecodeURL(`/${this.state.client}/${this.stateEntity()}/${Config.routes.generic.certificateCollection}?part=${newPath}`)
  }

  componentDidMount() {    
    this.setState({
      certificateCollectionStatusStatuses: [],

      userData: CertificateCollectionBloc.getUserData(
        this.props.userGlobal.displayName.length > 0
          ? this.props.userGlobal.displayName
          : "",
        this.props.userGlobal.designationTitle.length
          ? this.props.userGlobal.designationTitle
          : ""
      ),
    });
    this.getFilterBys();
    this.fetchCriteria();
    if (this.props.userGlobal.roles[0].mapCode != "BDM") {
      this.fetchRegion();
      this.fetchListing();
    }
  }

  async getFilterBys() {
    let result = await GeneralBloc.getDateRangeFilter();    
    result.data.resultColumns[0].list.shift();
    result.data.resultColumns[1].list.shift();
    if (result) {
      this.props.setFilterBy(result.data);
    }

    let resultUpcoming = await GeneralBloc.getDateRangeFilter("df-master-config-show-within-upcoming");    
    resultUpcoming.data.resultColumns[0].list.shift();
    resultUpcoming.data.resultColumns[1].list.shift();
    if (resultUpcoming) {          
      this.setState({
        upcomingEventDropDown:resultUpcoming.data
      })
    }
  }

  async fetchCriteria() {
    let criteria = await UserAccessBloc.getUserSearchCriteriaForModule(
      this.props.userGlobal.roles[0].mapCode,
      this.props.userGlobal.roles[0].entity,
      "certificateCollection"
    );
    this.setState({
      ...this.state,
      searchCriteria: criteria,
    });
    for (let x = 0; x < criteria.length; x++) {
      // Get Regions from User logged in
      let regions = [];
      for (let i = 0; i < this.props.userGlobal.roles.length; i++) {
        let record = {};
        record = {
          code: this.props.userGlobal.roles[i].region,
          status: false,
          name: this.props.userGlobal.roles[i].regionName,
        };
        regions.push(record);
      }
      // Get Branches from User logged in
      let branches = await GeneralBloc.getBranchesList(this.state.regions);

      // Get Agents from User logged in
      let salesStaff = await GeneralBloc.getAgentLists(
        this.state.regions,
        this.state.branches
      );

      switch (criteria[x].code) {
        case "REG":
          this.setState({
            dialogRegions: regions,
          });
          break;
        case "BRH":
          this.setState({
            dialogBranches: branches,
          });
          break;
        case "SEN":
          this.setState({
            dialogStaff: salesStaff,
          });
          break;
        default:
          break;
      }
    }
  }

  fetchRegion() {
    let regions = [];
    for (let i = 0; i < this.props.userGlobal.roles.length; i++) {
      regions.push(this.props.userGlobal.roles[i].region);
    }
    this.setState({
      regions: regions,
    });
  }

  async fetchBranches() {
    let branches = await GeneralBloc.getBranchesList(this.state.regions);
    this.setState({
      dialogBranches: branches,
    });
  }

  async fetchAgents() {
    let agents = await GeneralBloc.getAgentLists(
      this.state.regions,
      this.state.branches
    );

    this.setState({
      dialogStaff: agents,
    });
  }

  async fetchStatuses() {
    this.setState({
      loading: true,
    });

    let statuses =
      await CertificateCollectionBloc.getCertificateCollectionStatuses(
        this.props.userGlobal.roles[0].entity,
        "certificateCollection",
        "df-cert-collection-status-count",
        this.state.dateFilter || "",
        this.state.fromDate,
        this.state.toDate,
        this.state.searchBy,
        this.state.keyword,
        this.state.regions,
        this.state.branches,
        this.state.agents
      );

    this.setState({
      loading: false,
      certificateCollectionStatusStatuses: statuses,
    });
  }

  async handleFilterDate(dateValue) {
    this.setState(
      {
        dateFilter: dateValue,
      },
      this.props.userGlobal.roles[0].mapCode == "BDM" &&
        this.state.regions.length == 0
        ? null
        : this.fetchListing
    );
  }

  async handleDateRange(dateRange) {
    this.setState(
      {
        fromDate: dateRange[0],
        toDate: dateRange[1],
      },
      this.fetchListing
    );
  }

  async handleChangePage(selectedPage) {
    this.setState(
      {
        currentPage: selectedPage,
      },
      this.fetchListing
    );
  }

  async handleFilterSearch(filterSearchValue) {
    this.setState({
      ...this.state,
      searchBy: filterSearchValue,
    });
  }

  handleChangeKeyword(keyword) {
    this.setState({
      ...this.state,
      keyword: keyword,
    });
  }

  async fetchListing() {
    if (this.state.branches.length == 0) {
      this.fetchBranches();
    } else {
      this.fetchAgents();
    }

    this.setState({
      loading: true,
    });

    let certificateCollectionStatusData;
    certificateCollectionStatusData =
      await CertificateCollectionBloc.getCertificateCollectionDataTable(
        this.props.userGlobal.roles[0].entity,
        this.state.dateFilter,
        this.state.fromDate,
        this.state.toDate,
        this.state.regions,
        this.state.currentPage || 1,
        this.state.searchBy,
        this.state.keyword,
        this.state.branches,
        this.state.agents
      );

    this.setState(
      {
        loading: false,
        certificateCollectionStatusData: certificateCollectionStatusData,
      },
      this.fetchStatuses
    );
  }

  async handleOnClickSearch() {
    if (this.state.keyword.length > 3) {
      this.fetchListing();
    }
  }

  async handleCertificateCollectionStatusDetail(recMap) {
    let encodedRecMap = encodeURIComponent(recMap);
    return this.props.history.push(
      `/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.certificateCollection}/${encodedRecMap}`
    );
  }

  handleSelectedSearchType(searchType) {
    let searchTypeFilter = searchType === 1 ? "byGroup" : "bySearch";
    this.fetchRegion();
    this.setState(
      {
        searchType: searchTypeFilter,
        keyword: "",
        branches: [],
        agents: [],
        fromDate: "1900-01-01",
        toDate: "2050-01-01",
        selections: [],
        certificateCollectionStatusData: {
          dataCollumn: [],
        },
      },
      this.fetchStatuses,
      this.fetchRegion
    );
  }

  toggleDialog(code) {
    code === "REG"
      ? this.setState(
          {
            toggleRegions: true,
          },
          this.fetchBranches,
          this.fetchAgents
        )
      : code === "BRH"
      ? this.setState({
          toggleBranch: true,
        })
      : this.setState(
          {
            toggleStaff: true,
          },
          this.fetchAgents
        );
  }

  async handleFilterDialog(apiListId) {
    let apiListid = apiListId;
    let result = await GeneralBloc.getSearchCriteriaList(
      this.state.regions,
      apiListid
    );
    if (result) {
      this.setState({
        selections: result,
      });
    }

    return result;
  }

  handleFilterByGroup(list, code) {
    let filteredList = list.filter((item) => item.status);
    let result = [];
    filteredList.forEach((item) => {
      result.push(item.code);
    });

    switch (code) {
      case "REG":
        this.setState(
          {
            regions: result,
            toggleRegions: false,
            branches: [],
            dialogBranches: [],
          },
          this.fetchListing
        );
        break;
      case "BRH":
        this.setState(
          {
            branches: result,
            toggleBranch: false,
            dialogStaff: [],
          },
          this.fetchListing
        );
        break;
      case "SEN":
        this.setState(
          {
            agents: result,
            toggleStaff: false,
          },
          this.fetchListing
        );
        break;
      default:
        break;
    }
    // console.log(this.state.regions);
    // this.fetchListing();
  }

  renderCertificateCollectionStatusStatuses() {
    if (
      this.state.certificateCollectionStatusData.totalData &&
      this.state.certificateCollectionStatusData.totalData > 0
    ) {
      return this.state.certificateCollectionStatusStatuses.map((item) => {
        return <App_claim_status_mini data={item} />;
      });
    }
  }
  renderSearchCriteria() {
    return this.state.searchCriteria && this.state.searchCriteria.map((item) => {
      return (
        <>          
          <AppDialogFilter
            disabled={
              (item.code === "BRH" && this.state.regions.length > 0) ||
              (item.code === "SEN" && this.state.regions.length > 0) ||
              item.code === "REG"
                ? false
                : true
            }
            onClick={() => this.toggleDialog(item.code)}
            title={item.name}
            default={
              item.code === "REG"
                ? this.state.regions.length == 0
                  ? "(Select Region)"
                  : `(${this.state.regions.length})`
                : item.code === "BRH"
                ? this.state.branches.length == 0
                  ? // ||
                    // this.state.branches.length == this.state.regions.length
                    "(All)"
                  : `(${this.state.branches.length})`
                : this.state.agents.length == 0
                ? "(All)"
                : `(${this.state.agents.length})`
            }
          />
          <App_searching_dialog
            isOpen={
              item.code === "REG"
                ? this.state.toggleRegions
                : item.code === "BRH"
                ? this.state.toggleBranch
                : this.state.toggleStaff
            }
            onCloseDialog={() => {
              item.code === "REG"
                ? this.setState({
                    toggleRegions: false,
                  })
                : item.code === "BRH"
                ? this.setState({
                    toggleBranch: false,
                  })
                : this.setState({
                    toggleStaff: false,
                  });
            }}
            getSelectedOptions={(list) => {
              this.handleFilterByGroup(list, item.code);
            }}
            selections={
              item.code === "REG"
                ? this.state.dialogRegions
                : item.code === "BRH"
                ? this.state.dialogBranches
                : this.state.dialogStaff
            }
          />
        </>
      );
    });
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  async exportFile(){
    if (this.state.branches.length == 0) {
      this.fetchBranches();
    } else {
      this.fetchAgents();
    }

    this.setState({
      loading: true,
    });

    let fileData;
    fileData = await UtilBloc.exportFileByModule(
      this.props.userGlobal.roles[0].entity,
      this.state.dateFilter,
      this.state.fromDate,
      this.state.toDate,
      this.state.regions,
      this.state.currentPage || 1,
      this.state.searchBy,
      this.state.keyword,
      this.state.branches,
      this.state.agents,
      this.stateIDModuleListing()
    );
    let filename = `Certificate_Collection_Status.${"csv"}`    
    this.setState({
      loading: false,
    });
    UtilBloc.downloadBinary(fileData.data, filename)
  }

  render() {
    return (
      <>
        <div>
          {this.state.loading && this.loadingContent()}
          <App_header
            data={this.state.userData}
            route={this.state.route.entity}
          />
          <App_sidebar route={this.state.route.entity}>
            <App_header_title_filter
              title={"Certificate Collection Status"}              
              show={this.stateEntity()=="HSBC" ? true :false}
              onSelectedSearchType={this.handleSelectedSearchType}
              handleChangeModule={this.handleChangeModule}
              exportFile={this.exportFile}
              defaultValue={this.state.dashboardModule}
            />
            <div className="d-flex align-items-center mt-5">
              {this.state.searchType === "byGroup" ? (
                <>
                  <App_dropdown_filter
                    onSelectedDateFilter={this.handleFilterDate}
                    dataDateByCaption={
                       this.state.dashboardModule=="Overall Dashboard" ? this.props.globalFilterBy.resultColumns[0].list :this.state.upcomingEventDropDown ? this.state.upcomingEventDropDown.resultColumns[0].list:[]
                    }
                    dataDateByValue={
                      this.state.dashboardModule=="Overall Dashboard" ? this.props.globalFilterBy.resultColumns[1].list :this.state.upcomingEventDropDown ? this.state.upcomingEventDropDown.resultColumns[1].list:[]
                    }
                    placeholder={
                      this.props.userGlobal.roles[0].mapCode !== "SalesP"
                        ? "Select Date"
                        : this.state.dashboardModule=="Overall Dashboard" ? this.props.globalFilterBy.resultColumns[0].list[0] :this.state.upcomingEventDropDown ? this.state.upcomingEventDropDown.resultColumns[0].list:[]
                    }                    
                  />
                  <App_daterange_filter
                    disabled={this.state.dateFilter === "range" ? false : true}
                    onSelectedDateRange={this.handleDateRange}
                  />
                  <div className="d-flex ml-3">
                    {this.renderSearchCriteria()}
                  </div>
                </>
              ) : (
                <>
                  <App_dropdown_filter2
                    title={"Search By"}
                    onSelectedSearchFilter={this.handleFilterSearch}
                    dataSearchByCaption={
                      this.props.globalSearchBy.resultColumns[0].list
                    }
                    dataSearchByValue={
                      this.props.globalSearchBy.resultColumns[1].list
                    }
                  />
                  <App_search_filter
                    title={"Search"}
                    onChangeKeyword={this.handleChangeKeyword}
                    onClickSearch={this.handleOnClickSearch}
                  />
                </>
              )}
            </div>
            <div className="d-flex certificateCollectionStatus__status">
              {this.renderCertificateCollectionStatusStatuses()}
            </div>
            {this.state.certificateCollectionStatusData.totalData > 0 ? (
              <div className="mb-4 certificateCollectionStatus_result">
                Showing {this.state.certificateCollectionStatusData.totalData}{" "}
                results.
              </div>
            ) : null}
            <div className="">
              {this.state.certificateCollectionStatusData.dataCollumn.length ? (
                <App_table
                  data={
                    this.state.certificateCollectionStatusData
                      .dataCertificateCollection
                  }
                  collumn={
                    this.state.certificateCollectionStatusData.dataCollumn
                  }
                  totalData={
                    this.state.certificateCollectionStatusData.totalData
                  }
                  onSelectedPage={this.handleChangePage}
                  currentPage={this.state.currentPage}
                  handleOnClick={this.handleCertificateCollectionStatusDetail}
                />
              ) : (
                <AppEmptyData />
              )}
            </div>
          </App_sidebar>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
  globalFilterBy: state.filter.value,
  globalSearchBy: state.searchBy.value,
});

const mapDispatchToProps = { setFilterBy };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CertificateCollectionStatusScreen)
);
