import axios from "axios";
import GeneralBloc from "../bloc/general_bloc";
import LanguageBloc from "../bloc/language_bloc";
import SessionBloc from "../bloc/session_bloc";
import Config from "../Config";
import BaseResponseJson from "../data/web/base_response_json";
import AuthBloc from "../bloc/auth_bloc";
import RouteBloc from "../bloc/route_bloc";

class BaseWebService {
  static baseUrl() {    
    return process.env.REACT_APP_BASE_URL + this.getContextPathBySource();
  }

  static version() {
    return process.env.REACT_APP_RELEASE_VERSION;
  }

  static headersFromSession(session) {
    let lang = LanguageBloc.getSelectedLanguageForAPI();

    return {
      ChannelId: Config.channel,
      Language: lang,
      Authorization: SessionBloc.generateStatelessAuth(),
      PartnerCode: (session.partnerCode || "").toUpperCase(),
      Product: (session.productCode || "").toUpperCase(),
      CorrelationId: session.trxId,
      "Content-Type": "application/json",
      Version: BaseWebService.version(),
      Accept: "*/*",
    };
  }

  static async fixedHeaders(){
    let token = await AuthBloc.currentAccessToken();    

    let headers={
      "Authorization": "Bearer " + token,              
    }

    return headers;
  }

  static async headers() {
    let token=null;
    const session = await AuthBloc.checkSession();    
    if (session) {
      token = session.accessToken;
    }

    let headers={
      "Authorization": "Bearer " + token,              
    }

    return headers;
  }

  static async standardHeaders() {
    let token=null;
    const session = await AuthBloc.checkSession();    
    if (session) {
      token = session.accessToken;
    }

    let selectedBank=RouteBloc.getSelectedBank()
    let headers={};
    if(selectedBank){
      headers= {
        "Authorization": "Bearer " + token,
        "entity":selectedBank.id
      };
    } else {
      headers= {
        "Authorization": "Bearer " + token,
      };
    }    
    return headers;
  }

  static emptyHeader() {
    let lang = GeneralBloc.getSelectedLanguage();
    let newLang = lang === "eng" ? "EN" : "BN";
    return {
      /* 'ChannelId': "fwdbanca", */
      Language: newLang,
      Authorization: SessionBloc.generateStatelessAuth(),
      "Content-Type": "application/json",
      Version: BaseWebService.version(),
      Accept: "*/*",
    };
  }

  static getContextPathBySource() {    
    return "/v1";
  }

  static baseResponseFromWeb(resp) {
    let success = resp.errcode == null || resp.errcode === "";
    let message = resp.errmsg;
    let code = resp.errcode;
    let errorCode = resp.errcode;
    let data = resp.result;

    return new BaseResponseJson(success, message, code, data, errorCode);
  }
}

export default BaseWebService;
