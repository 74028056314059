import axios from "axios";
import { Component } from "react";
import BaseWebService from "./base_web_service";

export default class SettingWebService extends Component {
  static async getFEScreenSetting(
    token,
    entity
  ) {
    let header=await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: "df-frontend-scr-setting-list",
        bypassRowsOfPage: true
      },
      {
        headers: header,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
}
