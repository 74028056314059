class AppAnnouncementsBean {
  constructor(id, title, content, timestamp, image) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.timestamp = timestamp;
    this.image = image;
  }
}

export default AppAnnouncementsBean;
