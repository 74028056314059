import { Checkbox, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import AuthBloc from "../../../bloc/auth_bloc";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import { logOut, setAuth } from "../../../data/redux/reducers/authReducer";
import "./login_screen.css";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import UtilBloc from "../../../bloc/util_bloc";
import AppFieldError from "../../component/form/app_field_error/app_field_error";

class ChangePasswordScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    const urlParams = new URLSearchParams(props.location.search);
    this.state = {
      client: Config.context,
      route: routeParams,
      confirmPassword: "",
      password: "",
      oldPassword:"",
      isLoggedIn: false,
      loading: false,
      passwordVisible: false,
      errorOldPassword: null,
      errorPassword: null,
      errorConfirmPassword: null,
      enableButton: false,
      validatedOldPassword: false,
      validatedPassword: false,
      validatedConfirmPassword: false,
      formError: "",
      status: urlParams.get("status") || false
    };
  }

  stateDisableButton() { return this.state.validatedOldPassword &&this.state.validatedPassword && this.state.validatedConfirmPassword && (this.state.password == this.state.confirmPassword) };

  changeHandler(e, name, errorName) {
    let error = UtilBloc.validatePassword(e.target.value, name);
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      ["error" + errorName]: error,
      ["validated" + errorName]: UtilBloc.isNullOrEmpty(error) ? true : false
    });
  };

  validateForm() {
    let validatedPassword = this.state.validatedPassword;
    let validatedConfirmPassword = this.state.validatedConfirmPassword;
    if (validatedPassword && validatedConfirmPassword) {
      if (this.state.password != this.state.confirmPassword) {
        this.setState({
          formError: "Please enter same password or confirm password"
        })
      } else {
        this.setState({
          formError: null
        })
      }
    }
  }

  setPasswordVisible() {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  }

  async changePasswordHander() {
     this.setState({
       loading: true,
     });
     let userData=AuthBloc.getDataUser();
     if(userData){      
      let userName=userData.username;
      let oldPassword=this.state.oldPassword
      let password=this.state.password; 
      let result = await AuthBloc.changePassword(userName,oldPassword,password);    
      this.setState({
        loading: false,
      });
      if (result.success) {
        this.setState({
          status: true
        })
      } else {
        this.setState({        
        formError:result.message
      });
      }
     }     
  }

  changePasswordContainer() {
    return <div className="login__forms">
      <div className="login__header">Enter New Password</div>
      <div className="mb-4 login_description" style={{ marginTop: "-20px" }}>
        Password must contain between 8-16 characters, with upper case, lower case, special character and numeric characters.
      </div>
      <div className="login__input">
        <Input.Password
          style={{
            height: "48px",
          }}
          type="password"
          placeholder="Old Password"
          //className="login__input"
          value={this.state.oldPassword}
          defaultValue={""}
          onChange={async (e) => {
            await this.changeHandler(e, "old", "OldPassword")
            await this.validateForm()
          }}
          name="oldPassword"
          visibilitytoggle={{
            visible: this.state.passwordVisible,
            onVisibleChange: this.setPasswordVisible,
          }}
          maxLength={18}
        />
        <AppFieldError
          text={this.state.errorOldPassword}
        />
      </div>
      <div className="login__input">
        <Input.Password
          style={{
            height: "48px",
          }}
          type="password"
          placeholder="New Password"
          //className="login__input"
          value={this.state.password}
          defaultValue={""}
          onChange={async (e) => {
            await this.changeHandler(e, "new", "Password")
            await this.validateForm()
          }}
          name="password"
          visibilitytoggle={{
            visible: this.state.passwordVisible,
            onVisibleChange: this.setPasswordVisible,
          }}
          maxLength={18}
        />
        <AppFieldError
          text={this.state.errorPassword}
        />
      </div>
      <div className="login__input">
        <Input.Password
          style={{
            height: "48px",
          }}
          type="password"
          placeholder="Confirm Password"
          //className="login__input"
          value={this.state.confirmPassword}
          defaultValue={""}
          onChange={async (e) => {
            await this.changeHandler(e, "confirm", "ConfirmPassword")
            await this.validateForm()
          }}
          name="confirmPassword"
          visibilitytoggle={{
            visible: this.state.passwordVisible,
            onVisibleChange: this.setPasswordVisible,
          }}
          maxLength={18}
        />
      </div>
      <div style={{ marginTop: "-10px" }}>
        <AppFieldError
          text={this.state.errorConfirmPassword}
        />
        {
          (!this.state.errorConfirmPassword && !this.state.errorPassword) && this.state.formError ? <AppFieldError
            text={this.state.formError}
          /> : null
        }

      </div>
      <div
        className="d-flex align-items-center"
        style={{
          marginBottom: "40px",
        }}
      >
      </div>
      <Button
        disabled={!this.stateDisableButton()}
        size="large"
        htmlType="submit"
        style={{
          backgroundColor: "#183028",
          width: "100%",
          borderRadius: 0,
          color: "white",
          marginBottom: "36px",
        }}
        onClick={() =>
          this.changePasswordHander()
        }
      >
        Submit
      </Button>

      <a
        className="login__forgot-password d-flex justify-content-center"
        href={`/${Config.context}/login`}
      >
        <u>Back to Login</u>
      </a>
    </div>
  }

  successChangePasswordContainer() {
    return <div className="login__forms">
      <div className="login__header">Change Password Successful</div>
      <div className="mb-4 login_description" style={{ marginTop: "-20px" }}>
        Your password has been successfully updated.
      </div>
      <a
        className="login__forgot-password d-flex justify-content-center"
        href={`/${Config.context}/${
          this.props.userGlobal.roles[0].entity === "BCA"
            ? "HSBC"
            : "Alliance"
        }/home`}
      >
        <u>Proceed to Dashboard</u>
      </a>
    </div>
  }

  renderChangePasswordPage() {
    return (
      <div className="login__screen">
        <div className="login__wrapper">
          {this.state.loading && this.loadingContent()}
          <div className="login__container" style={{width:this.state.status ? "520px" :""}}>
            <div className="login__logo-container">
              <img
                src={
                  GeneralBloc.getAssetsContextPath() +
                  `/assets/images/Logo/FWD Takaful Logo.svg`
                }
                alt="FWD Takaful"
                className="login__logo-image"
              />
            </div>
            {
              !this.state.status ? this.changePasswordContainer() : this.successChangePasswordContainer()
            }
          </div>
        </div>
      </div>
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    return (
      <>
        {
          this.renderChangePasswordPage()
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = { setAuth, logOut };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePasswordScreen)
);
