import { Layout, Menu } from "antd";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as BriefcaseIcon } from "../../../Briefcase.svg";
import { ReactComponent as DashboardIcon } from "../../../Dashboard.svg";
import { ReactComponent as FileIcon } from "../../../File.svg";
import { ReactComponent as EventIcon } from "../../../Events.svg";
import "./app_sidebar.css";
import { setFilterDialog } from "../../../data/redux/reducers/filterDialogReducer";
import { setOverallListing } from "../../../data/redux/reducers/overallListingReducer";
import { setUpcomingListing } from "../../../data/redux/reducers/upcomingListingReducer";
import { setDateFilter } from "../../../data/redux/reducers/dateFilterReducer";

import GeneralBloc from "../../../bloc/general_bloc";
import Config from "../../../Config";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import ListingBloc from "../../../bloc/listing_bloc";
import { connect } from "react-redux";

const { Content, Sider } = Layout;

class AppSidebar extends Component {
  constructor(props) {
    const { host, hostname, href, origin, pathname, port, protocol, search } =
      window.location;
    let paths = pathname.split("/");
    super(props);
    this.state = {
      collapsed: false,
      client: Config.context,
      showProposal: false,
      showIssuedCase: false,
      showClaim: false,
      showBusinessOpp: false,
      showCertificateCollection: false,
      titleIssuedCase: "",
      paths: paths,
      currentMenu: "",
      sideBarWidth: 250,
      browserName: null,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  fnBrowserDetect() {
    let userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    return browserName;
  }

  updateDimensions() {
    let browserName = this.fnBrowserDetect();
    const expression = /(Mac|iPhone|iPod|iPad)/i;
    if (window.innerWidth < 1025) {
      if (expression.test(navigator.platform)) {
        this.setState({
          sideBarWidth: 345,
        });
      } else {
        this.setState({
          sideBarWidth: 345,
          collapsed: true,
        });
      }
    } else {
      this.setState({
        sideBarWidth: 250,
        collapsed: false,
      });
    }
  }

  stateSideBarWidth() {
    return this.state.sideBarWidth;
  }

  /* getSideBarWidth(){    
    var elem,style;
    elem = document.querySelector('.zindex_sidebar');
    style = getComputedStyle(elem);    
    if( parseInt(style.zIndex)==100){
      this.setState({
        collapsed: true,
        sideBarWidth:345
      });
    }
  }
 */
  setCollapsed(value) {
    this.setState({
      collapsed: value,
    });
  }

  async componentDidMount() {
    if (window.innerWidth < 1025) {
      this.setState({
        sideBarWidth: 345,
        collapsed: true,
      });
    } else {
      this.setState({
        sideBarWidth: 250,
        collapsed: false,
      });
    }

    window.addEventListener("resize", this.updateDimensions);
    this.showCurrentActiveKey();
    let showProposal = true;
    let showIssuedCase = true;
    let showClaim = true;
    let showBusinessOpp = true;
    let showCertificateCollection = true;

    setTimeout(async () => {
      showProposal = UserAccessBloc.accessible("proposal");
      showIssuedCase = UserAccessBloc.accessible("issuedCase");
      showClaim = UserAccessBloc.accessible("claim");
      showBusinessOpp = UserAccessBloc.accessible("businessOpportunity");
      showCertificateCollection = UserAccessBloc.accessible(
        "certificateCollection"
      );
      this.setState({
        showProposal,
        showIssuedCase,
        showClaim,
        showBusinessOpp,
        showCertificateCollection,
      });
      await this.getIssuedCaseTitle();
    }, 2000);
  }

  async getIssuedCaseTitle() {
    let result = await ListingBloc.getListingTitle("issuedCase");
    this.setState({
      titleIssuedCase: result,
    });
  }

  showCurrentActiveKey() {
    let route = GeneralBloc.showCurrentModule(this.state.paths)
    this.setState({
      currentMenu: route,
    });
  }

  performLinkClick() {
    if (this.props.onLinkClick) {
      this.props.onLinkClick();
    }
    this.props.setFilterDialog({});
    this.props.setOverallListing({
      ...this.props.overallListingGlobal,
      searchBy: null,
      keyword: null,
      overallDateValue: null,
      statusFilter: [],

    });
    this.props.setUpcomingListing({
      ...this.props.upcomingListingGlobal,
      searchBy: null,
      keyword: null,
      upcomingDateValue: null,
      statusFilter: [],
    });
    this.props.setDateFilter({});
  }

  logoByEntity() {
    let basePath = GeneralBloc.getAssetsContextPath() + "/assets/images/Logo/";
    switch (this.props.route) {
      case Config.entity.hsbc.route: {
        basePath = basePath + Config.entity.hsbc.logo;
        break;
      }
      case Config.entity.alliance.route: {
        basePath = basePath + Config.entity.alliance.logo;
      }
    }

    return basePath;
  }

  render() {
    return (
      <>
        <div>
          <Sider
            id="sidebar"
            theme="light"
            width={this.stateSideBarWidth()}
            className="bg-white site-layout-background sidebar zindex_sidebar"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={(value) => this.setCollapsed(value)}
            trigger={null}
            style={{
              position: "absolute",
              height: "100vh",
              zIndex: this.state.collapsed ? 0 : "",
            }}
          >
            {!this.state.collapsed && (
              <div className="logo_bank_container">
                <Link
                  to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.home}`}
                >
                  <img
                    className="logo_bank_icon_sidebar"
                    src={
                      GeneralBloc.getAssetsContextPath() +
                      `/assets/images/Logo/FWDLogo.png`
                    }
                    alt="FWD Logo"
                  />
                </Link>
                <img
                  className="logo_bank_icon_sidebar"
                  src={this.logoByEntity()}
                  style={{
                    marginLeft: 35.68,
                    fontWeight: 400,
                  }}
                />
              </div>
            )}
            <div
              className="bg-white position-absolute"
              style={{
                right: 0,
                top: 110,
                zIndex: 1,
              }}
            >
              {this.state.collapsed ? (
                <img
                  className="collapse-icon"
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    `/assets/icons/Right.svg`
                  }
                  alt=""
                  onClick={() => this.setCollapsed(!this.state.collapsed)}
                  style={{
                    cursor: "pointer",
                  }}
                />
              ) : (
                <img
                  className="collapse-icon"
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    `/assets/icons/Left.svg`
                  }
                  alt=""
                  onClick={() => this.setCollapsed(!this.state.collapsed)}
                  style={{
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
            <Menu
              selectedKeys={this.state.currentMenu}
              mode="inline"
              defaultOpenKeys={["/home", "sub1", "sub2"]}
              style={{
                height: "100%",
                borderRight: 0,
                paddingInlineStart: "20px",
                paddingTop: "40px",
                paddingRight: "5px",
              }}
            >
              <Menu.Item key="home" icon={<DashboardIcon className="icon" />}>
                <Link
                  to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.home}`}
                >
                  <div>Dashboard</div>
                </Link>
              </Menu.Item>
              <Menu.SubMenu
                key={"sub1"}
                icon={<BriefcaseIcon className="icon" />}
                style={{ backgroundColor: "white" }}
                expandIcon=" "
                title={
                  <>
                    <span>Overall Dashboard</span>
                  </>
                }
              >
                {this.state.showProposal ? (
                  <Menu.Item key="proposal">
                    <Link
                      onClick={(e) => this.performLinkClick()}
                      to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.proposal}`}
                    >
                      <div
                        style={{
                          paddingLeft: 10,
                        }}
                      >
                        Proposal
                      </div>
                    </Link>
                  </Menu.Item>
                ) : null}

                {this.state.showIssuedCase ? (
                  <Menu.Item key="issued-case">
                    <Link
                      onClick={(e) => this.performLinkClick()}
                      to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.issuedCase}`}
                    >
                      <div style={{ paddingLeft: 10 }}>
                        {this.props.route == Config.entity.hsbc.route
                          ? "Issued Cases"
                          : "Issued/ Accepted Cases"}
                      </div>
                    </Link>
                  </Menu.Item>
                ) : null}

                {this.props.route == Config.entity.hsbc.route ? (
                  this.state.showCertificateCollection ? (
                    <Menu.Item key="certificate-collection">
                      <Link
                        onClick={(e) => this.performLinkClick()}
                        to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.certificateCollection}`}
                      >
                        <div style={{ paddingLeft: 10 }}>
                          Certificate Collection Status
                        </div>
                      </Link>
                    </Menu.Item>
                  ) : null
                ) : null}

                {this.state.showClaim ? (
                  <Menu.Item key="claim">
                    <Link
                      onClick={(e) => this.performLinkClick()}
                      to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.claim}`}
                    >
                      <div style={{ paddingLeft: 10 }}>Claims</div>
                    </Link>
                  </Menu.Item>
                ) : null}

                {this.props.route == Config.entity.hsbc.route ? (
                  this.state.showBusinessOpp ? (
                    <Menu.Item key="business-opportunity">
                      <Link
                        onClick={(e) => this.performLinkClick()}
                        to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.businessOpportunity}`}
                      >
                        <div style={{ paddingLeft: 10 }}>
                          Business Opportunities
                        </div>
                      </Link>
                    </Menu.Item>
                  ) : null
                ) : null}
              </Menu.SubMenu>

              {this.props.route == Config.entity.hsbc.route ? (
                this.state.showBusinessOpp ||
                this.state.showCertificateCollection ? (
                  <Menu.SubMenu
                    key={"sub2"}
                    icon={<EventIcon className="icon" />}
                    expandIcon=" "
                    title={
                      <>
                        <span>Upcoming Events</span>
                      </>
                    }
                  >
                    
                {
                  this.props.route == Config.entity.hsbc.route ? (
                    this.state.showCertificateCollection ? (
                      <Menu.Item key="certificate-collection-upcoming">
                        <Link
                          onClick={(e) => this.performLinkClick()}
                          to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.certificateCollectionUpcoming}`}
                        >
                          <div style={{ paddingLeft: 10 }}>
                            Certificate Collection Status
                          </div>
                        </Link>
                      </Menu.Item>
                    ) : null
                  ) : null
                }

                {
                  this.props.route == Config.entity.hsbc.route ? (
                    this.state.showBusinessOpp ? (
                      <Menu.Item key="business-opportunity-upcoming">
                        <Link
                          onClick={(e) => this.performLinkClick()}
                          to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.businessOpportunityUpcoming}`}
                        >
                          <div style={{ paddingLeft: 10 }}>
                            Business Opportunities
                          </div>
                        </Link>
                      </Menu.Item>
                    ) : null
                  ) : null}

                  </Menu.SubMenu>
                ) : null
              ) : null}

              <Menu.Item key="form" icon={<FileIcon className="icon" />}>
                <Link
                  to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.form}`}
                >
                  <div>Forms</div>
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <div
            className={`sidebar-content ${
              this.state.collapsed ? "" : "disableDiv"
            }`}
          >
            <div
              className={`site-layout-background ${
                this.state.collapsed ? "ml_mc_w_c" : "ml_mc_w_nc"
              }`}
              style={{
                padding: "30px 40px 30px 40px",
                minHeight: 280,
                background: "#FAFAFA",
              }}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    overallListingGlobal: state.overallListing.value,
    upcomingListingGlobal: state.upcomingListing.value,
  };
};
const mapDispatchToProps = {
  setFilterDialog,
  setOverallListing,
  setUpcomingListing,
  setDateFilter,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppSidebar)
);
