class AppClaimStatusBean {
  constructor(icon, inactiveIcon, title, count, type, background, translation) {
    this.icon = icon;
    this.inactiveIcon = inactiveIcon;
    this.title = title;
    this.translation = translation;
    this.count = count;
    this.type = type;
    this.background = background;
  }
}

export default AppClaimStatusBean;
