import { Carousel } from "antd";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import Config from "../../../Config";
import "./app_carousel_dashboard.css";

const contentStyle = {
  height: "400px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "white",
  objectFit: "cover",
  width: "100%",
};

class AppCarouselDashboard extends Component {
  constructor(props) {
    super(props);
  }
  renderCarouselItem() {
    return this.props.data.map((item, index) => {
      return (
        <div key={index} className="carousel__wrapper">
            <div className="carousel__content">
              <div className="carousel__date">{item.date}</div>
              <div className="carousel__title">{item.title}</div>
              <div className="carousel__desc">{item.desc}</div>
              <Link
                to={`/${this.props.client}/${this.props.route}/${Config.routes.generic.announcement}/${item.id}`}
                style={{ color: "white" }}
              >
                <div className="d-flex align-items-center">
                  <div className="carousel__readmore">Read More</div>
                  <img
                    className="ml-3 carousel__image"
                    style={{ marginTop: 14 }}
                    src={
                      GeneralBloc.getAssetsContextPath() +
                      `/assets/icons/ArrowRight.svg`
                    }
                    alt="Arrow Right"
                  />
                </div>
              </Link>
          </div>
          <img
            src={`${item.image}`}
            alt="Carousel"
            //className="image_style"
            style={contentStyle}
          />
        </div>
      );
    });
  }

  render() {
    return (
        <Carousel autoplay slide="true" style={{width: "100%" }}>
          {this.renderCarouselItem()}
        </Carousel>
    );
  }
}

export default withRouter(AppCarouselDashboard);
