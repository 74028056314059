import AppAnnouncementsBean from "../data/bean/app_announcements_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import AuthBloc from "./auth_bloc";
import HomeWebService from "../service/home_web_service";

class AnnouncementsBloc {
  
  static async getNotificationCount(clientCode){
    let token = "";
    const session = await AuthBloc.checkSession();
  
    if (session) {
      token = session.accessToken;
    }
    let response = await HomeWebService.getNotificationCount(token,clientCode);
    let data=null;        
    if (response.success) {  
      data=response.data              
    }
    return data;    
  }

  static async getNotificationList(payload){
    let token = "";
    const session = await AuthBloc.checkSession();
  
    if (session) {
      token = session.accessToken;
    }
    let response = await HomeWebService.getNotificationList(token,payload);
    let data=null;        
    if (response.success) {        
      data=response.data              
    }
    return data;    
  }

  

  static async getAnnouncementDetails(entity,id) {        
    let token = "";
    const session = await AuthBloc.checkSession();
  
    if (session) {
      token = session.accessToken;
    }
    let response = await HomeWebService.getAnnouncementDetails(token, entity,id);
    let data={};   
    if (response.success) {      
      let image = HomeWebService.getAnnouncementImageUrl(
        token,
        "announcement",
        response.data.announcementId
      );
      data=response.data
      data.image=image;           
    }
    return data;    
  }
  
  static getBeanAnnouncementList(list) {
    let newList=[];
    if(list){
      list.map((ev,index)=>{
        newList.push( new AppAnnouncementsBean(
          ev.id,
          ev.title,
          ev.desc,
          ev.date,
          ev.image
        ),)
      })
    }
    return newList;
  }
  
  static truncateString(str, num) {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
      return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + "...";
  }

  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }
}

export default AnnouncementsBloc;
