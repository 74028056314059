import React, { Component } from "react";
import "./app_table_status.css";

class AppTableStatus extends Component {
  render() {
    console.log('AppTableStatus: textColor = '  + this.props.textColor + ', backgroundColor = ' + this.props.background)
    return (
      <div
        className={`table-status ${
          this.props.status === "green"
            ? "green"
            : this.props.status === "purple"
            ? "purple"
            : this.props.status === "blue"
            ? "blue"
            : this.props.status === "yellow"
            ? "yellow"
            : this.props.status === "grey"
            ? "grey"
            : this.props.status === "orange"
            ? "orange"
            : "red"
        }`}
        style={{
          backgroundColor:this.props.background,
          color:this.props.textColor
        }}
      >
        {this.props.statusName}
      </div>
    );
  }
}

export default AppTableStatus;
