import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import { Modal, ModalBody } from 'reactstrap';
import GeneralBloc from '../../bloc/general_bloc';
import AppLottie from '../component/lottie/app_lottie';
import animationData from '../../lottie/spinner.json';
class AppLoadingDialog extends React.Component {
  
  constructor(props) {
    super(props)
  }

    render() {
      return (
          <Modal
            className="modal-loading"
            backdrop="static"
            keyboard={false}
            centered
            style={{ maxWidth: '120px', maxHeight: '120px'}}
            isOpen={this.props.isLoading}
          >
            <ModalBody>
              <div className="modal-loading-img">                
                <div className='loading-lottie-spinner'>
                  <AppLottie
                    width={100}
                    height={100}
                    animatinoData={animationData}
                  />
                  {/* <img
                      className="img-fluid"
                      src={
                      GeneralBloc.getAssetsContextPath() +
                      `/assets/images/sprint_lottie.gif`
                      }
                  /> */}
                </div>
              </div>
            </ModalBody>
          </Modal>
      );
    }
}
export default withRouter(AppLoadingDialog)



// const AppLoadingDialog = ({props}) => {
//   const [show, setShow] = useState(true);

//   const handleClose = () => setShow(false);

//   return (
//     <>
//       <Modal 
//         {...props}
//         show={show} 
//         onHide={handleClose}
//         dialogClassName="app-loading-modal"
//         backdrop="static"
//         keyboard={false}
//         centered
//       >
        
//         <Modal.Body>
//           <div className="app-loading-img">
//             <img src={"/assets/images/loading.png"} />
//           </div>
//         </Modal.Body>
        
//       </Modal>
//     </>
//   );
// };

// export default AppLoadingDialog;

// class AppLoadingDialog extends React.PureComponent {
//   render() {
//     return (
//       <div className="app-loading-modal">
//         {props.children}
//       </div>
//     )
//   }
// }

// export default AppLoadingDialog;