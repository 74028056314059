import "./App.css";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import AppLoadingDialog from "./ui/dialog/app_loading_dialog";
import Config from "./Config";
import home_screen from "./ui/screen/home_screen/home_screen";
import "./lato_font/Lato-Regular.ttf";
import "./lato_font/Lato-Bold.ttf";
import announcements_screen from "./ui/screen/announcements_screen/announcements_screen";
import announcements_detail_screen from "./ui/screen/announcements_detail_screen/announcements_detail_screen";
import claims_screen from "./ui/screen/claims_sceeen/claims_screen";
import proposal_detail_screen from "./ui/screen/proposal_detail_screen/proposal_detail_screen";
import issued_cases_detail_screen from "./ui/screen/issued_cases_detail_screen/issued_cases_detail_screen";
import certificate_collection_detail_screen from "./ui/screen/certificate_collection_detail_screen/certificate_collection_detail_screen";
import proposal_outstanding_screen from "./ui/screen/proposal_outstanding_screen/proposal_outstanding_screen";
import forms_screen from "./ui/screen/forms_screen/forms_screen";
import claims_detail_screen from "./ui/screen/claims_detail_screen/claims_detail_screen";
import login_screen from "./ui/screen/login_screen/login_screen";
import change_password_screen from "./ui/screen/login_screen/change_password_screen";
import reset_password_screen from "./ui/screen/login_screen/reset_password_screen";
import select_account_screen from "./ui/screen/select_account_screen/select_account_screen";
import profile_screen from "./ui/screen/profile_screen/profile_screen";
import submitted_documents_screen from "./ui/screen/submitted_documents_screen/submitted_documents_screen";
import AuthBloc from "./bloc/auth_bloc";
import { connect } from "react-redux";
import { setAuth, logOut } from "./data/redux/reducers/authReducer";
import connectivity_screen from "./ui/screen/connectivity_screen";
import certificate_collection_status_screen from "./ui/screen/certificate_collection_status_screen/certificate_collection_status_screen";
import business_opportunities_screen from "./ui/screen/business_opportunities_screen/business_opportunities_screen";
import proposal_screen_v2 from "./ui/screen/proposal_screen.js/proposal_screen_v2";
import issed_case_screen_v2 from "./ui/screen/issued_cases_screen/issed_case_screen_v2";
import claims_screen_v2 from "./ui/screen/claims_sceeen/claims_screen_v2";
import certificate_collection_status_screen_v2 from "./ui/screen/certificate_collection_status_screen/certificate_collection_status_screen_v2";
import certificate_collection_status_upcoming_screen from "./ui/screen/certificate_collection_status_upcoming_screen/certificate_collection_status_upcoming_screen_v2";
import business_opportunities_screen_v2 from "./ui/screen/business_opportunities_screen/business_opportunities_screen_v2";
import business_opportunities_upcoming_screen_v2 from "./ui/screen/business_opportunities_upcoming_screen/business_opportunities_upcoming_screen_v2";
import certificate_collection_status_upcoming_screen_v2 from "./ui/screen/certificate_collection_status_upcoming_screen/certificate_collection_status_upcoming_screen_v2";
import business_opportunities_detail_screen from "./ui/screen/business_opportunities_detail_screen/business_opportunities_detail_screen";
import login_screen_admin from "./ui/screen/login_screen/login_screen_admin";
import daily_reminders_screen from "./ui/screen/daily_reminders_screen/daily_reminders_screen";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      client: Config.context,
    };
  }

  async componentDidMount() {
    await this.checkSession();
    this.setState({
      loading: false,
    });
  }

  async checkSession() {
    AuthBloc.checkSession()
      .then((result) => {
        if (result) {
          this.props.setAuth(result);
        } else {
          this.setState({ loading: false });
          this.redirectToLogin();
        }
      })
      .catch((e) => {
        this.redirectToLogin();
      });
  }

  redirectToLogin() {
    return (
      <Redirect to={`/${this.state.client}/${Config.routes.generic.login}`} />
    );
    //window.location.href = `/${Config.context}/${Config.routes.generic.login}`
  }

  //The loading state for the screen
  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  //The paths setup to determine which screen to display
  appContent() {
    return (
      <>
        <Route
          exact
          path={`/${this.state.client}/${Config.routes.generic.connectivity}`}
          component={connectivity_screen}
        />

        {/* ---------- Start : Enhanced Route Paths ---------- */}
        {/* 
          :entity - [HSBC,Alliance]. To identify which bank entity that is currently browsing the site
          :itemId - To identify which item is currently being viewed in this detail screen, coming from the listing page
        */}
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.home}`}
          component={home_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/${Config.routes.generic.login}`}
          component={login_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/${Config.routes.generic.loginAdmin}`}
          component={login_screen_admin}
        />
        <Route
          exact
          path={`/${this.state.client}/${Config.routes.generic.changePassword}`}
          component={change_password_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/${Config.routes.generic.resetPassword}`}
          component={reset_password_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/${Config.routes.generic.account}`}
          component={select_account_screen}
        />

        {/* Listing Page */}
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.announcement}`}
          component={announcements_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.proposal}`}
          component={proposal_screen_v2}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.issuedCase}`}
          component={issed_case_screen_v2}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.certificateCollection}`}
          component={certificate_collection_status_screen_v2}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.certificateCollectionUpcoming}`}
          component={certificate_collection_status_upcoming_screen_v2}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.claim}`}
          component={claims_screen_v2}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.form}`}
          component={forms_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.profile}`}
          component={profile_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.businessOpportunity}`}
          component={business_opportunities_screen_v2}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.businessOpportunityUpcoming}`}
          component={business_opportunities_upcoming_screen_v2}
        />

        {/* Detail Page */}
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.dailyReminder}`}
          component={daily_reminders_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.announcement}/:itemId`}
          component={announcements_detail_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.proposal}/:itemId`}
          component={proposal_detail_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.document}/submitted/:itemId/:docClass`}
          component={submitted_documents_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.issuedCase}/:itemId`}
          component={issued_cases_detail_screen}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.businessOpportunity}/:itemId`}
          component={business_opportunities_detail_screen}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.businessOpportunityUpcoming}/:itemId`}
          component={business_opportunities_detail_screen}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.certificateCollection}/:itemId`}
          component={certificate_collection_detail_screen}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.certificateCollectionUpcoming}/:itemId`}
          component={certificate_collection_detail_screen}
        />

        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.claim}/:itemId`}
          component={claims_detail_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/${Config.routes.generic.account}`}
          component={select_account_screen}
        />
        <Route
          exact
          path={`/${this.state.client}/:entity/${Config.routes.generic.profile}/:itemId`}
          component={profile_screen}
        />

        {/* Home Page */}
        <Route
          exact
          path="/"
          render={() => {
            return (
              <Redirect
                to={`/${this.state.client}/HSBC/${Config.routes.generic.home}`}
              />
            );
          }}
        />
        {/* http://localhost:3000/portal/HSBC/home
        http://localhost:3000/portal/HSBC/announcement/10101 */}
        <Route
          exact
          path={`/${this.state.client}/`}
          render={() => {
            return (
              <Redirect
                to={`/${this.state.client}/HSBC/${Config.routes.generic.home}`}
              />
            );
          }}
        />
        {/* ---------- End : Enhanced Route Paths ---------- */}
      </>
    );
  }

  render() {
    return this.state.loading === false
      ? this.appContent()
      : this.loadingContent();
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = { setAuth, logOut };

export default connect(mapStateToProps, mapDispatchToProps)(App);
