import { Button, Pagination, Table } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as LeftArrowIcon } from "../../../Arrow_Left.svg";
import { ReactComponent as RightArrowIcon } from "../../../Arrow_Right.svg";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_table.css";

const onChange = (pagination, filters, sorter, extra) => {
  //console.log("params", pagination, filters, sorter, extra);
};

let locale = {
  emptyText: (
    <div className="justify-content-center p-3">
      <div>
        <img
          src={
            GeneralBloc.getAssetsContextPath() +
            `/assets/images/Illustration/Data Empty.svg`
          }
          alt="Data Not Found"
        />
      </div>
      <div style={{ color: "black", fontWeight: 600 }}>No data found.</div>
      <div>Try changing the filters by clicking the tags under search bar.</div>
    </div>
  ),
};

class AppTable extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(page) {
    this.props.onSelectedPage(page);
  }

  handleShowIndexOfPages() {
    let startItem = (this.props.currentPage - 1) * 10 + 1;
    let endItem = startItem - 1 + this.props.data.length;

    // if (endItem < this.props.totalData) {
    //   endItem = this.props.totalData;
    // }
    let record = {
      startItem: startItem,
      endItem: endItem,
    };    
    return record;
  }

  render() {
    return (
      <>
        <Table
          // scroll={{x: this.props.width, y: this.props.height}}
          scroll={{x: 1300}}
          style={{borderCollapse:"collapse"}}          
          locale={locale}
          columns={this.props.collumn ?? null}
          dataSource={this.props.data}
          onChange={onChange}
          pagination={false}
          /* rowClassName={() => "rowClassName1"} */
          onRow={(record) => ({
            onClick: (e) => {
              /* Call some endPoint to log this click event */
              if (e.target.id == "download") {
                this.props.handleDownloadOnClick(record);
              } else {
                this.props.handleOnClick(record.pRecMap);
              }
            },
          })}
        />
        <div
          style={{ backgroundColor: "white", padding: 16 }}
          className="align-items-center d-flex  w-100"
        >
          {this.props.data.length ? (
            <>
              {!this.props.isLoading ? (
                <div style={{ width: "40%" }}>
                  Showing {this.handleShowIndexOfPages().startItem} -{" "}
                  {this.handleShowIndexOfPages().endItem} of{" "}
                  {this.props.totalData} items
                </div>
              ) : (
                <div style={{ width: "40%" }}>
                -
                </div>
              )}
              <div className="d-flex align-items-center">
                <div className="page_title_paggination">Page</div>
                <Pagination
                  current={this.props.currentPage || 1}
                  total={this.props.totalData}
                  onChange={this.handleChange}
                  style={{ bottom: "0px", width: "100%" }}
                  nextIcon={<RightArrowIcon />}
                  prevIcon={<LeftArrowIcon />}
                  pageSizeOptions={[10]}
                  showLessItems
                  size="default"
                />
                <div
                 className="page_total_paggination"
                >
                  of {Math.ceil(this.props.totalData / 10)}
                </div>
              </div>
              <div></div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(AppTable);
