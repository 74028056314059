import React, { Component } from "react";
import AppcategoryBean from "../data/bean/app_claim_status_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import AppTableStatus from "../ui/component/app_table_status/app_table_status";
import GeneralBloc from "./general_bloc";
import FormWebServices from "../service/form_web_services";
import AuthBloc from "./auth_bloc";

import { ReactComponent as DownloadIcon } from "../Download.svg";

class FormsBloc extends Component {

  static async getFormDataTable(
    entity,
    dateFilter,
    fromDate,
    toDate,
    regions,
    page,
    searchBy,
    keyword
  ) {
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response = await FormWebServices.getForm(
      token,
      entity,
      page,
      null,
      null,
      regions,
      searchBy,
      keyword
    );

    let dataSet = {};    
    if (response.success) {      
      dataSet = await GeneralBloc.getListingTable(response,"form");
    }    
    let result = {
      dataCollumn: dataSet.columnTable || [],
      dataClaim: dataSet.data || [],
      totalData: dataSet.total || [],
      //categories:response.data.criFormPage.form[1].typeConfig.values
    };
    return result;
  }

  static getDummyFormsStatuses() {
    return [
      new AppcategoryBean("Received.svg", "Submitted", 30),
      new AppcategoryBean("InProgress.svg", "In Progress", 30),
      new AppcategoryBean("Approved.svg", "Approved", 10),
      new AppcategoryBean("Closed.svg", "Closed", 10),
      new AppcategoryBean("Rejected.svg", "Rejected", 30),
      new AppcategoryBean("Mixed Decisions.svg", "Mixed Decisions", 30),
    ];
  }

  static getCollumnTable() {
    return [
      {
        title: "File Name",
        dataIndex: "fileName",

        // specify the condition of filtering result
        // here is that finding the name started with `value`
      },
      {
        title: "Category",
        dataIndex: "category",
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];
  }
  static getDummyFormsDataTable() {
    return [
      {
        key: "1",
        fileName: "Absolute Assignment",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "2",
        fileName: "Assignment of takaful certificate as collateral",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "3",
        fileName: "Cancellation/surrender form",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "4",
        fileName: "Change request form",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "5",
        fileName: "Investment form",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "6",
        fileName: "Nomination",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "7",
        fileName: "PDPA data access form",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "8",
        fileName: "Personal health delcaration form",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "9",
        fileName: "Reassignment",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "10",
        fileName: "Reassignment 2",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
      {
        key: "11",
        fileName: "Reassignment 3",
        category: (
          <AppTableStatus
            statusName="Takaful certificate servicing"
            status={"grey"}
          />
        ),
        lastUpdated: "25/01/2022",
        size: "1 MB",
        action: (
          <div className="pl-3">
            <DownloadIcon />,
          </div>
        ),
      },
    ];
  }
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }
}

export default FormsBloc;
