import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({});

export const filterReducer = createSlice({
  name: "dtOverallFilter",
  initialState,
  reducers: {
    setDTOverallFilter: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = filterReducer;
export const { setDTOverallFilter } = actions;

export default reducer;
