import { Component } from "react";
import AppHeaderBean from "../data/bean/app_header_bean";
import BusinessOpportunitiesWebService from "../service/business_opoortunities_web_service";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";

class BusinessOpportunitiesBloc extends Component {
  static async getBusinessOpportunitiesStatuses(
    entity,
    module,
    moduleId,
    dateFilter,
    fromDate,
    toDate,
    searchBy,
    keyword,
    regions,
    branches,
    agents
  ) {
    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let payload={
      "proposal-dt-range": dateFilter,
      dtFrom: getDateFilter.beginDate,
      dtTo: getDateFilter.endDate,
      "proposal-attr-search": searchBy,
      searchBy: keyword,
      regions: regions,
      branches: branches,
      agents: agents,
    }

    if(dateFilter!="range"){
      delete payload.dtFrom;
      delete payload.dtTo
    }

    let response = await GeneralBloc.getStatusBox(
      module,
      moduleId,
      false,
      false,
      payload
    );
    return response;
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getBusinessOpportunitiesDataTable(
    entity,
    dateFilter,
    fromDate,
    toDate,
    regions,
    page,
    searchBy,
    keyword,
    branches,
    agents
  ) {
    let totalData = 0;

    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    //console.log("Getting regions:", regions);
    let response =
      await BusinessOpportunitiesWebService.getBusinessOpportunities(
        token,
        entity,
        page,
        getDateFilter.beginDate,
        getDateFilter.endDate,
        regions,
        searchBy,
        keyword,
        branches,
        agents,
        dateFilter
      );

    let dataSet = {};
    if (response.success) {
      dataSet = await GeneralBloc.getListingTable(
        response,
        "businessOpportunity"
      );
    }
    //console.log(totalData);
    let result = {
      dataCollumn: dataSet.columnTable || [],
      dataBusinessOpportunities: dataSet.data || [],
      totalData: dataSet.total || [],
    };
    return result;
  }
  static async getBusinessOpportunitiesDetail(entity, recMap) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response =
      await BusinessOpportunitiesBloc.getBusinessOpportunitiesDetail(
        token,
        entity,
        recMap
      );

    if (response.success) {
      result = response.data;
    }
    return result;
  }

  // static async downloadFile(module, id) {
  //   let resp = await HomeWebService.downloadFile(module, id);
  //   if (resp.data) {
  //     return new DataResult(true, resp.message, resp);
  //   } else {
  //     return new DataResult(false, resp.message, null);
  //   }
  // }
}

export default BusinessOpportunitiesBloc;
