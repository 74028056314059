import axios from "axios";
import { Component } from "react";
import BaseWebService from "./base_web_service";

class AuthWebService extends Component {
  static async login(userName, password, system, godModeforUser) {
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/localAuth/login`,
      {
        userName: userName,
        password: password,
        system: system,
        godModeForUserId: godModeforUser,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async resetPassword(userName, password) {
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/localAuth/resetPassword`,
      {
        userName: userName,
        passwordAnswer: password,
      }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async changePassword(userName, oldPassword, password) {
    let headers = await BaseWebService.headers();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/localAuth/changePassword`,
      {
        oldPassword: oldPassword,
        newPassword: password,
        userName: userName,
      },
      { headers: headers }
    );

    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
  
  static async logOutPortal() {
    let headers = await BaseWebService.headers();
    let resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/localAuth/logout`,
      { headers: headers }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async renewToken(renewToken) {
    let headers = await BaseWebService.fixedHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/localAuth/renewJwtToken`,
      {
        renewTokenStr: renewToken,
      },
      { headers: headers }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
}

export default AuthWebService;
