import React from "react";
import AppContentFieldItem from "./app_content_field_item";
import { Col, Collapse, Row } from "antd";
import "./customer_details_card.css";

const { Panel } = Collapse;

class CustomerDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  stateData() {
    return this.state.data;
  }
  stateParticipant() {
    return this.stateData().participant;
  }
  stateCoveredPersons() {
    return this.stateData().covereds;
  }

  componentDidMount() {}

  renderField(field) {
    if (field)
      return (
        <AppContentFieldItem
          title={field.caption}
          value={
            field.value == "" || field.value == 0.0 || field.value == null
              ? "N/A"
              : field.value
          }
        />
      );

    return <div />;
  }

  renderCovereds(covereds) {
    if (!covereds || covereds.length <= 0) return <div />;

    let children = [];
    for (var i = 0; i < covereds.length; i++) {
      let covered = covereds[i];
      children.push(
        this.renderRow(
          this.renderField(covered.name),
          this.renderField(covered.id)
        )
      );
      children.push(
        this.renderRow(
          this.renderField(covered.dob),
          this.renderField(covered.age)
        )
      );
      children.push(
        this.renderRow(
          this.renderField(covered.mobileNumber),
          this.renderField(covered.email)
        )
      );
      children.push(
        this.renderRow(this.renderField(covered.gender), this.renderField(null))
      );
    }
    return children;
  }

  renderChildren() {
    let components = this.props.children || [];
    components = components.filter((v) => v != undefined && v != null);
    let children = [];
    if (this.propAttr().showDividerStart) {
      children.push(this.divider());
    }
    for (var i = 0; i < components.length; i++) {
      children.push(components[i]);
      if (this.propAttr().showDividerMiddle && i != components.length - 1) {
        children.push(this.divider());
      }
    }
    if (this.propAttr().showDividerEnd) {
      children.push(this.divider());
    }
    return children;
  }

  renderRow(item1, item2) {
    return (
      <Row>
        <Col span={12}>{item1}</Col>
        <Col span={12}>{item2}</Col>
      </Row>
    );
  }

  render() {
    return (
      <Collapse
        className="customer-detail-card"
        bordered={false}
        expandIconPosition={"end"}
        style={{
          backgroundColor: "white",
          marginBottom: 16,
          marginRight: 16,
        }}
        defaultActiveKey={[1]}
      >
        <Panel
          key={1}
          header={this.stateData().caption || ""}
          className="customer-detail-title"
        >
          {this.renderRow(
            this.renderField(this.stateParticipant().name),
            this.renderField(this.stateParticipant().id)
          )}
          {this.renderRow(
            this.renderField(this.stateParticipant().dob),
            this.renderField(this.stateParticipant().age)
          )}
          {this.renderRow(
            this.renderField(this.stateParticipant().mobileNumber),
            this.renderField(this.stateParticipant().email)
          )}
          {this.renderRow(
            this.renderField(this.stateParticipant().gender),
            this.renderField(this.stateParticipant().null)
          )}
          {this.renderCovereds(this.stateCoveredPersons())}
        </Panel>
      </Collapse>
    );
  }
}

export default CustomerDetailsCard;
