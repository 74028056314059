import Config from '../Config';
import Moment from 'moment';
import { globalState } from '../data/global/globalState';

class SessionBloc {

    static setGlobalUserConfig(config){
        globalState.userConfig = config;
    }
    static setGlobalFilterBy(filterBy){
        globalState.filterBy = filterBy;
    }
    static setGlobalSearchBy(searchBy){
        globalState.searchBy = searchBy;
    }
    static setGlobalUserInfo(userInfo){
        // console.log("Setting user global info to " + JSON.stringify(userInfo));
        globalState.userInfo = userInfo;
        // console.log("Getting user global info = " + JSON.stringify(globalState.userInfo));
    }

    static privateEncode(auth) {
        return auth
            .toLocaleLowerCase()
            .replaceAll('a', '1')
            .replaceAll('b', '2')
            .replaceAll('c', '3')
            .replaceAll('d', '4')
            .replaceAll('e', '5')
            .replaceAll('6', 'z')
            .replaceAll('7', 'x')
            .replaceAll('8', 'c')
            .replaceAll('9', 'v');
    }

    static generateStatelessAuth() {
        let username = 'fwdbancaStateless';
        let password = 'PRUfwdbanca123';
        let date = Moment(new Date()).format('YYYY-MM-DD');

        let basicAuth = username + ':' + password + ':' + date;
        // var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
        // let encodedAuth = Base64.encode(basicAuth);
        let encodedAuth = btoa(basicAuth);

        return this.privateEncode(encodedAuth);
    }

    //Generate UUID
    static getRequestUUID() {
        var d = new Date().getTime(); //Timestamp
        var d2 =
            (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = Math.random() * 16; //random number between 0 and 16
                if (d > 0) {
                    //Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {
                    //Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
    }

    //Get current session in cache for the particular product
    static getCurrentSessionId(productCode) {
        let sessionId = localStorage.getItem(
            Config.cache.sessionID + productCode
        );
        if (!sessionId || sessionId === null || sessionId === '') {
            sessionId = this.getRequestUUID();
            localStorage.setItem(
                Config.cache.sessionID + productCode,
                sessionId
            );
        }
        return sessionId;
    }

    //Set the new session ID to cache for the particular product
    static setCurrentSessionId(productCode, sessionId) {
        localStorage.setItem(Config.cache.sessionID + productCode, sessionId);
    }

}

export default SessionBloc;
