import Config from "../../../Config";
import Listing_screen from "./listing_screen";
import React from "react";
import { withRouter } from "react-router-dom";

class ProposalScreenV2 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <Listing_screen
        listId={"df-proposal-list"} //To define which list this is loading for
        countId={"df-proposal-count"} //To define which count to retrieve
        module={"proposal"} //To define which module this list is for
        detailRoute={Config.routes.generic.proposal} //Route to detail screen
        listingTitle={"Proposal"} //Title of listing
        dateRange={"overall"}
      />
    );
  }
}

export default withRouter(ProposalScreenV2);
