import React from "react";
import Lottie from "lottie-react";
import animationData from '../../../lottie/spinner.json';


class AppLottie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      height: props.height,
      animationData: props.animationData
    };
  }

  stateWidth(){return this.state.width}
  stateHeight(){return this.state.height}
  stateAnimationData(){return this.state.animationData}

  componentDidMount(){

  }

  config(){
    return {
      loop: true,
      autoplay: true,
      animationData: this.stateAnimationData(),
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

  render() {
    return (
        <div style={{
            // width: this.stateWidth(),
            // height: this.stateHeight(),
            // display: 'flex',
            // flexDirection: 'column',
            // position: 'relative',
            // alignItems: 'center',
            // justifyContent: 'center'
        }}>
            <Lottie
                animationData={this.state.animationData || animationData}
                loop={true}
                autoplay={true}
            />          
        </div>
        // <Lottie 
        //     options={this.config()}
        //     height={this.stateWidth()}
        //     width={this.stateHeight()}
        // />
    );
  }
}

export default AppLottie;
