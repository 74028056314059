/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import Config from "../../../Config";
import App_claim_status_mini from "../../component/app_claim_status/app_claim_status_mini";
import AppEmptyData from "../../component/app_empty_data/app_empty_data";
import App_daterange_filter from "../../component/app_filters/app_daterange_filter";
import AppDialogFilter from "../../component/app_filters/app_dialog_filter";
import App_header from "../../component/app_header/app_header";
import App_header_title_filter from "../../component/app_header_title/app_header_title_filter";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import App_table from "../../component/app_table/app_table";
import App_searching_dialog from "../../dialog/app_searching_dialog/app_searching_dialog";
import App_dropdown_filter from "../../component/app_filters/app_dropdown_filter";
import "./proposal_screen.css";
import App_dropdown_filter2 from "../../component/app_filters/app_dropdown_filter2";
import App_search_filter from "../../component/app_filters/app_search_filter";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import { setFilterBy } from "../../../data/redux/reducers/filterReducer";
import { setUserData } from "../../../data/redux/reducers/userDataReducer";
import { setFilterBy1 } from "../../../data/redux/reducers/filterReducer1";
import { setUserConfig } from "../../../data/redux/reducers/userConfigReducer";
import { setFilterDialog } from "../../../data/redux/reducers/filterDialogReducer";
import { setDateFilter } from "../../../data/redux/reducers/dateFilterReducer";
import { setOverallListing } from "../../../data/redux/reducers/overallListingReducer";
import { setUpcomingListing } from "../../../data/redux/reducers/upcomingListingReducer";
import UtilBloc from "../../../bloc/util_bloc";
import Base_screen from "../base/base_screen/base_screen";
import ListingBloc from "../../../bloc/listing_bloc";
import App_claim_status_mini_skeleton from "../../component/app_claim_status/app_claim_status_mini_skeleton";
import App_daterange_filter_upcoming from "../../component/app_filters/app_daterange_filter_upcoming";
import { current } from "@reduxjs/toolkit";
import AppFieldError from "../../component/form/app_field_error/app_field_error";

class ListingScreen extends Component {
  constructor(props) {
    super(props);
    this.handleFilterDate = this.handleFilterDate.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleOnClickSearch = this.handleOnClickSearch.bind(this);
    this.handleDetailRedirection = this.handleDetailRedirection.bind(this);
    this.handleSelectedSearchType = this.handleSelectedSearchType.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleChangeModule = this.handleChangeModule.bind(this);

    let listId = this.props.listId; //To define which list this is loading for
    let countId = this.props.countId; //To define which count to retrieve
    let module = this.props.module; //To define which module this list is for
    let detailRoute = this.props.detailRoute; //Route to detail screen
    let listingTitle = this.props.listingTitle; //Title of listing
    let part = this.props.part;
    let section = this.props.dateRange;

    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      route: routeParams,
      client: Config.context,
      proposalStatuses: [],
      loaded: false,
      proposalData: {
        dataCollumn: [],
        dataProposal: [],
        totalData: 0,
      },
      userData: {},
      currentPage: this.props.dialogFilterGlobal?.currentPage
        ? this.props.dialogFilterGlobal.currentPage
        : 1,
      fromDate: "1900-01-01",
      toDate: "2050-01-01",
      searchBy: "",
      keyword: "",
      isValidBySearch: true,
      searchCriteria: [],
      isOpenSearchingAgentDialog: false,
      selections: [],
      regions: [],
      branches: [],
      agents: [],
      searchType: "byGroup",
      loadingStatuses: false,
      idModuleListing: listId,
      module: module,
      countId: countId,
      detailRoute: detailRoute,
      listingTitle: listingTitle,
      statusFilter: [],
      upcomingEventDropDown: part,
      dataDateByCaption: [],
      dataDateByValue: [],
      part: part == "dashboard" ? "Overall Dashboard" : "Upcoming Events",
      section: section,
      show: part ? true : false,
      appliedFilters: {},
      appliedFilterAll: {},

      overallListing: this.props.overallListingGlobal,
      upcomingListing: this.props.upcomingListingGlobal,
      dashboardListing: this.props.dateFilterGlobal,
    };
  }

  componentDidMount() {}

  stateStatuses() {
    return this.state.proposalStatuses;
  }

  stateExportIndicator() {
    return process.env.REACT_APP_EXPORT_FUNCTION == "true";
  }

  getSearchCaptionList() {
    let captionList = this.props.globalSearchBy.resultColumns[0].list;
    let data = [...captionList];
    let entity = this.getEntity();
    if (entity && entity == "Alliance") {
      let record = data.pop();
      data.unshift(record);
    }
    return data;
  }

  getsearchValueList() {
    let valueList = this.props.globalSearchBy?.resultColumns[1].list;
    let data = [...valueList];
    let entity = this.getEntity();
    if (entity && entity == "Alliance") {
      let record = data.pop();
      data.unshift(record);
    }
    return data;
  }

  getEntity() {
    return this.state.route.entity;
  }

  getDefaultSearchBy() {
    let entity = this.getEntity();
    if (entity == "Alliance") {
      this.setState({
        searchBy: "bankReferenceNumber",
      });
    } else {
      this.setState({
        searchBy: "certificateNumber",
      });
    }
  }

  async init() {
    console.log("check state from history", this.props.location);
    await this.initSearchCriteria();
    await this.getDateRangeFilter();
    this.setState({
      loaded: true,
    });
    this.getDefaultSearchBy();

    let defaultDate = await UserAccessBloc.getUserDateRangeForModule(
      this.props.userGlobal.roles[0].mapCode,
      this.props.userGlobal.roles[0].entity,
      this.state.module,
      this.props.userConfigGlobal
    );

    let dateValue = this.resumeState();
    let dateCaption = dateValue;
    let dateCode;

    if (dateValue == "all") {
      dateCaption = defaultDate.options[0].code;
      dateCode = "all";
    } else {
      dateCode = dateValue;
    }

    let datePlaceHolderByDashboard = defaultDate.options.filter(
      (item) => item.code == dateCaption
    );
    let defaultDateRange = dateCode || defaultDate.default;
    if (defaultDateRange == "all") {
      defaultDateRange = defaultDate.options[0].code;
    }
    let defaultDateRangePlaceHolder = null;

    let isDefault = this.checkDate();

    if (isDefault) {
      // check section
      if (this.state.section == "overall") {
        defaultDateRange = defaultDate.options[1].code;
        defaultDateRangePlaceHolder = defaultDate.options[1].title;
      } else {
        defaultDateRange = defaultDate.options[0].code;
        defaultDateRangePlaceHolder = defaultDate.options[0].title;
      }
    } else {
      defaultDateRangePlaceHolder =
        datePlaceHolderByDashboard.length > 0
          ? datePlaceHolderByDashboard[0].title
          : defaultDate.defaultPlaceholder;
    }
    let fromDate;
    if (this.state.section == "overall") {
      fromDate = this.state.overallListing
        ? this.state.overallListing?.overallFromDate
        : null;
    } else {
      fromDate = this.state.upcomingListing
        ? this.state.upcomingListing?.upcomingFromDate
        : null;
    }

    let toDate;
    if (this.state.section == "overall") {
      toDate = this.state.overallListing
        ? this.state.overallListing?.overallToDate
        : null;
    } else {
      toDate = this.state.upcomingListing
        ? this.state.upcomingListing?.upcomingToDate
        : null;
    }
    let statusFilter = JSON.parse(
      (this.state.section == "overall"
        ? this.state.overallListing
          ? this.state.overallListing.statusFilter
          : null
        : this.state.upcomingListing
        ? this.state.upcomingListing.statusFilter
        : null) || "[]"
    );

    //console.log("Check status global", statusFilter);

    let searchType =
      (this.state.section == "overall"
        ? this.state.overallListing?.searchType
        : this.state.upcomingListing?.searchType) || "";

    let searchBy =
      (this.state.section == "overall"
        ? this.state.overallListing?.searchBy
        : this.state.upcomingListing?.searchBy) || "";

    let keyword =
      (this.state.section == "overall"
        ? this.state.overallListing?.keyword
        : this.state.upcomingListing?.keyword) || "";

    this.setState({
      dateFilter: defaultDateRange,
      dateFilterPlaceholder: defaultDateRangePlaceHolder,
      fromDate: fromDate,
      toDate: toDate,
      statusFilter: statusFilter,
      searchType: searchType,
      searchBy: searchBy,
      keyword: keyword,
    });

    this.setState({
      defaultDateRange: defaultDateRange,
      proposalStatuses: [],
      userData: this.props.userDataGlobal.data,
    });

    if (this.state.section == "overall") {
      if (this.props.location.dataListing) {
        this.setState({
          proposalData: this.props.location.dataListing,
          proposalStatuses: this.props.location.proposalStatuses,
          statusFilter: this.props.location.statusFilter,
        });
      } else {
        this.fetchListing();
      }
    }

    if (this.state.section == "upcoming") {
      if (this.props.location.dataListing) {
        this.setState({
          proposalData: this.props.location.dataListing,
          proposalStatuses: this.props.location.proposalStatuses,
          statusFilter: this.props.location.statusFilter,
        });
      } else {
        this.fetchListing();
      }
    }
  }

  async getDateRangeFilter() {
    let dateRangeSetting = await GeneralBloc.getDateRangeFromConfig(
      this.props.userConfigGlobal,
      this.state.module
    );

    this.setState({
      dateRangeCaptions: dateRangeSetting.captions,
      dateRangeCodes: dateRangeSetting.codes,
    });
  }

  resumeState() {
    let section = this.state.section;
    let dateValue = "";

    if (section == "overall") {
      this.props.setOverallListing({
        overallDateValue:
          this.state.overallListing &&
          this.state.overallListing?.overallDateValue
            ? this.state.overallListing?.overallDateValue
            : this.state.dashboardListing?.overallDateValue,
        overallFromDate:
          this.state.overallListing && this.state.overallListing.overallFromDate
            ? this.state.overallListing.overallFromDate
            : this.state.dashboardListing?.overallFromDate,
        overallToDate:
          this.state.overallListing && this.state.overallListing.overallToDate
            ? this.state.overallListing?.overallToDate
            : this.state.dashboardListing?.overallToDate,
        statusFilter: this.state.overallListing?.statusFilter,
        searchType:
          this.props.overallListingGlobal?.searchType || this.state.searchType,
        searchBy:
          this.props.overallListingGlobal?.searchBy || this.state.searchBy,
        keyword: this.props.overallListingGlobal?.keyword || this.state.keyword,
      });
      dateValue =
        this.state.overallListing?.overallDateValue ||
        this.state.dashboardListing?.overallDateValue;
    } else {
      this.props.setUpcomingListing({
        upcomingDateValue:
          this.state.upcomingListing &&
          this.state.upcomingListing.upcomingDateValue
            ? this.state.upcomingListing?.upcomingDateValue
            : this.state.dashboardListing?.upcomingDateValue,
        upcomingFromDate:
          this.state.upcomingListing &&
          this.state.upcomingListing.upcomingFromDate
            ? this.state.upcomingListing?.upcomingFromDate
            : this.state.dashboardListing?.upcomingFromDate,
        upcomingToDate:
          this.state.upcomingListing &&
          this.state.upcomingListing.upcomingToDate
            ? this.state.upcomingListing?.upcomingToDate
            : this.state.dashboardListing?.upcomingToDate,
        statusFilter: this.state.upcomingListing?.statusFilter,
        searchType:
          this.props.upcomingListingGlobal?.searchType || this.state.searchType,
        searchBy:
          this.props.upcomingListingGlobal?.searchBy || this.state.searchBy,
        keyword:
          this.props.upcomingListingGlobal?.keyword || this.state.keyword,
      });
      dateValue =
        this.state.upcomingListing?.upcomingDateValue ||
        this.state.dashboardListing?.upcomingDateValue;
    }

    this.setState({
      dashboardListing: this.props.dateFilterGlobal,
      overallListing: this.props.overallListingGlobal,
      upcomingListing: this.props.upcomingListingGlobal,
    });
    return dateValue;
  }

  checkDate() {
    let isDefault = false;
    let dateFilterOverall =
      this.state.overallListing?.overallDateValue ||
      this.state.dashboardListing?.overallDateValue;

    let dateFilterUpcoming =
      this.state.upcomingListing?.upcomingDateValue ||
      this.state.dashboardListing?.upcominglDateValue;

    if (this.state.section == "overall") {
      if (
        dateFilterOverall == "range" &&
        (!this.state.dashboardListing?.overallToDate ||
          !this.state.dashboardListing?.overallFromDate) &&
        (!this.state.overallListing?.overallToDate ||
          !this.state.overallListing?.overallFromDate)
      ) {
        isDefault = true;
      }
    }
    if (this.state.section == "upcoming") {
      if (
        dateFilterUpcoming == "range" &&
        (!this.state.dashboardListing?.upcomingToDate ||
          !this.state.dashboardListing?.upcomingFromDate) &&
        (!this.state.upcomingListing?.upcomingToDate ||
          !this.state.upcomingListing?.upcomingFromDate)
      ) {
        isDefault = true;
      }
    }
    return isDefault;
  }

  async canLoadList() {
    let criteria = await this.getSearchCriterias();
    let filterState = this.state.filterState;
    let canLoad = true;
    for (let x = 0; x < criteria.length; x++) {
      // Get Regions from User logged in
      let crit = criteria[x];
      canLoad = canLoad && filterState[crit.code];
    }
    /* console.log("Can load list for criterias = " + canLoad);
    console.log("Can load list for dateFilter = " + this.state.dateFilter);
 */
    return (
      (this.state.searchType == "bySearch" &&
        this.state.keyword &&
        this.state.keyword.length > 0) ||
      (canLoad &&
        this.state.dateFilter != null &&
        this.state.searchType != "bySearch")
    );
  }

  async loadSearchCriteria() {
    let criteria = await this.getSearchCriterias();

    let appliedFilters = this.state.appliedFilters;
    let appliedFilterAll = this.state.appliedFilterAll;
    let filterState = this.state.filterState;
    let availableCriterias = [];

    //Setting up the criterias
    for (let x = 0; x < criteria.length; x++) {
      // Get Regions from User logged in
      let crit = criteria[x];
      let defaultVal = crit.default;
      let isAll = defaultVal && defaultVal.code == "ALL";
      let dependency = crit.dependency || [];
      let noValuesSelected = !appliedFilters[crit.code];
      availableCriterias.push(crit.code);

      //No dependency, then enable
      if (dependency.length <= 0) {
        filterState[crit.code] = true;
      }

      //If default selection is all and currently no values selected, set to all
      if (isAll && noValuesSelected) {
        appliedFilterAll[crit.code] = true;
      }
    }

    //Loop through values to enable or disable
    for (let x = 0; x < criteria.length; x++) {
      // Get Regions from User logged in
      let crit = criteria[x];
      let dependency = crit.dependency || [];
      for (var i = 0; i < dependency.length; i++) {
        let dep = dependency[i];
        let noValuesSelected = !appliedFilters[dep];

        if (
          availableCriterias.includes(dep) &&
          appliedFilterAll[dep] != true &&
          noValuesSelected
        ) {
          filterState[crit.code] = false;
          break;
        } else {
          filterState[crit.code] = true;
        }
      }
    }

    this.setState({
      appliedFilterAll: appliedFilterAll,
      filterState: filterState,
    });
  }

  async getSearchCriterias() {
    let criteria =
      (await UserAccessBloc.getUserSearchCriteriaForModule(
        this.props.userGlobal.roles[0].mapCode,
        this.props.userGlobal.roles[0].entity,
        this.state.module,
        this.props.userConfigGlobal
      )) || [];
    return criteria;
  }

  async initSearchCriteria() {
    //Get list of search criterias for this module
    let criteria = await this.getSearchCriterias();

    let appliedFilters = {};
    let appliedFilterAll = {};
    let filterSelections = {};
    let filterState = {};
    for (var i = 0; i < criteria.length; i++) {
      let crit = criteria[i];
      //console.log("crit.code",crit.code)
      appliedFilters[crit.code] = null;
      appliedFilterAll[crit.code] = false;
      filterSelections[crit.code] = [];
      filterState[crit.code] = false; //Disabling the filter
    }

    if (
      this.props.dialogFilterGlobal &&
      this.props.dialogFilterGlobal.filterApplied
    ) {
      appliedFilters = JSON.parse(this.props.dialogFilterGlobal.filterApplied);
      appliedFilterAll = JSON.parse(
        this.props.dialogFilterGlobal.filterAppliedAll
      );
      let currentPage = this.props.dialogFilterGlobal.currentPage;
    }
    this.setState(
      {
        ...this.state,
        searchCriteria: criteria,
        filterSelections: filterSelections,
        appliedFilterAll: appliedFilterAll,
        appliedFilters: appliedFilters,
        filterState: filterState,
      },
      this.loadSearchCriteria
    );
  }

  async fetchStatuses() {
    if (!this.canLoadList()) return;

    this.setState({
      loadingStatuses: true,
    });

    let statuses = await ListingBloc.getListingStatuses(
      this.props.userGlobal.roles[0].entity,
      this.state.module,
      this.state.countId,
      this.state.dateFilter || "",
      this.state.fromDate,
      this.state.toDate,
      this.state.searchBy,
      this.state.keyword,
      this.state.appliedFilters,
      this.state.appliedFilterAll,
      this.props.userConfigGlobal,
      this.state.searchType,
      this.state.section
    );

    console.log("Get Status", statuses);

    let statusFilter = this.state.statusFilter || [];

    statuses.forEach((item) => {
      let record = {};
      record = {
        title: item.title,
        status: this.getCurrentToggleFilter(item.title),
      };

      statusFilter.push(record);
    });

    // // Filter status only unique
    // const uniqueTitle = [];

    // const uniqueStatusFilter = statusFilter.filter((element) => {
    //   const isDuplicate = uniqueTitle.includes(element.title);

    //   if (!isDuplicate) {
    //     uniqueTitle.push(element.title);

    //     return true;
    //   }

    //   return false;
    // });

    // // Check only one result that has a data
    // let isHasOnlyOneCountDataCount = 0;
    // statuses.forEach((item) => {
    //   if (item.count != 0) {
    //     isHasOnlyOneCountDataCount++;
    //   }
    // });

    // // Change into true if only has 1 false status
    // let isFalseCount = 0;
    // uniqueStatusFilter.forEach((item) => {
    //   if (item.status == false) {
    //     isFalseCount++;
    //   }
    // });

    // console.log(
    //   "IsfalseCount",
    //   isFalseCount,
    //   "isHasOnlyOneCountData",
    //   isHasOnlyOneCountDataCount
    // );

    // if (
    //   (isFalseCount == 1 || isFalseCount == 2) &&
    //   isHasOnlyOneCountDataCount == 1
    // ) {
    //   uniqueStatusFilter.forEach((item) => {
    //     item.status = true;
    //   });
    // }

    // console.log("STATUS UPDATE", statusFilter);
    this.setState({
      loadingStatuses: false,
      proposalStatuses: statuses,
      statusFilter: statusFilter,
    });
  }

  async handleFilterDate(dateValue) {
    let filter = dateValue;
    if (filter.length == 0) {
      filter = "all";
    }
    if (dateValue != "range") {
      if (this.state.section == "overall") {
        this.props.setOverallListing({
          overallFromDate: null,
          overallToDate: null,
          overallDateValue: filter,
        });
      } else {
        this.props.setUpcomingListing({
          upcomingFromDate: null,
          upcomingToDate: null,
          upcomingDateValue: filter,
        });
      }
    }
    this.setState(
      {
        fromDate: null,
        toDate: null,
        dateFilter: dateValue,
      },
      filter != "range" ? this.fetchListing : null
    );

    // this.setState(
    //   {
    //     dateFilter: dateValue,
    //   },
    //   dateValue != "range" ? this.fetchListing : dateValue,

    //   this.props.setOverallListing({
    //     ...this.state.overallListing,
    //     overallFromDate: null,
    //     overallToDate: null,
    //     overallDateValue: dateValue,
    //   }),
    //   this.props.setUpcomingListing({
    //     ...this.state.upcomingListing,
    //     upcomingFromDate: null,
    //     upcomingToDate: null,
    //     upcomingDateValue: dateValue,
    //   })
    // );
  }

  async handleDateRange(dateRange) {
    this.props.setOverallListing({
      overallFromDate: dateRange[0],
      overallToDate: dateRange[1],
      overallDateValue: this.state.dateFilter,
    });
    this.props.setUpcomingListing({
      upcomingFromDate: dateRange[0],
      upcomingToDate: dateRange[1],
      upcomingDateValue: this.state.dateFilter,
    });
    this.setState(
      {
        fromDate: dateRange[0],
        toDate: dateRange[1],
      },
      this.fetchListing
      // this.props.setOverallListing({
      //   overallFromDate: dateRange[0],
      //   overallToDate: dateRange[1],
      //   overallDateValue: this.state.dateFilter,
      // }),
      // this.props.setUpcomingListing({
      //   upcomingFromDate: dateRange[0],
      //   upcomingToDate: dateRange[1],
      //   upcomingDateValue: this.state.dateFilter1,
      // })
    );
  }

  async handleChangePage(selectedPage) {
    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.fetchListing(false);
        this.props.setFilterDialog({
          ...this.props.dialogFilterGlobal,
          currentPage: selectedPage,
        });
      }
    );
  }

  async handleFilterSearch(filterSearchValue) {
    if (this.state.section == "overall") {
      this.props.setOverallListing({
        statusFilter: [],
        searchType: "bySearch",
        searchBy: filterSearchValue,
        keyword: "",
      });
    } else {
      this.props.setUpcomingListing({
        statusFilter: [],
        searchType: "bySearch",
        searchBy: filterSearchValue,
        keyword: "",
      });
    }
    this.setState(
      {
        ...this.state,
        searchBy: filterSearchValue,
        isValidBySearch: true,
        keyword: "",
        statusFilter: [],
        proposalData: {
          dataCollumn: [],
        },
      },
      this.init
    );
  }

  handleChangeKeyword(keyword) {
    this.setState({
      ...this.state,
      keyword: keyword,
    });
    let searchBy = this.state.searchBy;
    let validationType = Config.searchCriteriaValidationType[searchBy];
    // console.log("Validation Type", validationType);
    let isValid = ListingBloc.validateKeyword(keyword, validationType);
    // console.log("Is Valid Keyword?", isValid);
    if (isValid) {
      this.setState({
        isValidBySearch: true,
      });
    } else {
      this.setState({
        isValidBySearch: false,
      });
    }
  }

  getSelectedStatuses() {
    let statusFilter = [];
    let status = [];
    //console.log("getting status", this.state.statusFilter);
    status = this.state.statusFilter.filter((item) => item.status);
    status.forEach((item) => {
      statusFilter.push(item.title);
    });
    //console.log("Statooooo", status);
    //console.log("Selected Statttt", statusFilter);

    if (statusFilter.length == 0) {
      statusFilter = null;
    }
    return statusFilter;
  }

  async fetchListing(loadStatus) {
    let canLoad = await this.canLoadList();
    if (!canLoad) return;

    this.setState({
      loading: true,
    });

    if (loadStatus != false) {
      await this.fetchStatuses();
    }

    let statusFilter = await this.getSelectedStatuses();

    let listingData;
    listingData = await ListingBloc.getListingDataTable(
      this.state.idModuleListing,
      this.state.module,
      this.props.userGlobal.roles[0].entity,
      this.state.dateFilter,
      this.state.fromDate,
      this.state.toDate,
      this.state.currentPage || 1,
      this.state.searchBy,
      this.state.keyword,
      this.state.appliedFilters,
      this.state.appliedFilterAll,
      statusFilter,
      this.props.userConfigGlobal,
      this.state.searchType,
      this.state.section
    );

    this.setState({
      loading: false,
      proposalData: listingData,
      // statusFilter: JSON.stringify(statusFilter),
    });
  }

  async handleOnClickSearch() {
    this.setState({currentPage: 1, statusFilter: []})
    if (this.state.keyword.length >= 3) {
      if (this.state.section == "overall") {
        this.props.setOverallListing({
          searchType: "bySearch",
          searchBy: this.state.searchBy,
          keyword: this.state.keyword,
          currentPage: 1,
        });
      } else {
        this.props.setUpcomingListing({
          searchType: "bySearch",
          searchBy: this.state.searchBy,
          keyword: this.state.keyword,
          currentPage: 1,
        });
      }
      this.fetchListing();
    }
  }

  async handleDetailRedirection(recMap) {
    let encodedRecMap = encodeURIComponent(recMap);
    let dataListing = this.state.proposalData;
    let proposalStatuses = this.state.proposalStatuses;
    let statusFilter = this.state.statusFilter;

    let newLocation = {
      pathname: `/${this.state.client}/${this.state.route.entity}/${this.state.detailRoute}/${encodedRecMap}`,
      dataListing: dataListing,
      proposalStatuses: proposalStatuses,
      statusFilter: statusFilter,
      entity: this.state.route.entity,
      module: this.props.module,
      detailRoute: this.props.detailRoute,
    };

    return this.props.history.replace(newLocation);
  }

  handleChangeModule(part) {
    let newRoute = GeneralBloc.getSwitchUpcomingOrDashboard(
      this.state.detailRoute,
      part
    );

    window.location.href = `/${this.state.client}/${this.state.route.entity}/${newRoute}`;
  }

  async handleSelectedSearchType(searchType) {
    let currentSearchType = this.state.searchType;
    let searchTypeFilter = searchType;

    // if(currentSearchType != searchTypeFilter){
    //   await this.init();
    // }
    if (this.state.section == "overall") {
      this.props.setOverallListing({
        ...this.props.overallListingGlobal,
        statusFilter: [],
        searchType: searchTypeFilter,
        keyword: "",
        overallDateValue: null,
      });
      this.props.setUpcomingListing({
        searchType: searchTypeFilter,
        keyword: "",
      });
    } else {
      this.props.setUpcomingListing({
        ...this.props.upcomingListingGlobal,
        statusFilter: [],
        searchType: searchTypeFilter,
        keyword: "",
        upcomingDateValue: null,
      });
      this.props.setOverallListing({
        searchType: searchTypeFilter,
        keyword: "",
      });
    }
    this.props.setDateFilter({});
    this.props.setFilterDialog({});

    // Remove state on router
    this.props.location.dataListing = [];
    this.props.location.proposalStatuses = [];
    this.props.location.statusFilter = [];

    this.setState(
      {
        searchType: searchTypeFilter,
        isValidBySearch: true,
        keyword: "",
        branches: [],
        agents: [],
        fromDate: "",
        toDate: "",
        selections: [],
        currentPage: 1,
        proposalData: {
          dataCollumn: [],
        },
        searchBy: "",
        statusFilter: [],
        appliedFilters: [],
        appliedFilterAll: [],
        dashboardListing: {},
        overallListing: {
          ...this.state.dashboardListing,
          overallDateValue: null,
        },
        upcomingListing: {
          ...this.state.dashboardListing,
          upcomingDateValue: null,
        },
      },
      () => {
        if (currentSearchType != searchTypeFilter) {
          this.init();
        }
        // this.init();
      }
    );
  }

  async toggleDialog(code) {
    this.setState({
      loading: true,
    });

    let filterSelections = this.state.filterSelections;
    let selections = await ListingBloc.getListingFilter(
      this.state.module,
      this.state.appliedFilters,
      code,
      this.props.userConfigGlobal
    );
    let appliedFilters = this.state.appliedFilters[code];
    if (appliedFilters) {
      for (var i = 0; i < selections.length; i++) {
        if (appliedFilters.includes(selections[i].code)) {
          selections[i].status = true;
        } else {
          selections[i].status = false;
        }
      }
    }

    /* console.log(
      "Showing dialog filtered selection for code " +
        code +
        ", list = " +
        JSON.stringify(appliedFilters)
    ); */

    filterSelections[code] = selections;
    this.setState({
      loading: false,
      filterSelections: filterSelections,
    });
    this.toggleFilterDialog(code, true);
  }

  getFilterDialogToggleStatus(code) {
    let currentFilterMode = this.state.filterDialog || {};
    return currentFilterMode[`filter_${code}`] || false;
  }

  toggleFilterDialog(code, open) {
    let currentFilterMode = this.state.filterDialog || {};
    currentFilterMode[`filter_${code}`] = open;
    this.setState({
      filterDialog: currentFilterMode,
    });
  }

  async resetCriteriaOnSelect(code) {
    let appliedFilters = this.state.appliedFilters;
    let criteria = await this.getSearchCriterias();
    for (var i = 0; i < criteria.length; i++) {
      let crit = criteria[i];
      let dependencies = crit.dependency || [];
      if (dependencies.includes(code)) {
        appliedFilters[crit.code] = null;
      }
    }

    this.setState({
      appliedFilters: appliedFilters,
    });
  }

  async onFilterDialogConfirm(list, code) {
    let filteredList = list.filter((item) => item.status);
    let result = [];
    filteredList.forEach((item) => {
      result.push(item.code);
    });

    //Check if user has selected all
    let isSelectAll = list.length == result.length;

    // console.log(
    //   "Updated filtered selection for code " +
    //     code +
    //     ", list = " +
    //     JSON.stringify(result)
    // );

    let appliedFilters = this.state.appliedFilters;
    let appliedFilterAll = this.state.appliedFilterAll;
    appliedFilters[code] = result;
    appliedFilterAll[code] = isSelectAll;

    this.props.setFilterDialog({
      filterApplied: JSON.stringify(appliedFilters),
      filterAppliedAll: JSON.stringify(appliedFilterAll),
      currentPage: this.state.currentPage,
    });

    this.toggleFilterDialog(code, false);
    this.setState(
      {
        appliedFilters: appliedFilters,
        appliedFilterAll: appliedFilterAll,
      },
      () => {
        this.refreshFilter(code);
      }
    );
  }

  async refreshFilter(code) {
    await this.resetCriteriaOnSelect(code);
    await this.loadSearchCriteria();
    this.fetchListing();
  }

  getCurrentToggleFilter(nameType) {
    //Save into listing state (overall / upcoming)

    let statusFilter = this.state.statusFilter;

    for (let i in statusFilter) {
      //console.log(statusFilter[i].title);
      if (statusFilter[i].title == nameType) {
        return statusFilter[i].status;
      }
    }
    return true;
  }

  shouldRefreshStatus() {
    let hasStatusSelected = false;
    let statusFilter = this.state.statusFilter;
    for (let i in statusFilter) {
      hasStatusSelected = hasStatusSelected || statusFilter[i].status;
    }
    return hasStatusSelected;
  }

  stillOneStatusLeftSameName(statusFilter, nameType) {
    let status = [];

    status = statusFilter.filter((item) => item.status == true);
    // Filter status only unique

    return status.length == 1 && status[0].title == nameType ? true : false;
  }

  handleToggleFilter(nameType, toggle) {
    let statusFilter = this.state.statusFilter;
    // let statusCount = this.stateStatuses();

    // let hasDataCount = 0;
    // for (let i in statusFilter) {
    //   if (statusCount[i]?.count > 0) {
    //     hasDataCount = hasDataCount + 1;
    //   }
    // }

    if (
      !this.stillOneStatusLeftSameName(
        UtilBloc.getUniqueListBy(statusFilter, "title"),
        nameType
      )
    ) {
      for (let i in statusFilter) {
        if (statusFilter[i].title == nameType) {
          statusFilter[i].status = !toggle;
        }
      }
      // if (hasDataCount > 1) {
      if (this.state.section == "overall") {
        this.props.setOverallListing({
          ...this.state.overallListing,
          statusFilter: JSON.stringify(statusFilter),
          overallDateValue:
            this.state.dateFilter ||
            this.props.overallListingGlobal?.overallDateValue,
          overallFromDate: this.state.fromDate,
          overallToDate: this.state.toDate,
          keyword: this.state.keyword,
        });
      } else {
        this.props.setUpcomingListing({
          ...this.state.upcomingListing,
          statusFilter: JSON.stringify(statusFilter),
          upcomingDateValue:
            this.state.dateFilter ||
            !UtilBloc.isNullOrEmpty(this.state.upcomingListing)
              ? !UtilBloc.isNullOrEmpty(
                  this.state.upcomingListing.upcomingDateValue
                )
                ? this.state.upcomingListing.upcomingDateValue
                : null
              : null,
          upcomingFromDate: this.state.fromDate,
          upcomingToDate: this.state.toDate,
          keyword: this.state.keyword,
        });
      }

      //If no status has been selected, then done refresh
      if (!this.shouldRefreshStatus()) {
        return false;
      }

      this.setState(
        {
          statusFilter: statusFilter,
          currentPage: 1,
        },
        () => {
          this.fetchListing(false);
        }
      );
    }

    return true;
    // }
  }

  renderListingStatuses() {
    if (this.state.loadingStatuses) {
      return [
        { title: "_______ _________", count: 0 },
        { title: "_____ ___", count: 0 },
      ].map((item) => {
        return (
          <App_claim_status_mini_skeleton
            data={item}
            toggleFilter={this.handleToggleFilter}
          />
        );
      });
    } else if (
      this.state.proposalStatuses &&
      this.state.proposalStatuses.length > 0
    ) {
      return this.state.proposalStatuses.map((item) => {
        return (
          <App_claim_status_mini
            data={item}
            status={this.getCurrentToggleFilter(item.title)}
            toggleFilter={this.handleToggleFilter}
          />
        );
      });
    }
  }

  getCriteriaLabel(item) {
    let appliedFilters = this.state.appliedFilters;
    let appliedFilterAll = this.state.appliedFilterAll;

    if (appliedFilterAll[item.code] == true) {
      return "(All)";
    }
    if (appliedFilters[item.code]) {
      return `(${appliedFilters[item.code].length})`;
    }
    return "(" + ((item.default || {}).name || `Select ${item.name}`) + ")";
  }

  renderSearchCriteria() {
    return this.state.searchCriteria.map((item) => {
      return (
        <div>
          <AppDialogFilter
            disabled={this.state.filterState[item.code] == false}
            onClick={() => this.toggleDialog(item.code)}
            title={item.name}
            default={this.getCriteriaLabel(item)}
          />
          <App_searching_dialog
            isOpen={this.getFilterDialogToggleStatus(item.code)}
            onCloseDialog={() => {
              this.toggleFilterDialog(item.code, false);
            }}
            getSelectedOptions={(list) => {
              this.onFilterDialogConfirm(list, item.code);
            }}
            selections={this.state.filterSelections[item.code] || []}
          />
        </div>
      );
    });
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  async exportFile() {
    this.setState({
      loading: true,
    });

    let fileData;
    let statusFilter = this.getSelectedStatuses();

    fileData = await ListingBloc.exportFileByModule(
      this.state.idModuleListing,
      this.state.module,
      this.props.userGlobal.roles[0].entity,
      this.state.dateFilter,
      this.state.fromDate,
      this.state.toDate,
      this.state.searchBy,
      this.state.keyword,
      this.state.appliedFilters,
      this.state.appliedFilterAll,
      statusFilter,
      this.props.userConfigGlobal
    );
    let filename = `${this.state.module}.${"csv"}`;
    this.setState({
      loading: false,
    });
    UtilBloc.downloadBinary(fileData.data, filename);
  }

  render() {
    return (
      <>
        <Base_screen
          module={this.props.module}
          listener={() => {
            this.init();
          }}
        >
          {this.state.loaded ? (
            <div>
              {this.state.loading && this.loadingContent()}
              <App_header
                data={this.state.userData}
                route={this.state.route.entity}
              />
              <App_sidebar route={this.state.route.entity}>
                <App_header_title_filter
                  title={this.props.listingTitle}
                  onSelectedSearchType={this.handleSelectedSearchType}
                  exportFile={this.exportFile}
                  defaultValueSearchType={this.state.searchType}
                  valueSearchType={this.state.searchType}
                  defaultValue={this.state.part}
                  handleChangeModule={this.handleChangeModule}
                  show={this.state.upcomingEventDropDown}
                  disabled={
                    // this.state.proposalData.totalData > 0 ? false : true
                    !(
                      this.state.proposalData.totalData > 0 &&
                      this.stateExportIndicator()
                    )
                  }
                />
                <div className=" align-items-center mt-5 d-xxl-flex d-xl-block">
                  {this.state.searchType === "byGroup" ? (
                    <div className="dropdown_list_container">
                      <div className="d-flex mb-md-2">
                        <App_dropdown_filter
                          onSelectedDateFilter={this.handleFilterDate}
                          defaultValue={
                            this.state.overallListing?.overallDateValue ||
                            this.state.upcomingListing?.upcomingDateValue
                          }
                          value={this.state.dateFilter}
                          // value={this.state.dateFilter}
                          dataDateByCaption={this.state.dateRangeCaptions}
                          dataDateByValue={this.state.dateRangeCodes}
                          placeholder={
                            this.state.dateFilterPlaceholder || "Select Date"
                          }
                        />
                        {this.props.dateRange == "overall" ? (
                          <App_daterange_filter
                            isNotRange={this.state.dateFilter != "range"}
                            defaultValueFromDate={this.state.fromDate}
                            defaultValueToDate={this.state.toDate}
                            value={[this.state.fromDate, this.state.toDate]}
                            disabled={
                              this.state.dateFilter == "range" ? false : true
                            }
                            onSelectedDateRange={this.handleDateRange}
                          />
                        ) : (
                          <App_daterange_filter_upcoming
                            isNotRange={this.state.dateFilter != "range"}
                            value={[this.state.fromDate, this.state.toDate]}
                            defaultValueFromDate={this.state.fromDate}
                            defaultValueToDate={this.state.toDate}
                            disabled={
                              this.state.dateFilter === "range" ? false : true
                            }
                            onSelectedDateRange={this.handleDateRange}
                          />
                        )}
                      </div>
                      <div className="search_criteria_container mb-md-2">
                        {this.renderSearchCriteria()}
                      </div>
                    </div>
                  ) : (
                    <div className=" w-100">
                      <div className="d-flex ">
                        <App_dropdown_filter2
                          title={"Search By"}
                          onSelectedSearchFilter={this.handleFilterSearch}
                          dataSearchByCaption={this.getSearchCaptionList()}
                          dataSearchByValue={this.getsearchValueList()}
                          defaultValue={this.getsearchValueList()[0]}
                          value={this.state.searchBy}
                        />
                        <App_search_filter
                          title={"Search"}
                          value={this.state.keyword}
                          defaultValue={this.state.keyword}
                          onChangeKeyword={this.handleChangeKeyword}
                          onClickSearch={this.handleOnClickSearch}
                          isValid={!this.state.isValidBySearch}
                        />
                      </div>
                      <div className="mt-2 ml-3 login_error">
                        <AppFieldError
                          text={
                            this.state.isValidBySearch ? null : "Wrong Format"
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex proposal__status">
                  {this.renderListingStatuses()}
                </div>
                {this.state.proposalData.totalData > 0 ? (
                  <div className="mb-4 proposal__result">
                    Showing{" "}
                    {this.state.overallListing?.listingData?.proposalData
                      ?.totalData || this.state.proposalData.totalData}{" "}
                    results.
                  </div>
                ) : null}
                <div className="">
                  {this.state.overallListing?.listingData?.proposalData
                    ?.dataCollumn?.length ||
                  this.state.proposalData.dataCollumn?.length ? (
                    //passing state loading into this
                    <App_table
                      data={
                        this.state.overallListing?.listingData?.dataProposal ||
                        this.state.proposalData.dataProposal
                      }
                      collumn={
                        this.state.overallListing?.listingData?.dataProposal
                          ?.dataCollumn || this.state.proposalData.dataCollumn
                      }
                      totalData={
                        this.state.overallListing?.listingData?.dataProposal
                          ?.totalData || this.state.proposalData.totalData
                      }
                      onSelectedPage={this.handleChangePage}
                      currentPage={this.state.currentPage}
                      handleOnClick={this.handleDetailRedirection}
                      isLoading={this.state.loading}
                    />
                  ) : (
                    <AppEmptyData />
                  )}
                </div>
              </App_sidebar>
            </div>
          ) : (
            <div>{this.state.loading && this.loadingContent()}</div>
          )}
        </Base_screen>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userGlobal: state.user.value,
    globalFilterBy: state.filter.value,
    globalSearchBy: state.searchBy.value,
    userConfigGlobal: state.userConfig.value,
    userDataGlobal: state.userData.value,
    dateFilterGlobal: state.dateFilter.value,
    dialogFilterGlobal: state.filterDialog.value,
    overallListingGlobal: state.overallListing.value,
    upcomingListingGlobal: state.upcomingListing.value,
  };
};

const mapDispatchToProps = {
  setFilterBy,
  setFilterBy1,
  setUserData,
  setUserConfig,
  setFilterDialog,
  setDateFilter,
  setOverallListing,
  setUpcomingListing,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListingScreen)
);
