/*
Last Updated: 7th Oct 2022
Author: Neoh Wei
*/
class JWTTokenService {
  static decodeJWT(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  static secondsSinceEpoch(date) {
    return Math.floor(date / 1000);
  }

  static currentSecondsSinceEpoch() {
    return Math.floor(new Date() / 1000);
  }

  static isValid(token) {
    return !this.isExpired(token);
  }

  static isExpired(token) {
    let decodedToken = this.decodeJWT(token);
    let exp = decodedToken.exp;
    let cur = this.currentSecondsSinceEpoch();

    //console.log(exp, cur);
    return exp < cur;
  }

  //For testing purpose
  static test() {
    let token =
      "eyJhbGciOiJSUzUxMiJ9.eyJpYXQiOjE2NjUxMTgyMzAsImV4cCI6MTY2NTEyMDAzMCwidG9rZW4iOiI5ZDU2YTYzNy1kOWI1LTQwYmEtYTkzOC1lMmQyZmI1MjcyMjQiLCJlbWFpbCI6ImluZ3Rvbzg4M0BnbWFpbC5jb20iLCJ1c2VybmFtZSI6ImhvYjAxIiwiZGlzcGxheU5hbWUiOiJIZWFkIG9mIEJhbmNhIDAxIHVzZXIiLCJpY05vIjoiIiwiYmFuY2FJZE5vIjoiIiwicGhvbmVObyI6IiIsInBob25lTm8yIjoiIiwicm9sZXMiOlt7ImlkIjoiRldEX0hFQURfT0ZfQkFOQ0EiLCJtYXBDb2RlIjoiSEVBRF9PRl9CQU5DQSIsImVudGl0eSI6IkZXRCIsImVudGl0eU5hbWUiOiIiLCJyZWdpb24iOiIiLCJyZWdpb25OYW1lIjoiIiwiYnJhbmNoIjoiIiwiYnJhbmNoTmFtZSI6IiJ9XX0.FqRnYBVdqxncygc20TxiG3Ma4JV9O0rSGIrvYXwgt4sMIg0G_nmvfMUD2rf9YX9GbYtlLlbtmNOD02JcEMSWy4zXx7pW-JhJjxbuAim1QQeNQd0jmEjDHkX4Sg8d6RAUFFoNs-MQ2TMRg6PHJk069i7ZJvo4fhOY5yZnSOy5OcTq9XzeAog_vd8MeX3cMLhCnJ3iV3I-qiNZ3xhxme1NKw5Ql1ADfHu-N6HU7jegdNW593YPxgzv7OmVjWoCTX51bz7w0VcowXpC44n3r5nacfBpsE7rmNxNhUNXRSQZAwoTOajAq4W4E25sOipcg12lz5dV9IGQl8X5umLRdk_FJg";
    let decoded = this.decodeJWT(token);
    //console.log("Result = " + JSON.stringify(decoded));
  }
}

export default JWTTokenService;
