import React from "react";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_group_button_dialog.css";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";

class AppGroupButtonDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};  
  }

  onPrint() { }

  downloadFile() {
    let link = this.props.pdf;
    let a = document.createElement("a");
    a.href = link;

    a.target = "_self";
    a.download = this.props.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  }

  downloadFileFromAPI() {
    fetch(this.props.pdf, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          this.props.name + ".pdf",
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  }

  downloadBinary() {
    //console.log("pdsBlobData", this.props.pdsBlobData)
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([this.props.pdsBlobData], { type: 'application/pdf' }));
    a.download = this.props.name + ".pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  printFile() {
    let link = this.props.pdf;
    window.open(link);
  }

  render() {
    return (
      <div className="print_download_container">
        <a className="btn button_style_dialog" onClick={() => this.printFile()}>
          <div className="text_icon_container">
            <div>Print</div>
            <img
              className="print_icon"
              src={`${GeneralBloc.getAssetsContextPath()}/assets/images/red_print_icon.png`}
            />
          </div>
        </a>
        <a
          className="btn button_style_dialog"
          onClick={() => {
            if (this.props.type == "blob") {
              this.downloadBinary();
            } else {              
              this.downloadFileFromAPI();
            }
          }}
        >
          <div className="text_icon_container">
            <div>Download</div>
            <img
              className="print_icon"
              src={`${GeneralBloc.getAssetsContextPath()}/assets/images/red_download_icon.png`}
            />
          </div>
        </a>
      </div>
    );
  }
}

export default withRouter(AppGroupButtonDialog);
