import React, { Component } from "react";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_empty_data.css";

class AppEmptyData extends Component {
  render() {
    return (
      <div
        className="w-full d-flex justify-content-center"
        style={{ marginTop: 63 }}
      >
        <div>
          <img
            src={
              GeneralBloc.getAssetsContextPath() +
              `/assets/images/Illustration/Data not Found.svg`
            }
            alt="Data Not Found"
          />
          <div
            style={{
              color: "black",
              fontWeight: 600,
              textAlign: "center",
              fontSize: 14,
              paddingTop: 12,
            }}
          >
            {this.props.description1 ? this.props.description1:"Enter keywords to begin searching"}
          </div>
          <div
            style={{
              color: "#9599B6",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {this.props.description2 ? this.props.description2:"You may change the filter to aid your search."}
          </div>
        </div>
      </div>
    );
  }
}

export default AppEmptyData;
