import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
  errmsg: null,
  result: {
    userType: 0,
    accessToken: "",
    tokenType: "",
    expiresIn: 0,
    refreshToken: "",
    email: "",
    username: "",
    displayName: "",
    designationTitle: "",
    reportingMgrName: "",
    icNo: "",
    bancaIdNo: "",
    phoneNo: "",
    phoneNo2: "",
    roles: [
      {
        id: "",
        mapCode: "",
        entity: "",
        entityName: "",
        region: "",
        regionName: "",
        branch: "",
        branchName: "",
      },
    ],
    needChangePasswordAfterLogin: false,
    availBanks: [
      {
        id: "",
        name: "",
      },
      {
        id: "",
        name: "",
      },
    ],
  },
  errcode: null,
});

export const authReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.value = action.payload;
    },
    logOut: () => initialState,
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = authReducer;
export const { setAuth, logOut } = actions;

export default reducer;
