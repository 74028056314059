import React, { Component } from 'react';
import GeneralBloc from '../../../bloc/general_bloc';
import './app_filter_button.css'


class FilterButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterOn: false,
    };
  }

  propTitle() {
    return this.props.label
  }

  propCount() {
    return this.props.count
  }

  toggleFilter = () => {
    this.setState((prevState) => ({
      isFilterOn: !prevState.isFilterOn,
    }), () => {
      this.props.onFilterChange(this.state.isFilterOn);
    });
  };

  render() {
    return (
      <button onClick={this.toggleFilter} className={`filter-button__container claim-status__title ${this.props.selected ? "active" : ""}`}>
        <span>{
          this.props.selected ? <img
            style={{marginTop:"-4px"}}
            src={
              GeneralBloc.getAssetsContextPath() +
              `/assets/icons/checked.svg`
            }
            alt="CheckedIcon"
            height={22}
            width={22}            
          /> : <img
            style={{marginTop:"-4px"}}
            src={
              GeneralBloc.getAssetsContextPath() +
              `/assets/icons/unchecked.svg`
            }
            alt="UnCheckedIcon"
            height={22}
            width={22}              
          />
        } </span>
        <span style={{ fontSize: "16px", marginRight: "8px" }}>{this.propCount()}</span><span style={{ fontSize: "14px", fontWeight: 400 }}>{this.propTitle()}</span>
      </button>
    );
  }
}

export default FilterButton;
