import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_claim_status.css";

class AppClaimStatusMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.status || true,
    };
  }

  handleToggleFilter(toggleType, toggleStatus) {
    let statusChanged = this.props.toggleFilter(toggleType, toggleStatus);

    if (statusChanged) {
      this.setState({
        isActive: !this.state.isActive,
      });
    }
  }

  getStatusName() {
    return this.props.data.translation || this.props.data.title;
  }

  render() {
    // Make state selected or not selected and change icons
    console.log("Background = " + this.props.data.background);
    return (
      <>
        <div
          className={`claim-status-mini ${this.props.data.type}`}
          style={{
            background: this.props.status
              ? this.props.data.background
              : "transparent",
            cursor: "pointer",
          }}
          onClick={() =>
            this.handleToggleFilter(this.props.data.title, this.props.status)
          }
        >
          <div className="claim-status-mini__image">
            <img
              src={GeneralBloc.getImagePath(
                this.props.status
                  ? this.props.data.icon
                  : this.props.data.inactiveIcon
              )}
              alt={this.getStatusName()}
              height={32}
              width={32}
              className=""
            />
          </div>
          <div className="claim-status-mini__data-count">
            {this.props.data.count}
          </div>
          <div className="claim-status__title">{this.getStatusName()}</div>
        </div>
      </>
    );
  }
}

export default withRouter(AppClaimStatusMini);
