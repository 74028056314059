class BaseResponseJson {
    constructor(success, message, code, data, errorCode) {
        this.success = success;
        this.message = message;
        this.code = code;
        this.data = data;
        this.errorCode = errorCode;
    }
}

export default BaseResponseJson