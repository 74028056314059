import axios from "axios";
import { Component } from "react";
import GeneralBloc from "../bloc/general_bloc";
import BaseWebService from "./base_web_service";

export default class ListingWebServices extends Component {
  static async getSubmittedDocuments(docClass, recMap) {
    let headers = await BaseWebService.headers(docClass);
    let resp = await axios.get(
      GeneralBloc.encodeAndDecodeURL(
        BaseWebService.baseUrl() + `/submittedDocs/${docClass}/${recMap}`
      ),
      { headers: headers }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getListing(
    listId,
    token,
    entity,
    payload
  ) {
    let header=await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      payload,
      {
        headers: header,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
}
