import React from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import ListingBloc from "../../../bloc/listing_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import Config from "../../../Config";
import Listing_screen from "../proposal_screen.js/listing_screen";

class IssuedCaseScreenV2 extends React.Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      route: routeParams,
      title: "",
    };
  }
  async componentDidMount() {
    let result = await ListingBloc.getListingTitle("issuedCase");
    //console.log(result);
    this.setState({
      title: result,
    });
  }

  render() {
    return (
      <Listing_screen
        listId={"df-issued-case-list"} //To define which list this is loading for
        countId={"df-issued-case-count"} //To define which count to retrieve
        module={"issuedCase"} //To define which module this list is for
        detailRoute={Config.routes.generic.issuedCase} //Route to detail screen
        listingTitle={this.state.title} //Title of listing
        dateRange={"overall"}
      />
    );
  }
}

export default withRouter(IssuedCaseScreenV2);
