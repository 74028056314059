import { Col, Collapse, Row } from "antd";
import React from "react";
import AppContentFieldItem2 from "./app_content_field_item2";
import "./contribution_details_card.css";
import Config from "../../../Config";
import GeneralBloc from "../../../bloc/general_bloc";
import ProposalBloc from "../../../bloc/proposal_bloc";
import UtilBloc from "../../../bloc/util_bloc";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import TermsAndConditionDialog from "../terms_and_condition_dialog/terms_and_condition_dialog";

const { Panel } = Collapse;

class LetterOfAcceptance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenPDF: false,
      module: "casepedia",
      isLoading: false
    };
  }

  stateData() {
    return this.props.data;
  }

  stateItemId() { return this.stateData().itemId };
  stateModule() { return this.state.module };
  stateDocClass() { return this.stateData().docClass };
  stateIsLoading() { return this.state.isLoading };

  componentDidMount() {

  }

  async viewOrDownloadFile(id, fileType, view) {
    this.setState({
      isLoading: true
    })
    ProposalBloc.downloadFile(this.stateModule(), id).then((result)=>{
      if (result.success) {
        this.setState({
          isLoading: false
        })
        let typeFile = result.data.headers['content-type'].split("/")[1];
        if (view == "download") {
          let filename = `${id}.${typeFile}`
          UtilBloc.downloadBinary(result.data.data, filename)
        }
  
        if (view == "view") {
          if (typeFile == "pdf") {
            this.openViewDialog(typeFile, result.data.data);
          } else if (typeFile == "png") {
            let url = window.URL.createObjectURL(new Blob([result.data.data]))
            this.openViewDialog(typeFile, url);
          }
        }
      }
    }).catch(e=>{
      this.setState({
        isLoading: false
      })
    })
    
  }

  openViewDialog(type, content) {
    this.setState({
      isOpenPDF: true,
      type: type,
      pdfContent: content
    })
  }

  closeViewDialog() {
    this.setState({
      isOpenPDF: false,
    })
  }

  loadingContent() {
    return (
      <div>
        <AppLoadingDialog isLoading={this.stateIsLoading()} />
        < div className="row landing_section">
          <div className="col-md-12 text-center">
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div        
        bordered={false}
        expandIconPosition={"end"}
        style={{
          backgroundColor: "white",
          marginBottom: 16,
          marginRight: 16,
          paddingLeft:16,
          paddingRight:16,
          paddingTop:16
        }}
        defaultActiveKey={[1]}
      >
        <TermsAndConditionDialog
          showClose={true}
          isOpen={this.state.isOpenPDF}
          content={this.state.pdfContent || null}
          onDialogClose={(e) => this.closeViewDialog()}
          type={this.state.type}
        />
        {this.loadingContent()}
        <div
          key={1}
          className="contribution-detail-title"
        >

          <div className="d-flex justify-content-between align-items-center">
            <div className="contribution-detail-title">{"Letter of Acceptance"}</div>
            <div className="d-flex align-items-start">
              {
                <img
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    "/assets/images/new_eye_icon.png"
                  }
                  style={{ cursor: "pointer", width: "16px", height: "13px" }}
                  onClick={() => { this.viewOrDownloadFile(this.stateData()?.docs[0]?.id, "", "view") }}
                />
              }
              {
                <img
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    "/assets/images/Download.png"
                  }
                  className="img-fluid ml-2"
                  style={{ cursor: "pointer", marginTop: "-4px" }}
                  onClick={() => { this.viewOrDownloadFile(this.stateData()?.docs[0]?.id, "", "download") }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LetterOfAcceptance;
