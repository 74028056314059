import React, { Component } from "react";
import AppClaimStatusBean from "../data/bean/app_claim_status_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import IssuedCasesWebService from "../service/issued_cases_web_service";
import ProposalWebServices from "../service/proposal_web_services";
import AppTableStatus from "../ui/component/app_table_status/app_table_status";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";

class ProposalDetailBloc extends Component {
  static getDummyClaimStatuses() {
    return [
      new AppClaimStatusBean("InProgress.svg", "Pending", 30, "pending"),
      new AppClaimStatusBean("Received.svg", "Outstanding", 20, "outstanding"),
    ];
  }

  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getProposalDetail(entity, policyType, policyNumber, planCode) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response = await ProposalWebServices.getProposalDdetail(
      token,
      entity,
      policyType,
      policyNumber,
      planCode
    );

    if (response.success) {
      result = response.data;
    }
    return result;
  }
}

export default ProposalDetailBloc;
