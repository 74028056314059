/* eslint-disable react/jsx-pascal-case */
import { Col, Row, Space } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import HomeBloc from "../../../bloc/home_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import App_header from "../../component/app_header/app_header";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import "./base_empty_screen.css";
import GeneralBloc from "../../../bloc/general_bloc";
import { setFilterBy } from "../../../data/redux/reducers/filterReducer";
import { setUserData } from "../../../data/redux/reducers/userDataReducer";
import { setFilterBy1 } from "../../../data/redux/reducers/filterReducer1";
import { setSearchBy } from "../../../data/redux/reducers/searchByReducer";
import { setUserConfig } from "../../../data/redux/reducers/userConfigReducer";
import { setDTOverallFilter } from "../../../data/redux/reducers/dtOverallFilterReducer";
import { setDateFilter } from "../../../data/redux/reducers/dateFilterReducer";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import Base_screen from "../base/base_screen/base_screen";

class BaseEmptyScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);

    this.state = {
      client: Config.context,
      route: routeParams,
    };
  }

  componentDidMount() {}

  async init() {
     let userData = this.props.userDataGlobal.data;

    this.setState({
      userData: userData,
      loading: false,
    })

  }

  renderHeaderContents() {
    return (
      <>
        <div>
          {this.state.loading && this.loadingContent()}
          <div>
            You are not allowed to access this content
          </div>
        </div>
      </>
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    return (
      <>        
          {/* <Base_screen
            checkAccess={false}
            // loading={this.state.loading}
            listener={() => {
              this.init();
            }}
          > */}
            {
              this.props.userGlobal ? <div className="homescreen">
              <App_header
                data={this.state.userData || {}}
                route={this.state.route.entity}
              />
              <App_sidebar
                route={this.state.route.entity}
                onLinkClick={() => {
                  this.props.setDateFilter({
                    overallDateValue: null,
                  });
                }}
              >
                {this.renderHeaderContents()}
              </App_sidebar>
            </div> :
            <Redirect
            to={`/${this.state.client}/${Config.routes.generic.login}`}
          />
            }
            
           {/* </Base_screen> */}
        
      </>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("STATEALL",state)
  return {
    userGlobal: state.user.value,
    globalFilterBy: state.filter.value,
    globalSearchBy: state.searchBy.value,
    userConfigGlobal: state.userConfig.value,
    userDataGlobal: state.userData.value,
    dtOverallFilterGlobal: state.dtOverallFilter.value,
    dateFilterGlobal: state.dateFilter.value,
    dialogFilterGlobal: state.filterDialog.value,

    //globalFilterByUpcoming: state.filter1.value,
  };
};

const mapDispatchToProps = {
  setFilterBy,
  setSearchBy,
  setFilterBy1,
  setUserData,
  setUserConfig,
  setDTOverallFilter,
  setDateFilter,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseEmptyScreen)
);

// export default withRouter(homeScreen);
