import { Col, Row } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ClaimsBloc from "../../../bloc/claims_bloc";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import App_button2 from "../../component/app_button/app_button2";
import BankRepresentativeCard from "../../component/app_content_detail/bank_representative_card";
import CertificateDetailsCard from "../../component/app_content_detail/certificate_details_card";
import CertificateDetailsDateCard from "../../component/app_content_detail/certificate_details_date_card";
import CustomerDetailsCard from "../../component/app_content_detail/customer_details_card";
import App_claim_status_mini from "../../component/app_claim_status/app_claim_status_mini";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import Config from "../../../Config";
import ClaimsDetailBloc from "../../../bloc/claims_detail_bloc";
import ProposalDetailBloc from "../../../bloc/proposal_detail_bloc";
import AppTableStatus from "../../component/app_table_status/app_table_status";
import "./claims_detail_screen.css";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import AppSteps from "../../component/app_steps/app_steps";
import { connect } from "react-redux";
import Base_screen from "../base/base_screen/base_screen";
import AuthBloc from "../../../bloc/auth_bloc";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import ClaimDetailsCard from "../../component/app_content_detail/claim_details_card";
import StatusTrackingCard from "../../component/app_content_detail/status_tracking_card";

class ClaimsDetail extends Component {
  constructor(props) {
    const { host, hostname, href, origin, pathname, port, protocol, search } =
    window.location;
    let paths = pathname.split("/");
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      route: routeParams,
      itemId: routeParams.itemId,
      client: Config.context,
      userData: {},
      claimDetail: [],
      steps: [
        {
          title: "First",
          content: "First-content",
        },
        {
          title: "Second",
          content: "Second-content",
        },
        {
          title: "Last",
          content: "Last-content",
        },
      ],
      paths: paths,
    };
  }

  stateItemId() {
    return this.state.itemId;
  }

  stateModule() {
    return "claim";
  }

  stateDetailRoute() {
    return this.props.location.detailRoute || GeneralBloc.showCurrentModule(this.state.paths);
 }

  stateEntity() {
    return this.state.route.entity;
  }

  componentDidMount() {}

  goSubmittedDocumentDetails() {
    let encodedRecMap = encodeURIComponent(this.stateItemId());
    return this.props.history.push(
      `/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.document}/submitted/${encodedRecMap}/${this.stateDetailRoute()}`
    );
  }

  async fetchDetails() {
    this.setState({
      loading: true,
    });

    let userData = await ProposalDetailBloc.getUserData(
      this.props.userGlobal.displayName.length > 0
        ? this.props.userGlobal.displayName
        : "",
      this.props.userGlobal.designationTitle.length
        ? this.props.userGlobal.designationTitle
        : ""
    );
    let propData = await ClaimsBloc.getClaimDetail(
      this.props.userGlobal.roles[0].entity,
      this.stateItemId()
    );

    this.setState({
      loading: false,
      userData: userData,
      claimDetail: propData,
    });
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    return (
      <>
        <Base_screen
          listener={() => {
            this.fetchDetails();
          }}
        >
          <div>
            {this.state.loading && this.loadingContent()}
            <App_header
              data={this.state.userData}
              route={this.state.route.entity}
            />
            <App_sidebar route={this.state.route.entity}>
              <div
                className="d-flex"
                style={{ marginRight: "30px", marginLeft: "10px" }}
              >
                <App_header_title_simple
                  menu={"menu2"}
                  title={
                    this.state.claimDetail.customerDetails
                      ? this.state.claimDetail.customerDetails.participant.name
                          .value
                      : ""
                  }
                  description={
                    "Certificate Number : " +
                    (this.state.claimDetail.certDetails
                      ? this.state.claimDetail.certDetails.certNum.value
                      : "")
                  }
                />
                <App_button2
                  title={"View All Submitted Documents"}
                  onClickAction={() => this.goSubmittedDocumentDetails()}
                />
              </div>
              <div className="proposal">
                <div className="proposal__customer">
                  <Row>
                    <Col span={15}>
                      {this.state.claimDetail.customerDetails ? (
                        <CustomerDetailsCard
                          module={this.stateModule()}
                          data={this.state.claimDetail.customerDetails}
                          entity={this.stateEntity()}
                        />
                      ) : (
                        <div />
                      )}
                      {this.state.claimDetail.certDetails ? (
                        <CertificateDetailsCard
                          module={this.stateModule()}
                          data={this.state.claimDetail.certDetails}
                          entity={this.stateEntity()}
                        />
                      ) : (
                        <div />
                      )}
                      {this.state.claimDetail.claimDetails ? (
                        <ClaimDetailsCard
                          module={this.stateModule()}
                          data={this.state.claimDetail.claimDetails}
                          entity={this.stateEntity()}
                        />
                      ) : (
                        <div />
                      )}
                      {this.state.claimDetail.bankRepresentativeDetails ? (
                        <BankRepresentativeCard
                          module={this.stateModule()}
                          data={
                            this.state.claimDetail.bankRepresentativeDetails
                          }
                          entity={this.stateEntity()}
                        />
                      ) : (
                        <div />
                      )}
                    </Col>
                    <Col span={9}>
                      <Row>
                        <Col span={24}>
                          {this.state.claimDetail.certDateDetails ? (
                            <CertificateDetailsDateCard
                              module={this.stateModule()}
                              data={this.state.claimDetail.certDateDetails}
                              entity={this.stateEntity()}
                            />
                          ) : (
                            <div />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {this.state.claimDetail.statusTrackingDetailsList ? (
                            <AppSteps
                              title={
                                this.state.claimDetail.statusTrackingDetailsList
                                  .caption
                              }
                              direction="vertical"
                              current={
                                this.state.claimDetail.statusTrackingDetailsList
                                  .data.length
                              }
                              items={ClaimsBloc.getStatusTrackingDetailsList(
                                this.state.claimDetail.statusTrackingDetailsList
                                  .data
                              )}
                              entity={this.stateEntity()}
                            />
                          ) : (
                            <div />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </App_sidebar>
          </div>
        </Base_screen>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps)(ClaimsDetail));
