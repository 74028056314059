import React from "react";
import AppContentFieldItem from "./app_content_field_item";
import { Col, Collapse, Row } from "antd";
import "./customer_details_card.css";
import Config from "../../../Config";

const { Panel } = Collapse;

class StatusTrackingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      title: props.title,
    };
  }

  stateTitle() {
    return this.state.title;
  }

  stateData() {
    console.log("Beneficiary Data", this.state.data);
    return this.state.data;
  }

  stateModule() {
    return this.props.module || "";
  }

  stateEntity() {
    return this.props.entity;
  }

  stateFields() {
    return (
      Config[this.stateEntity()][this.stateModule()][
        this.stateNameOfComponent()
      ] || []
    );
  }

  stateNameOfComponent() {
    return "beneficiaryDetails";
  }

  componentDidMount() {}

  renderField(field) {
    if (field)
      return (
        <AppContentFieldItem
          title={field.caption || "Status Tracking"}
          value={
            field.value == "" || field.value == 0.0 || field.value == null
              ? "N/A"
              : field.value
          }
        />
      );

    return <div />;
  }

  renderRow(item1, item2, item3, item4) {
    return (
      <Row>
        <Col span={6}>{item1}</Col>
        <Col span={6}>{item2}</Col>
        <Col span={6}>{item3}</Col>
        <Col span={6}>{item4}</Col>
      </Row>
    );
  }

  renderFieldsFromObject() {
    return (
      <>
        {this.stateFields() &&
          this.stateFields().map((fields, index) => {
            return (
              <div>
                {this.renderRow(
                  this.renderField(this.stateData()[index][fields.item1]),
                  this.renderField(this.stateData()[index][fields.item2]),
                  this.renderField(this.stateData()[index][fields.item3]),
                  this.renderField(this.stateData()[index][fields.item4])
                )}
              </div>
            );
          })}
      </>
    );
  }

  render() {
    return (
      <Collapse
        className="customer-detail-card"
        bordered={false}
        expandIconPosition={"end"}
        style={{
          backgroundColor: "white",
          marginBottom: 16,
          marginRight: 16,
        }}
        defaultActiveKey={[1]}
      >
        <Panel
          key={1}
          header={this.stateTitle() || ""}
          className="customer-detail-title"
        >
          {this.renderFieldsFromObject()}
        </Panel>
      </Collapse>
    );
  }
}

export default StatusTrackingCard;
