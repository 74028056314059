import Config from "../../../Config";
import Listing_screen from "../proposal_screen.js/listing_screen";
import React from "react";
import { withRouter } from "react-router-dom";

class ClaimsScreenV2 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <Listing_screen
        listId={"df-claim-list"} //To define which list this is loading for
        countId={"df-claim-count"} //To define which count to retrieve
        module={"claim"} //To define which module this list is for
        detailRoute={Config.routes.generic.claim} //Route to detail screen
        listingTitle={"Claims"} //Title of listing
        dateRange={"overall"}
      />
    );
  }
}

export default withRouter(ClaimsScreenV2);
