import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import "./app_announcements_detail.css";
import moment from "moment";

class AppAnnouncementsDetail extends Component {
    render() {
        return (
            <>
                {
                    this.props.data ? <div className="d-row announcements-detail">
                        <img
                            src={this.props.data.image}
                            style={{
                                width: '100%',                                
                                objectFit: "object"
                            }}
                            alt=""
                            className="mb-3"
                        />
                        
                        {/* <div className="announcements-detail__timestamp mb-3">
                            {moment(this.props.data.publishedDtFrom).format('DD MMM YYYY')} to {moment(this.props.data.publishedDtTo).format('DD MMM YYYY')}
                        </div> */}

                        <div className="announcements-detail__title mb-3">
                            {this.props.data.title}
                        </div>
                        <div className="announcements-detail__content" dangerouslySetInnerHTML={{
                            __html: this.props.data.content
                        }}>
                        </div>
                    </div> : null
                }
            </>
        );
    }
}

export default withRouter(AppAnnouncementsDetail);
