import { Button, Dropdown, Menu, Select, Space } from "antd";
import { Option } from "antd/lib/mentions";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_filters.css";
import UtilBloc from "../../../bloc/util_bloc";
import { ReactComponent as DropDownIcon } from "../../../ArrowDownIcon.svg";

class AppDropdownFilter2 extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  renderCaption(caption) {
    let result = caption;

    // if (caption.length > 30) {
    //   result = result.split(" ");
    //   result[2] = `${result[2]}\n`;
    //   console.log(result);
    //   result.join("");
    // }

    return result;
  }

  handleChange(value) {
    this.props.onSelectedSearchFilter(value);
  }

  renderSearchByCaption() {
    let filters = [];
    for (let i = 0; i < this.props.dataSearchByCaption.length; i++) {
      let record = {};
      record = {
        caption: this.props.dataSearchByCaption[i],
        value: this.props.dataSearchByValue[i],
      };
      filters.push(record);
    }
    return filters.map((item) => {
      return (
        <Select.Option value={item.value}>
          <div data-toggle="tooltip" data-placement="top" title={item.caption}>
            {/* {UtilBloc.truncateString(item.caption, 30)} */}
            {this.renderCaption(item.caption)}
            {/* {item.caption.length > 20
              ? item.caption.length.split(" ")
              : item.caption} */}
          </div>
        </Select.Option>
      );
    });
  }

  render() {
    return (
      <>
        <div
          className="dropdown-filter d-flex justify-content-center align-items-center"
          style={{ width: 300 }}
        >
          {this.props.title ? (
            <div className="pl-3 dropdown-filter__label">
              {this.props.title}
            </div>
          ) : null}
          <Select
            style={{
              width: 500,
              fontSize: 14,
              fontWeight: 400,
            }}
            size={"large"}
            //placeholder="Select Filter"
            onChange={this.handleChange}
            bordered={false}
            defaultValue={this.props.defaultValue}
            suffixIcon={<DropDownIcon />}
            value={this.props.value}
          >
            {this.renderSearchByCaption()}
          </Select>
        </div>
      </>
    );
  }
}

export default withRouter(AppDropdownFilter2);
