import React, { Component } from "react";
import AppClaimStatusBean from "../data/bean/app_claim_status_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import GeneralWebService from "../service/general_web_service";
import IssuedCasesWebService from "../service/issued_cases_web_service";
import AppTableStatus from "../ui/component/app_table_status/app_table_status";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";

class IssuedCasesBloc extends Component {
  static async getIssuedCaseStatuses(
    entity,
    module,
    moduleId,
    dateFilter,
    fromDate,
    toDate,
    searchBy,
    keyword,
    regions,
    branches,
    agents
  ) {
    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let payload={
      "proposal-dt-range": dateFilter,
      dtFrom: getDateFilter.beginDate,
      dtTo: getDateFilter.endDate,
      "proposal-attr-search": searchBy,
      searchBy: keyword,
      regions: regions,
      branches: branches,
      agents: agents,
    }

    if(dateFilter!="range"){
      delete payload.dtFrom;
      delete payload.dtTo
    }

    let response = await GeneralBloc.getStatusBox(
      module,
      moduleId,
      false,
      false,
      payload
    );
    return response;
  }

  static getCollumnTable() {
    return [
      {
        title: "Certificate Number",
        dataIndex: "certificateNumber",
      },
      {
        title: "Product Name",
        dataIndex: "productName",
      },
      {
        title: "Participant Name",
        dataIndex: "participantName",
      },
      {
        title: "Participant ID",
        dataIndex: "participantID",
      },
      {
        title: "Participant Mobile Number",
        dataIndex: "mobileNo",
      },
      {
        title: "Certificate Issued Date",
        dataIndex: "certificateComDate",
      },
      {
        title: "Certificate Status",
        dataIndex: "certificateStatus",
      },
    ];
  }
  static getDummyIssuedCasesDataTable() {
    return [
      {
        key: "1",
        certificateNumber: "1222233330001",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: "IDM-101010",
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"green"} />
        ),
      },
      {
        key: "2",
        certificateNumber: "1222233330002",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"orange"} />
        ),
      },
      {
        key: "3",
        certificateNumber: "1222233330003",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"orange"} />
        ),
      },
      {
        key: "4",
        certificateNumber: "1222233330004",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"green"} />
        ),
      },
      {
        key: "5",
        certificateNumber: "1222233330005",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"green"} />
        ),
      },
      {
        key: "6",
        certificateNumber: "1222233330006",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"green"} />
        ),
      },
      {
        key: "7",
        certificateNumber: "1222233330007",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"orange"} />
        ),
      },
      {
        key: "8",
        certificateNumber: "1222233330008",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"green"} />
        ),
      },
      {
        key: "9",
        certificateNumber: "1222233330009",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"orange"} />
        ),
      },
      {
        key: "10",
        certificateNumber: "1222233330010",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"green"} />
        ),
      },
      {
        key: "11",
        certificateNumber: "1222233330011",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"orange"} />
        ),
      },
      {
        key: "12",
        certificateNumber: "1222233330012",
        productName: "Takaful Future Select",
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: 50783259,
        certificateComDate: "29/07/2022",
        certificateStatus: (
          <AppTableStatus statusName="Lorem Ipsum" status={"green"} />
        ),
      },
    ];
  }
  static async getIssuedCasesDataTable(
    entity,
    dateFilter,
    fromDate,
    toDate,
    regions,
    page,
    searchBy,
    keyword,
    branches,
    agents,
    statuses
  ) {
    let dataIssuedCase = [];
    let collumnTable = [];
    let totalData = 0;

    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let response = await IssuedCasesWebService.getIssuedCase(
      token,
      entity,
      page,
      getDateFilter.beginDate,
      getDateFilter.endDate,
      regions,
      searchBy,
      keyword,
      branches,
      agents,
      statuses,
      dateFilter
    );

    let dataSet = {};
    if (response.success) {
      dataSet = await GeneralBloc.getListingTable(response, "issuedCase");
    }
    //console.log(totalData);
    let result = {
      dataCollumn: dataSet.columnTable || [],
      dataIssuedCase: dataSet.data || [],
      totalData: dataSet.total || [],
    };
    return result;
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getBranchList(regions) {
    let token = "";
    let result;
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response = await GeneralWebService.getBranchList(token, regions);
    if (response.success) {
      result = response.data;
    }
    return result;
  }

  static async getSearchCriteriaList(regions, id) {
    let token = "";
    const session = await AuthBloc.checkSession();
    if (session) {
      token = session.accessToken;
    }

    let response = await GeneralWebService.getSearchCriteriaList(
      token,
      regions,
      id
    );

    let data = [];
    if (response.success) {
      let record = {};
      for (let i = 0; i < response.data.resultColumns[0].list.length; i++) {
        for (let j = 0; j < response.data.resultColumns.length; j++) {
          record[response.data.resultColumns[j]["caption"]] =
            response.data.resultColumns[j]["list"][i];
        }
       // console.log(record);
        data.push(record);
      }
     // console.log(data);
    }
    return data;
  }
}

export default IssuedCasesBloc;
