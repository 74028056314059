class SubmittedDocumentBean {
  constructor(docId,docName, docDesc, docType, canPreview) {
    this.docId = docId;
    this.docName = docName;
    this.docDesc = docDesc;
    this.docType = docType;
    this.canPreview = canPreview;
  }
}

export default SubmittedDocumentBean;