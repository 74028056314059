import React, { Component } from "react";
import AppHeaderBean from "../data/bean/app_header_bean";
import ProposalWebServices from "../service/proposal_web_services";
import HomeWebService from "../service/home_web_service";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";
import DataResult from "../data/bean/data_result";
import ListingWebServices from "../service/listing_web_services";
import UserAccessBloc from "./user_access_bloc";
//import NUIJSEvaluation from "../js/js_engine";
import GeneralWebService from "../service/general_web_service";
import UtilBloc from "./util_bloc";
import RouteBloc from "./route_bloc";
import JSScriptBloc from "./js/js_script_bloc";
import Config from "../Config";

class ListingBloc extends Component {
  static async getListingStatuses(
    entity,
    module,
    moduleId,
    dateFilter,
    fromDate,
    toDate,
    searchBy,
    keyword,
    filters,
    filtersAll,
    userConfig,
    searchType,
    section
  ) {
    let jsPayload = {
      listId: moduleId,
      module: module,
      entity: entity,
      dateFilter: searchType == "bySearch" ? null : dateFilter,
      fromDate:
        searchType == "bySearch" && section == "upcoming"
          ? this.getDayPlusOneDate()
          : searchType == "byGroup"
          ? fromDate
          : null,
      toDate: toDate,
      searchBy: searchBy,
      keyword: keyword,
      filters: filters,
      filtersAll: filtersAll,
      section: section,
    };

    let config = await UserAccessBloc.getUserConfigForModule(
      null,
      null,
      module,
      userConfig
    );
    let integration = config.integration;
    let requestParser = integration.countRequestParser;
    //console.log("JSPayload Count= " + JSON.stringify(jsPayload));
    // let finalPayload = NUIJSEvaluation.evaluate(
    //   { request: jsPayload },
    //   requestParser
    // );

    let selectedBank = RouteBloc.getSelectedBank().id;
    let finalPayload = JSScriptBloc.compute(
      { request: jsPayload },
      "integrationCountRequestParser",
      selectedBank
    );

    let response = await GeneralBloc.getStatusBox(
      module,
      moduleId,
      false,
      false,
      finalPayload.criParams,
      userConfig
    );
    return response;
  }

  static async downloadFile(module, id) {
    let resp = await HomeWebService.downloadFile(module, id);
    if (resp.data) {
      return new DataResult(true, resp.message, resp);
    } else {
      return new DataResult(false, resp.message, null);
    }
  }

  static async getSubmittedDocuments(docClass, recMap) {
    let resp = await ProposalWebServices.getSubmittedDocuments(
      docClass,
      recMap
    );
    if (resp.success) {
      return new DataResult(true, resp.message, resp.data);
    } else {
      return new DataResult(false, resp.message, null);
    }
  }

  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static getListingFilterValues(list) {
    let values = [];
    for (var i = 0; i < list.length; i++) {
      values.push(list.code);
    }
    return values;
  }

  static async getCriteriaForCode(module, code, userConfig) {
    let criteria =
      (await UserAccessBloc.getUserSearchCriteriaForModule(
        null,
        null,
        module,
        userConfig
      )) || [];
    let targetCriteria;
    for (var i = 0; i < criteria.length; i++) {
      if (criteria[i].code == code) {
        targetCriteria = criteria[i];
        break;
      }
    }
    return targetCriteria;
  }

  static async getListingFilter(
    module,
    appliedFilters,
    criteriaCode,
    userConfig
  ) {
    let targetCriteria = await this.getCriteriaForCode(
      module,
      criteriaCode,
      userConfig
    );
    //console.log("CATCH UUU",targetCriteria)
    if (!targetCriteria) {
      console.log(
        "No criteria found for code = " +
          criteriaCode +
          " and module = " +
          module
      );
      return [];
    }

    let apiEndpoint = targetCriteria.apiEndpoint;
    let code = targetCriteria.code;
    let request = targetCriteria.request;
    let requestTemplate = request.template;
    let requestRespParser = request.responseParser;
    let requestParam = request.param || [];
    let defaultEmpty = !targetCriteria.default;
    for (var i = 0; i < requestParam.length; i++) {
      let param = requestParam[i];
      let currentFilter = appliedFilters[param.fromCode];
      let paramCode = param.code;
      if (UtilBloc.isNullOrEmpty(currentFilter)) {
        currentFilter = [];
      }
      requestTemplate = requestTemplate.replace(
        `<${paramCode}>`,
        JSON.stringify(currentFilter)
      );
    }
    let response = await GeneralBloc.getDynamicFilter(
      apiEndpoint,
      requestTemplate
    );

    let selectedBank = RouteBloc.getSelectedBank().id;
    // let finalResult = NUIJSEvaluation.evaluate(
    //   { response: response, preSelect: !defaultEmpty },
    //   requestRespParser
    // );
    let finalResult = JSScriptBloc.compute(
      { response: response, preSelect: !defaultEmpty },
      code,
      selectedBank
    );

    /* console.log(
      "Dynamic listing filter for module " +
        module +
        " and criteria " +
        criteriaCode +
        ": " +
        JSON.stringify(finalResult)
    ); */

    return finalResult;
  }

  static async getListingDataTable(
    listId,
    module,
    entity,
    dateFilter,
    fromDate,
    toDate,
    page,
    searchBy,
    keyword,
    filters,
    filtersAll,
    statuses,
    userConfig,
    searchType,
    section
  ) {
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let jsPayload = {
      listId: listId,
      module: module,
      entity: entity,
      dateFilter: searchType == "bySearch" ? null : dateFilter,
      fromDate:
        searchType == "bySearch" && section == "upcoming"
          ? this.getDayPlusOneDate()
          : searchType == "byGroup"
          ? fromDate
          : null,
      toDate: toDate,
      page: page,
      searchBy: searchBy,
      keyword: keyword,
      filters: filters,
      filtersAll: filtersAll,
      statuses,
      section: section,
    };

    let config = await UserAccessBloc.getUserConfigForModule(
      null,
      null,
      module,
      userConfig
    );
    //bySearch
    let integration = config.integration;
    let requestParser = integration.requestParser;
    //console.log("JSPayload = " + JSON.stringify(jsPayload));
    // let finalPayload = NUIJSEvaluation.evaluate(
    //   { request: jsPayload },
    //   requestParser
    // );

    let selectedBank = RouteBloc.getSelectedBank().id;
    let finalPayload = JSScriptBloc.compute(
      { request: jsPayload },
      "integrationRequestParser",
      selectedBank,
      module
    );
    let response = await ListingWebServices.getListing(
      listId,
      token,
      entity,
      finalPayload
    );

    let dataSet = {};
    let result = {};

    if (response.success) {
      dataSet = await GeneralBloc.getListingTable(response, module);
    }

    result = {
      dataCollumn: dataSet.columnTable || [],
      dataProposal: dataSet.data || [],
      totalData: dataSet.total || [],
    };
    return result;
  }

  static async exportFileByModule(
    listId,
    module,
    entity,
    dateFilter,
    fromDate,
    toDate,
    searchBy,
    keyword,
    filters,
    filtersAll,
    statuses,
    userConfig
  ) {
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let jsPayload = {
      listId: listId,
      module: module,
      entity: entity,
      dateFilter: dateFilter,
      fromDate: fromDate,
      toDate: toDate,
      searchBy: searchBy,
      keyword: keyword,
      filters: filters,
      filtersAll: filtersAll,
      statuses,
    };

    let config = await UserAccessBloc.getUserConfigForModule(
      null,
      null,
      module,
      userConfig
    );
    let integration = config.integration;
    let requestParser = integration.requestParser;
    //console.log("JSPayload = " + JSON.stringify(jsPayload));
    // let finalPayload = NUIJSEvaluation.evaluate(
    //   { request: jsPayload },
    //   requestParser
    // );

    let selectedBank = RouteBloc.getSelectedBank().id;
    let finalPayload = JSScriptBloc.compute(
      { request: jsPayload },
      "integrationRequestParser",
      selectedBank,
      module
    );

    let response = await GeneralWebService.exportFileByModule(
      token,
      entity,
      finalPayload
    );

    return response;
  }

  static async getListingTitle(module) {
    let userSettings = await UserAccessBloc.getUserSettings();
    let userDetail = await GeneralBloc.getModuleDetailsFromUserSetting(
      userSettings,
      module
    );
    return userDetail.title;
  }

  static validateKeyword(keyword, validationType) {
    let isValid = true;
    let validationFormat;
    switch (validationType) {
      case 1:
        // No Symbol
        validationFormat = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        isValid = !validationFormat.test(keyword);
        break;

      case 2:
        // No number
        validationFormat = /\d/;
        isValid = !validationFormat.test(keyword);
        break;

      case 3:
        //No Letters & Numbers
        validationFormat = /^\d+$/;
        isValid = validationFormat.test(keyword);
        break;

      default:
        // No Validation
        break;
    }
    return isValid;
  }

  static getDayPlusOneDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const tomorrowFormatted = tomorrow.toISOString().slice(0, 10);

    return tomorrowFormatted;
  }
}

export default ListingBloc;
