import axios from "axios";
import { Component } from "react";
import GeneralBloc from "../bloc/general_bloc";
import BaseWebService from "./base_web_service";

export default class FormWebServices extends Component {

  static async getSubmittedDocuments(docClass,recMap) {    
    let headers = await BaseWebService.headers(docClass);
    let resp = await axios.get(GeneralBloc.encodeAndDecodeURL(BaseWebService.baseUrl() + `/submittedDocs/${docClass}/${recMap}`),      
      { headers: headers }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
  
  static async getForm(
    token,
    entity,
    page,
    fromDate,
    toDate,
    regions,
    searchBy,
    keyword
  ) {
    let header=await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: "form-list",
        pageNumber: page,
        criParams: {          
          "fileName":keyword, 
          "category":searchBy        
        }
      },
      {
        headers: header,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getFormDetail(token, entity, recMap) {
    let header=await BaseWebService.standardHeaders();
    let resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/form/${recMap}`,
      {
        headers: header,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
}
