import { Component } from "react";
import JWTTokenService from "../core_library/authentication/jwt_token_service";
import AuthWebService from "../service/auth_web_service";

class AuthBloc extends Component {
  static isHeadOfBanca(id) {
    return id == "FWD_HEAD_OF_BANCA" ? true : false;
  }

  static isSalePerson(mapCode) {
    return mapCode == "SalesP" ? true : false;
  }

  static async login(username, password, godModeforUser) {
    let response = await AuthWebService.login(
      username,
      password,
      process.env.REACT_APP_BASE_SYSTEM,
      godModeforUser
    );

    if (response.success) {
      localStorage.setItem("dataUser", JSON.stringify(response.data));
    }

    return {
      success: response.success,
      data: response.data,
      message: response.message,
    };
  }

  static async resetPassword(userName, password) {
    let response = await AuthWebService.resetPassword(userName, password);

    return {
      success: response.success,
      data: response.data,
      message: response.message,
    };
  }

  static async changePassword(userName, oldPassword, password) {
    let response = await AuthWebService.changePassword(
      userName,
      oldPassword,
      password
    );

    return {
      success: response.success,
      data: response.data,
      message: response.message,
    };
  }

  static async logOutPortal() {
    let response = await AuthWebService.logOutPortal();
    if (response.success == true) {
      localStorage.removeItem("dataUser");
      localStorage.removeItem("selectedBank");
      localStorage.removeItem("userSettings");
    }
    return {
      success: response.success,
      data: response.data,
      message: response.message,
    };
  }

  static getDataUser() {
    return localStorage.getItem("dataUser")
      ? JSON.parse(localStorage.getItem("dataUser"))
      : null;
  }

  static setGodMode(value) {
    localStorage.setItem("godMode", value);
  }

  static getGodMode() {
    return localStorage.getItem("godMode");
  }

  static async currentAccessToken() {
    let token = "";
    let refreshToken = "";

    let dataUser = JSON.parse(localStorage.getItem("dataUser"));
    if (dataUser) {
      token = dataUser.accessToken;
      refreshToken = dataUser.refreshToken;
    }

    return token;
  }

  static async checkSession() {
    let token = "";
    let refreshToken = "";

    let dataUser = JSON.parse(localStorage.getItem("dataUser"));
    if (dataUser) {
      token = dataUser.accessToken;
      refreshToken = dataUser.refreshToken;
    }

    if (token) {
      // Check Expirity of the token
      let isTokenValid = await JWTTokenService.isValid(token);

      if (!isTokenValid) {
        // Do Renew Token
        let resp = await AuthWebService.renewToken(refreshToken);

        //console.log(resp);

        if (resp.success) {
          localStorage.setItem("dataUser", JSON.stringify(resp.data));
          dataUser = JSON.parse(localStorage.getItem("dataUser"));
        } else {
          localStorage.removeItem("dataUser");
          dataUser = "";
        }
      }
    }
    return dataUser;
  }
}
export default AuthBloc;
