import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({});

export const overallListingReducer = createSlice({
  name: "overallListing",
  initialState,
  reducers: {
    setOverallListing: (state, action) => {
      state.value = {
        ...initialState,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = overallListingReducer;
export const { setOverallListing } = actions;

export default reducer;
