import React from "react";
import "./app_content_field_item2.css";

class AppContentFieldItem2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      value: props.value,
    };
  }

  stateTitle() {
    return this.state.title;
  }
  stateValue() {
    let value = this.state.value;
    if (this.state.title.includes("Fund Pricing")) {
      if (this.state.value == "N/A") {
        value = this.state.value;
      } else {
        value = `RM ${value}`;
      }
    }
    return value;
  }

  componentDidMount() {}

  render() {
    return (
      <div className="app-content-field2">
        <div className="app-content-field-tag2" dangerouslySetInnerHTML={{
          __html: this.stateTitle() || ""
        }}></div>
        <div className="app-content-field-title2" dangerouslySetInnerHTML={{
          __html: this.stateValue() || ""
        }}>          
        </div>
      </div>
    );
  }
}

export default AppContentFieldItem2;
