import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import AuthBloc from "../../../bloc/auth_bloc";

import "./select_account_screen.css";

class AccountScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      client: Config.context,
      route: routeParams,
      isLoggedIn: false,
      isLoading: false,
      channelList: [],
    };
  }

  stateChannelList() { return this.state.channelList };

  getChannelList() {    
    let availBanks = [];    
    if (AuthBloc.getDataUser()) {
      availBanks = AuthBloc.getDataUser().availBanks;
    }
    this.setState({
      channelList: availBanks
    })
  }

  componentDidMount() {
    this.getChannelList()
  }

  chooseAccount(account) {
    RouteBloc.saveSelectedBank(
      account.id,
      account.name
    );
    window.location.href=`/${this.state.client}/${RouteBloc.contextByEntity(account.id)}/${Config.routes.generic.home}`
  }

  render() {
    return (
      <div>
        {
          !this.state.isLoading ? this.stateChannelList() && this.stateChannelList().length > 0 ?
            <div class="channel_selection_container">
              <div className="channel_selection_title">
                Choose an account to proceed with
              </div>
              {/* <div className="channel_selection_desc">
                Choose an account to proceed with
              </div> */}
              <div className="channel_selection_list">
                {
                  this.stateChannelList() && this.stateChannelList().length > 0 && this.stateChannelList().map((ev) => {
                    return <div className="channel_selection_item" onClick={(e) => { this.chooseAccount(ev) }}>
                      <img
                        className="img-fluid"
                        style={{ padding: '20px',cursor:"pointer" }}
                        src={
                          GeneralBloc.getAssetsContextPath() +
                          `/assets/images/Logo/${ev.name}.svg`
                        }
                      />
                    </div>;
                  })
                }
              </div>
            </div>
            :
            <div class="channel_selection_container">
              <div className="channel_selection_title text-center" style={{marginLeft:"0px"}}>
                Access Denied
              </div>
              <div className="channel_selection_desc text-center mt-3" dangerouslySetInnerHTML={{
                __html: this.state.error ? this.state.error : "You are not allowed to use Admin Portal .<br /> Please check your Admin Role credential."
              }}>
              </div>
            </div> : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = {};

// export default withRouter(AccountScreen);
export default withRouter(connect(mapStateToProps)(AccountScreen));
