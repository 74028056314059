import React from "react";
import "./app_content_field_item3.css";
import GeneralBloc from "../../../bloc/general_bloc";

class AppContentFieldItem3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      value: props.value,
    };
  }

  stateTitle() {
    return this.state.title;
  }
  stateValue() {
    let value = this.state.value

    if (this.state.title == "Contribution Due Amount" ||
        this.state.title == "Additional Charges"
    ) {
      if (this.state.value == "N/A") {
        value = this.state.value;
      } else {
        let val = GeneralBloc.convertToCurrencyRM(value);
        value = val;
        }
      }

    return value;

  }

  componentDidMount() {}

  render() {
    return (
      <div className="app-content-field3">
        <div className="app-content-field-tag3">{this.stateTitle() || ""}</div>
        <div className="app-content-field-title3">
          {this.stateValue() || ""}
        </div>
      </div>
    );
  }
}

export default AppContentFieldItem3;
