import React, { useState } from "react";
import { Document, Page, pdfjs, View } from "react-pdf";
import GeneralBloc from "../../../bloc/general_bloc";
pdfjs.GlobalWorkerOptions.workerSrc = `${GeneralBloc.getAssetsContextPath()}/assets/javascript/pdf.worker.js`;


export default function AllPagesPdfViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pagesRendered, setPagesRendered] = useState(null);
  const styles = {
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
      width: "100%",
      orientation: "portrait",
    },
    view: {
      width: "100%",
      height: "100%",
      padding: 0,
      backgroundColor: "white",
    },
    image: {
      objectFit: "cover",
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPagesRendered(0);
  }

  function onRenderSuccess() {
    setPagesRendered(pagesRendered + 1)
  }


  const { pdf } = props;

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

  return (

    props.mobile == true || (props.width && props.width <= 600) ? 
    (<Document
      file={pdf}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(pagesRenderedPlusOne), (el, index) => {
        const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
        const isLastPage = numPages === index + 1;
        const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;
        return (
          <Page
            renderMode="canvas"
            orientation="portrait"
            wrap={true}
            //  width={1000}
            width={props.width || 400}
            // margin="0px"
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            style={styles.page}
            size="A4"
            onRenderSuccess={
              needsCallbackToRenderNextPage ? onRenderSuccess : null
            }           
          >
            <hr></hr>            
          </Page>
        )
      })}
    </Document>)
      :
      (<Document
        style={{ margin: "0px" }}
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            renderMode="canvas"
            width="1200"
            margin="0px"
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            style={styles.page}
            size="A4"
          >
            <hr></hr>
          </Page>
        ))}
      </Document>)
  );
}
