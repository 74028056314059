/* eslint-disable react/jsx-pascal-case */
import { Col, Row, Space } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import HomeBloc from "../../../bloc/home_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import App_carousel_dashboard from "../../component/app_carousel_dashboard/app_carousel_dashboard";
import App_claim_status from "../../component/app_claim_status/app_claim_status";
import App_daterange_filter from "../../component/app_filters/app_daterange_filter";
import App_dropdown_filter from "../../component/app_filters/app_dropdown_filter";
import App_header from "../../component/app_header/app_header";
import App_news_card from "../../component/app_news_card/app_news_card";
import App_dashboard_header from "../../component/app_proposal_dashboard/app_dashboard_header";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import "./home_screen.css";
import GeneralBloc from "../../../bloc/general_bloc";
import { setFilterBy } from "../../../data/redux/reducers/filterReducer";
import { setUserData } from "../../../data/redux/reducers/userDataReducer";
import { setFilterBy1 } from "../../../data/redux/reducers/filterReducer1";
import { setSearchBy } from "../../../data/redux/reducers/searchByReducer";
import { setUserConfig } from "../../../data/redux/reducers/userConfigReducer";
import { setDTOverallFilter } from "../../../data/redux/reducers/dtOverallFilterReducer";
import { setDateFilter } from "../../../data/redux/reducers/dateFilterReducer";
import { setOverallListing } from "../../../data/redux/reducers/overallListingReducer";
import { setUpcomingListing } from "../../../data/redux/reducers/upcomingListingReducer";
import { setFilterDialog } from "../../../data/redux/reducers/filterDialogReducer";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import Base_screen from "../base/base_screen/base_screen";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import App_daterange_filter_upcoming from "../../component/app_filters/app_daterange_filter_upcoming";
import App_claim_status_skeleton from "../../component/app_claim_status/app_claim_status_skeleton";
import AppFilterButtons from "../../component/app_filter_buttons/app_filter_buttons";
import AuthBloc from "../../../bloc/auth_bloc";

class homeScreen extends Component {
  constructor(props) {
    super(props);

    this.handleFilterDate = this.handleFilterDate.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleFilterDate1 = this.handleFilterDate1.bind(this);
    this.handleDateRange1 = this.handleDateRange1.bind(this);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);

    this.state = {
      client: Config.context,
      route: routeParams,
      proposalCount: null,
      issuedCasesCount: null,
      certificateCollectionCount: null,
      certificateCollectionCountUpcoming: null,
      claimsCount: null,
      businessOpportunitiesCount: null,
      businessOpportunitiesCountUpcoming: null,
      newsCardData: [],
      userData: [],
      carouselDummyData: [],
      carouselData: [],
      dateFilter: "",
      placeholderOverview: this.props.dateFilterGlobal?.overallDateValue
        ? this.props.dateFilterGlobal.overallDateValue
        : "",
      fromDate: "",
      toDate: "",
      dateFilter1: "",
      placeholderUpcoming: "",
      fromDate1: "",
      toDate1: "",
      loading: false,
      dateRangeOverall: {},
      dateRangeUpcoming: {},
      upcomingEventDropDown: {},
      issuedCaseTitle: "",
      dashboardListing: this.props.dateFilterGlobal,
    };
  }

  clearCounts() {
    this.setState({
      proposalCount: null,
      issuedCasesCount: null,
      certificateCollectionCount: null,
      claimsCount: null,
      businessOpportunitiesCount: null,
    });
  }

  clearUpcomingCounts() {
    this.setState({
      certificateCollectionCountUpcoming: null,
      businessOpportunitiesCountUpcoming: null,
    });
  }

  componentDidMount() {    
  }

  async init() {
    this.setState({
      loading: true,
    });

    await this.getDateRangeFilter();

    let userSettings = await UserAccessBloc.getUserSettings();
    let userDetail = await GeneralBloc.getModuleDetailsFromUserSetting(
      userSettings,
      "issuedCase"
    );
    this.setState({
      issuedCaseTitle: userDetail.title,
    });

    //Get announcements
    let newsData = HomeBloc.getAnnouncementLists();
    let announcements = HomeBloc.getAnnouncementBannerData();

    let userData = this.props.userDataGlobal.data;

    this.setState({
      newsCardData: await newsData,
      carouselData: await announcements,
      userData: userData,
      dashboardListing: this.props.dateFilterGlobal || {},
    });

    this.fetchDashboard();
    this.fetchUpcomingEvent();
  }

  async getDateRangeFilter() {
    let dateRangeOverallSetting = await GeneralBloc.getDateRangeFromConfig(
      this.props.userConfigGlobal,
      Config.modules.dashboardOverall
    );
    let dateRangeUpcomingSetting = await GeneralBloc.getDateRangeFromConfig(
      this.props.userConfigGlobal,
      Config.modules.dashboardUpcoming
    );

    let dateRangeIndexOverall = dateRangeOverallSetting.codes.indexOf(
      this.state.dashboardListing?.overallDateValue ||
        dateRangeOverallSetting.default
    );

    let dateRangeIndexUpcoming = dateRangeUpcomingSetting.codes.indexOf(
      this.state.dashboardListing?.upcomingDateValue ||
        dateRangeUpcomingSetting.default
    );

    let isOverallDefault = this.checkOverallDate();
    let isUpcomingDefault = this.checkUpcomingDate();
    if (isOverallDefault) {
      dateRangeIndexOverall = 0;
    }
    if (isUpcomingDefault) {
      dateRangeIndexUpcoming = 0;
    }

    this.setState({
      dateRangeOverall: dateRangeOverallSetting,
      dateFilter:
        dateRangeOverallSetting.codes[dateRangeIndexOverall] ||
        dateRangeOverallSetting.default,
      placeholderOverview:
        dateRangeOverallSetting.captions[dateRangeIndexOverall] ||
        dateRangeOverallSetting.defaultPlaceholder,
      dateRangeUpcoming: dateRangeUpcomingSetting,
      dateFilter1:
        dateRangeUpcomingSetting.codes[dateRangeIndexUpcoming] ||
        dateRangeUpcomingSetting.default,
      placeholderUpcoming:
        dateRangeUpcomingSetting.captions[dateRangeIndexUpcoming] ||
        dateRangeUpcomingSetting.defaultPlaceholder,
      fromDate:
        this.state.dashboardListing?.overallFromDate ??
        this.state.dashboardListing?.overallFromDate,
      toDate:
        this.state.dashboardListing?.overallToDate ??
        this.state.dashboardListing?.overallToDate,

      fromDate1:
        this.state.dashboardListing?.upcomingFromDate ??
        this.state.dashboardListing?.upcomingFromDate,
      toDate1:
        this.state.dashboardListing?.upcomingToDate ??
        this.state.dashboardListing?.upcomingToDate,
    });

    this.props.setDateFilter({
      overallDateValue: dateRangeOverallSetting.codes[0] || "-30d",
      upcomingDateValue: dateRangeUpcomingSetting.codes[0] || "7d",
    });
  }

  async handleFilterDate(dateValue) {
    if (dateValue != "range") {
      this.setState({
        fromDate: null,
        toDate: null,
      });
    }
    this.setState(
      {
        dateFilter: dateValue,
      },
      dateValue == "range" ? null : this.fetchDashboard,
      this.props.setOverallListing({}),
      this.props.setDateFilter({
        overallDateValue: dateValue,
      })
    );
  }

  async handleFilterDate1(dateValue) {
    this.setState(
      {
        dateFilter1: dateValue,
      },
      dateValue == "range" ? null : this.fetchUpcomingEvent,
      this.props.setUpcomingListing({}),
      this.props.setDateFilter({
        upcomingDateValue: dateValue,
      })
    );
  }

  checkOverallDate() {
    let isDefault = false;

    if (
      (this.state.dashboardListing?.overallDateValue == "range" &&
        this.state.dashboardListing?.overallToDate == undefined) ||
      (null && this.state.dashboardListing?.overallFromDate == undefined) ||
      null
    ) {
      isDefault = true;
    }
    return isDefault;
  }

  checkUpcomingDate() {
    let isDefault = false;

    if (
      (this.state.dashboardListing?.upcomingDateValue == "range" &&
        this.state.dashboardListing?.upcomingToDate == undefined) ||
      (null && this.state.dashboardListing?.upcomingFromDate == undefined) ||
      null
    ) {
      isDefault = true;
    }
    return isDefault;
  }

  async loadProposalCount() {
    let proposal = await HomeBloc.getDashboardCountForModule(
      "proposal",
      "df-proposal-count",
      this.state.dateFilter || "",
      this.state.fromDate,
      this.state.toDate
    );

    this.setState({
      loading: false,
      proposalCount: proposal,
    });
  }

  /**
   * Loading count for issued cases
   */
  async loadIssuedCaseCount() {
    let issuedCase = await HomeBloc.getDashboardCountForModule(
      "issuedCase",
      "df-issued-case-count",
      this.state.dateFilter || "",
      this.state.fromDate,
      this.state.toDate
    );

    this.setState({
      loading: false,
      issuedCasesCount: issuedCase,
    });
  }

  /**
   * Loading count for claims
   */
  async loadClaimCount() {
    let claim = await HomeBloc.getDashboardCountForModule(
      "claim",
      "df-claim-count",
      this.state.dateFilter || "",
      this.state.fromDate,
      this.state.toDate
    );

    console.log("CHECK CLAIM><", claim);

    this.setState({
      loading: false,
      claimsCount: claim,
    });
  }

  /**
   * Loading count for business opportunities
   */
  async loadBusinessOppCount() {
    let businessOpp = await HomeBloc.getDashboardCountForModule(
      "businessOpportunity",
      "df-business-oppo-count",
      this.state.dateFilter || "",
      this.state.fromDate,
      this.state.toDate
    );

    this.setState({
      loading: false,
      businessOpportunitiesCount: businessOpp,
    });
  }

  /**
   * Loading count for certificate collection
   */
  async loadCertCollectionCount() {
    let certificateCollection = await HomeBloc.getDashboardCountForModule(
      "certificateCollection",
      "df-cert-collection-status-count",
      this.state.dateFilter || "",
      this.state.fromDate,
      this.state.toDate
    );

    this.setState({
      loading: false,
      certificateCollectionCount: certificateCollection,
    });
  }

  /**
   * Fetch dashboard counts
   */
  async fetchDashboard() {
    this.clearCounts();
    // this.setState({
    //   loading: true,
    // });

    let regions = [];
    for (let i = 0; i < this.props.userGlobal.roles.length; i++) {
      regions.push(this.props.userGlobal.roles[i].region);
    }

    this.loadProposalCount();
    this.loadIssuedCaseCount();
    this.loadClaimCount();
    this.loadBusinessOppCount();
    this.loadCertCollectionCount();

    this.setState({
      loading: false,
    });
  }

  /**
   * Loading count for business opportunities upcoming
   */
  async loadBusinessOppUpcomingCount() {
    let businessOpp = await HomeBloc.getDashboardCountForModule(
      "businessOpportunity-upcoming",
      "df-business-oppo-count",
      this.state.dateFilter1 || "",
      this.state.fromDate1,
      this.state.toDate1
    );

    this.setState({
      loading: false,
      businessOpportunitiesCountUpcoming: businessOpp,
    });
  }

  /**
   * Loading count for certificate collection upcoming
   */
  async loadCertCollectionUpcomingCount() {
    let certificateCollection = await HomeBloc.getDashboardCountForModule(
      "certificateCollection-upcoming",
      "df-cert-collection-status-count",
      this.state.dateFilter1 || "",
      this.state.fromDate1,
      this.state.toDate1
    );

    this.setState({
      loading: false,
      certificateCollectionCountUpcoming: certificateCollection,
    });
  }

  /**
   * Fetch overview counts for upcoming events
   */
  async fetchUpcomingEvent() {
    this.clearUpcomingCounts();
    // this.setState({
    //   loading: true,
    // });

    let regions = [];
    for (let i = 0; i < this.props.userGlobal.roles.length; i++) {
      regions.push(this.props.userGlobal.roles[i].region);
    }

    this.loadBusinessOppUpcomingCount();
    this.loadCertCollectionUpcomingCount();
  }

  async handleDateRange(dateRange) {
    let fromDate = dateRange[0];
    let toDate = dateRange[1];

    this.setState(
      {
        ...this.state,
        fromDate: fromDate,
        toDate: toDate,
      },
      this.fetchDashboard,

      this.props.setDateFilter({
        ...this.state.dashboardListing,
        overallFromDate: fromDate,
        overallToDate: toDate,
        overallDateValue: this.state.dateFilter,
      }),

      this.props.setOverallListing({
        overallFromDate: fromDate,
        overallToDate: toDate,
      })
    );
  }

  async handleDateRange1(dateRange) {
    this.setState(
      {
        ...this.state,
        fromDate1: dateRange[0],
        toDate1: dateRange[1],
      },
      this.fetchUpcomingEvent,
      this.props.setDateFilter({
        ...this.state.dashboardListing,
        upcomingFromDate: dateRange[0],
        upcomingToDate: dateRange[1],
        upcomingDateValue: this.state.dateFilter1,
      }),

      this.props.setUpcomingListing({
        upcomingFromDate: dateRange[0],
        upcomingToDate: dateRange[1],
        upcomingDateValue: this.state.dateFilter1,
      })
    );
  }

  handleViewMoreOverall() {
    this.props.setDateFilter({
      overallFromDate: this.state.fromDate,
      overallToDate: this.state.toDate,
      overallDateValue: this.state.dateFilter,
      upcomingFromDate: this.state.fromDate1,
      upcomingToDate: this.state.toDate1,
      upcomingDateValue: this.state.dateFilter1,
    });

    this.props.setOverallListing({});
    this.props.setUpcomingListing({});
    this.props.setFilterDialog({});
  }

  handleViewMoreUpcoming() {
    this.props.setDateFilter({
      overallFromDate: this.state.fromDate,
      overallToDate: this.state.toDate,
      overallDateValue: this.state.dateFilter,
      upcomingFromDate: this.state.fromDate1,
      upcomingToDate: this.state.toDate1,
      upcomingDateValue: this.state.dateFilter1,
    });
    this.props.setOverallListing({});
    this.props.setUpcomingListing({});
    this.props.setFilterDialog({});
  }

  renderClaimStatusProposal() {
    return (
      <div className="homescreen__cards_container mt-3">
        {this.state.proposalCount.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status data={item} />
            </div>
          );
        })}
      </div>
    );
  }

  renderClaimStatusIssuedCases() {
    /* return this.state.issuedCasesCount.map((item, index) => {
      return (
        <Space className="mt-3" style={{ marginRight: "16px" }} key={index}>
          <App_claim_status data={item} />
        </Space>
      );
    }); */
    return (
      <div className="homescreen__cards_container mt-3">
        {this.state.issuedCasesCount.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status data={item} />
            </div>
          );
        })}
      </div>
    );
  }

  renderClaimStatusCertificateCollection() {
    /*  return this.state.certificateCollectionCount.map((item, index) => {
      return (
        <Space className="mt-3" style={{ marginRight: "16px" }} key={index}>
          <App_claim_status data={item} />
        </Space>
      );
    }); */
    return (
      <div className="homescreen__cards_container mt-3">
        {this.state.certificateCollectionCount.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status data={item} />
            </div>
          );
        })}
      </div>
    );
  }

  renderClaimStatusCertificateCollection1() {
    /* return this.state.certificateCollectionCountUpcoming.map((item, index) => {
      return (
        <Space className="mt-3" style={{ marginRight: "16px" }} key={index}>
          <App_claim_status data={item} />
        </Space>
      );
    }); */
    return (
      <div className="homescreen__cards_container mt-3">
        {this.state.certificateCollectionCountUpcoming.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status data={item} />
            </div>
          );
        })}
      </div>
    );
  }

  renderClaimStatusClaimStatus() {
    /* return this.state.claimsCount.map((item, index) => {
      return (
        <Space className="mt-3" style={{ marginRight: "16px" }} key={index}>
          <App_claim_status data={item} />
        </Space>
      );
    }); */
    console.log("Claim Count", this.state.claimsCount);

    return (
      <div className="homescreen__cards_container mt-3">
        {this.state.claimsCount.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status data={item} />
            </div>
          );
        })}
      </div>
    );
  }

  renderSkeleton(count) {
    let toRenderCount = count || 1;
    let toRenderList = [];
    for (var i = 0; i < toRenderCount; i++) {
      toRenderList.push({});
    }
    return (
      <div className="homescreen__cards_container mt-3">
        {toRenderList.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status_skeleton data={{}} />
            </div>
          );
        })}
      </div>
    );
  }

  renderClaimStatusBusinessOpportunities() {
    return (
      <div className="homescreen__cards_container mt-3">
        {this.state.businessOpportunitiesCount.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status data={item} />
            </div>
          );
        })}
      </div>
    );
  }

  renderClaimStatusBusinessOpportunities1() {
    return (
      <div className="homescreen__cards_container mt-3">
        {this.state.businessOpportunitiesCountUpcoming.map((item, index) => {
          return (
            <div className="cards_container">
              <App_claim_status data={item} />
            </div>
          );
        })}
      </div>
    );
  }

  renderNewsCards() {
    return this.state.newsCardData.map((item, idx) => {
      return (
        <div className="mb-2" key={idx}>
          <App_news_card
            data={item}
            route={this.state.route.entity}
            client={this.state.client}
          />
        </div>
      );
    });
  }

  renderDashboards() {
    return (
      <div>
        {(!this.state.proposalCount || this.state.proposalCount.length > 0) && GeneralBloc.proposalEnabled() ? (
          <App_dashboard_header
            title="Proposal"
            onClick={() => {
              this.handleViewMoreOverall();
            }}
            link={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.proposal}`}
          >
            {!this.state.proposalCount
              ? this.renderSkeleton(2)
              : this.renderClaimStatusProposal()}
          </App_dashboard_header>
        ) : null}

        {(!this.state.issuedCasesCount ||
        this.state.issuedCasesCount.length > 0) && GeneralBloc.issuedCaseEnabled() ? (
          <App_dashboard_header
            title={
              this.state.issuedCaseTitle
                ? this.state.issuedCaseTitle
                : "Issued Cases"
            }
            onClick={() => {
              this.handleViewMoreOverall();
            }}
            link={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.issuedCase}`}
          >
            {!this.state.issuedCasesCount
              ? this.renderSkeleton(1)
              : this.renderClaimStatusIssuedCases()}
          </App_dashboard_header>
        ) : null}

        {(!this.state.certificateCollectionCount ||
        this.state.certificateCollectionCount.length > 0) && GeneralBloc.certCollectionEnabled() ? (
          <App_dashboard_header
            title="Certificate Collection Status"
            onClick={() => {
              this.handleViewMoreOverall();
            }}
            link={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.certificateCollection}`}
          >
            {!this.state.certificateCollectionCount
              ? this.renderSkeleton(3)
              : this.renderClaimStatusCertificateCollection()}
          </App_dashboard_header>
        ) : null}

        {(!this.state.claimsCount || this.state.claimsCount.length > 0) && GeneralBloc.claimEnabled() ? (
          <App_dashboard_header
            onClick={() => {
              this.handleViewMoreOverall();
            }}
            title="Claims"
            link={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.claim}`}
          >
            {!this.state.claimsCount
              ? this.renderSkeleton(1)
              : this.renderClaimStatusClaimStatus()}
          </App_dashboard_header>
        ) : null}

        {(!this.state.businessOpportunitiesCount ||
        this.state.businessOpportunitiesCount.length > 0 ) && GeneralBloc.bizOppEnabled() ? (
          <App_dashboard_header
            onClick={() => {
              this.handleViewMoreOverall();
            }}
            title="Business Opportunities"
            link={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.businessOpportunity}`}
          >
            {!this.state.businessOpportunitiesCount
              ? this.renderSkeleton(1)
              : this.renderClaimStatusBusinessOpportunities()}
          </App_dashboard_header>
        ) : null}
      </div>
    );
  }

  renderUpcomingEvents() {
    return (
      <>
        {(!this.state.certificateCollectionCountUpcoming ||
        this.state.certificateCollectionCountUpcoming.length > 0) && GeneralBloc.certCollectionUpcomingEnabled() ? (
          <App_dashboard_header
            onClick={() => {
              this.handleViewMoreUpcoming();
            }}
            title="Certificate Collection Status"
            link={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.certificateCollectionUpcoming}`}
          >
            {!this.state.certificateCollectionCountUpcoming
              ? this.renderSkeleton(2)
              : this.renderClaimStatusCertificateCollection1()}
          </App_dashboard_header>
        ) : null}

        {(!this.state.businessOpportunitiesCountUpcoming ||
        this.state.businessOpportunitiesCountUpcoming.length > 0) && GeneralBloc.bizOppUpcomingEnabled() ? (
          <App_dashboard_header
            onClick={() => {
              this.handleViewMoreUpcoming();
            }}
            title="Business Opportunities"
            link={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.businessOpportunityUpcoming}`}
          >
            {!this.state.businessOpportunitiesCountUpcoming
              ? this.renderSkeleton(1)
              : this.renderClaimStatusBusinessOpportunities1()}
          </App_dashboard_header>
        ) : null}
      </>
    );
  }

  renderDailyReminders(){   
    let userSettings = UserAccessBloc.getUserSettings(); 
    let notificationSetting=null;
    if(userSettings){
        notificationSetting =  GeneralBloc.getModuleDetailsFromUserSetting(
        userSettings,
        "notification"
      );
    }
    
    return notificationSetting && <div>
      <div className="homescreen__dashboard">
            <div className="homescreen__title">{notificationSetting.title}</div>                       
      </div>
      <div className="mt-4">
        <AppFilterButtons
          from={"dashboard"}
          filterButtonConfig={notificationSetting.status}
          defaultSelected={notificationSetting.defaultSelected || ["BIRTHDAY"]}
          notificationTemplate={notificationSetting.notificationTemplate}
          findArrayNotificationTitle={notificationSetting.findArrayNotificationTitle}
          findArrayNotificationDesc={notificationSetting.findArrayNotificationDesc}
          client={this.state.client}
          route={this.state.route}
          filterStatus={(selectedStatus)=>{
            console.log("selectedStatus",selectedStatus)
          }}
          maxRecords={3}
          gotNotification={(gotNotification)=>{
            this.setState({
              gotNotification
            })
          }}
        />
      </div>      
    </div>
  }

  renderHeaderContents() {
    return (
      <div className="homescreen__main_content">
        <div>
          {this.state.loading && this.loadingContent()}
          <div className="homescreen__header w-100">
            <div className="homescreen__topbanner_left">
              <App_carousel_dashboard
                data={this.state.carouselData}
                route={this.state.route.entity}
                client={this.state.client}
              />
            </div>

            <div className="homescreen__topbanner_right">
              <div
                className="homescreen__announcement-title"
                style={{ marginBottom: "20px" }}
              >
                Announcements
              </div>
              {this.renderNewsCards()}
              <div className="homescreen__announcement-view-more">
                <Link
                  className="homescreen__announcement-view-more"
                  to={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.announcement}`}
                >
                  View More
                </Link>
              </div>
            </div>
          </div>
          {/* <Row className="w-full homescreen__header">
            <Col span={15}>
              <App_carousel_dashboard
                data={this.state.carouselData}
                route={this.state.route.entity}
                client={this.state.client}
              />
            </Col>
            <Col span={9} className="w-100 pl-3">
              <div
                className="homescreen__announcement-title"
                style={{ marginBottom: "20px" }}
              >
                Announcements
              </div>
              {this.renderNewsCards()}
              <div className="homescreen__announcement-view-more">
                <Link
                  className="homescreen__announcement-view-more"
                  to={`/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.announcement}`}
                >
                  View More
                </Link>
              </div>
            </Col>
          </Row> */}
          {
              AuthBloc.isSalePerson(this.props.userGlobal.roles[0].mapCode) ? 
              this.renderDailyReminders():
              null              
          }           
          <div className="homescreen__dashboard">
            <div className="homescreen__title">Overall Dashboard</div>
            <div className="d-flex">
              <App_dropdown_filter
                onSelectedDateFilter={this.handleFilterDate}
                dataDateByCaption={this.state.dateRangeOverall.captions || []}
                dataDateByValue={this.state.dateRangeOverall.codes || []}
                placeholder={this.state.placeholderOverview}
              />
              <App_daterange_filter
                isNotRange={this.state.dateFilter != "range"}
                defaultValueFromDate={this.state.fromDate}
                defaultValueToDate={this.state.toDate}
                value={[this.state.fromDate, this.state.toDate]}
                disabled={this.state.dateFilter == "range" ? false : true}
                onSelectedDateRange={this.handleDateRange}
              />
            </div>
          </div>
          {this.renderDashboards()}
          
          {(!this.state.certificateCollectionCountUpcoming ||
        this.state.certificateCollectionCountUpcoming.length > 0) && GeneralBloc.certCollectionUpcomingEnabled()  ||
        (!this.state.businessOpportunitiesCountUpcoming ||
          this.state.businessOpportunitiesCountUpcoming.length > 0) && GeneralBloc.bizOppUpcomingEnabled()  ? (
            <>
              <div
                style={{
                  marginTop: "32px",
                  width: "100%",
                  border: "1px solid #183028",
                  backgroundColor: "#183028",
                }}
              />
              <div className="d-flex justify-content-between homescreen__dashboard">
                <div className="homescreen__title">Upcoming Events</div>
                <div className="d-flex">
                  <App_dropdown_filter
                    onSelectedDateFilter={this.handleFilterDate1}
                    dataDateByCaption={
                      this.state.dateRangeUpcoming.captions || []
                    }
                    dataDateByValue={this.state.dateRangeUpcoming.codes || []}
                    placeholder={this.state.placeholderUpcoming}
                  />
                  <App_daterange_filter_upcoming
                    isNotRange={this.state.dateFilter1 != "range"}
                    defaultValueFromDate={this.state.fromDate1}
                    defaultValueToDate={this.state.toDate1}
                    value={[this.state.fromDate1, this.state.toDate1]}
                    disabled={this.state.dateFilter1 == "range" ? false : true}
                    onSelectedDateRange={this.handleDateRange1}
                  />
                </div>
              </div>
              {this.renderUpcomingEvents()}
            </>
          ) : null} 
        </div>
      </div>
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    return (
      <>
        <Base_screen
          // loading={this.state.loading}
          entity={this.state.route.entity}
          listener={() => {
            this.init();
          }}
        >
          {this.props.userGlobal ? (
            <div className="homescreen">
              <App_header
                data={this.state.userData}
                route={this.state.route.entity}
              />
              <App_sidebar
                route={this.state.route.entity}
                onLinkClick={() => {
                  this.props.setOverallListing({
                    overallDateValue: null,
                  });
                }}
              >
                {this.renderHeaderContents()}
              </App_sidebar>
            </div>
          ) : (
            <Redirect
              to={`/${this.state.client}/${Config.routes.generic.login}`}
            />
          )}
        </Base_screen>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userGlobal: state.user.value,
    globalFilterBy: state.filter.value,
    globalSearchBy: state.searchBy.value,
    userConfigGlobal: state.userConfig.value,
    userDataGlobal: state.userData.value,
    dtOverallFilterGlobal: state.dtOverallFilter.value,
    dateFilterGlobal: state.dateFilter.value,
    dialogFilterGlobal: state.filterDialog.value,
    overallListingGlobal: state.overallListing.value,
    upcomingListingGlobal: state.upcomingListing.value,

    //globalFilterByUpcoming: state.filter1.value,
  };
};

const mapDispatchToProps = {
  setFilterBy,
  setSearchBy,
  setFilterBy1,
  setUserData,
  setUserConfig,
  setDTOverallFilter,
  setDateFilter,
  setOverallListing,
  setUpcomingListing,
  setFilterDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(homeScreen)
);

// export default withRouter(homeScreen);
