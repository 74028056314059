import AppCarouselBean from "../data/bean/app_carousel_bean";
import AppClaimStatusBean from "../data/bean/app_claim_status_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import AppNewsCardBean from "../data/bean/app_news_card_bean";
import HomeWebService from "../service/home_web_service";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";
import ProposalBloc from "./proposal_bloc";

class HomeBloc {
  static getDummyProposalStatuses() {
    return [
      new AppClaimStatusBean("InProgress.svg", "Pending", 30),
      new AppClaimStatusBean("Received.svg", "Outstanding", 20),
    ];
  }
  static getDummyIssuedCasesStatuses() {
    return [new AppClaimStatusBean("Received.svg", "Issue Cases", 20)];
  }
  static getDummyCertificateCollectionStatuses() {
    return [
      new AppClaimStatusBean("Approved.svg", "Force", 38),
      new AppClaimStatusBean("InProgress.svg", "Contract Lapsed", 42),
      new AppClaimStatusBean("Closed.svg", "Lapsed", 2),
      new AppClaimStatusBean(
        "Mixed Decisions.svg",
        "Regular Payment Rescind",
        2
      ),
    ];
  }
  static getDummyClaimsStatuses() {
    return [
      new AppClaimStatusBean("Received.svg", "Accepted", 30),
      new AppClaimStatusBean("InProgress.svg", "Paid Up", 42),
      new AppClaimStatusBean("Approved.svg", "Premium Due", 38),
      new AppClaimStatusBean("Closed.svg", "Closed", 2),
      new AppClaimStatusBean("Rejected.svg", "Rejected", 12),
    ];
  }
  static getDummyBusinessOpportunitiesStatuses() {
    return [
      new AppClaimStatusBean("InProgress.svg", "Fully Paid Up", 30),
      new AppClaimStatusBean("Received.svg", "Matured", 20),
    ];
  }

  static getDummyNewsCards() {
    return [
      new AppNewsCardBean(
        "1",
        "Celebrate Life with Father's Day",
        `Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy textLorem Ipsum is simply dummy textLorem Ipsum is simply dummy textLorem Ipsum is simply dummy textLorem Ipsum is simply dummy textLorem Ipsum is simply dummy textLorem Ipsum is simply dummy textLorem Ipsum is simply dummy textLorem Ipsum is simply dummy text`,
        "2 days ago"
      ),

      new AppNewsCardBean(
        "2",
        "Celebrate the Day with your Family",
        `Lorem Ipsum is simply dummy text`,
        "5 days ago"
      ),

      new AppNewsCardBean(
        "3",
        "News",
        `Lorem Ipsum is simply dummy text`,
        "5 hours ago"
      ),
    ];
  }

  static truncateString(str, num) {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
      return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + "...";
  }
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }

  static getDummyCarouselNewsData() {
    return [
      new AppCarouselBean(
        "1",
        "24 May, 2022",
        "Celebrate Life with Father's Day",
        " Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        "Carousel1.jpg"
      ),
      new AppCarouselBean(
        "2",
        "24 May, 2022",
        "Celebrate Life with Father's Day",
        " Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        "Announcement1.jpg"
      ),
      new AppCarouselBean(
        "3",
        "24 May, 2022",
        "Celebrate Life with Father's Day",
        " Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        "Announcement2.jpg"
      ),
    ];
  }
  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static getUserDataObj(name, role, branch) {
    return {
      name: name,
      role: role,
      branch: branch,
    };
  }

  static async getAnnouncementBannerData(page) {
    let dataAnnouncementBanner = [];
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }
    let response = await HomeWebService.getAnnouncementBanner(page ? page : 1);
    // cahnge into recCount
    if (response.success) {
      let recCount = response.data.resultColumns[0].list.length; //response.data.recCount;
      for (let i = 0; i < recCount; i++) {
        // not called again becauase the data not 10 >

        let resultColumns = response.data.resultColumns;

        let imageUrl = HomeWebService.getAnnouncementImageUrl(
          token,
          "announcement",
          GeneralBloc.getResultColumnByKey("announcement_id", resultColumns)
            .list[i]
        );

        let bean = new AppCarouselBean(
          GeneralBloc.getResultColumnByKey(
            "announcement_id",
            resultColumns
          ).list[i],
          GeneralBloc.getResultColumnByKey("ann_date", resultColumns).list[i],
          GeneralBloc.getResultColumnByKey("title", resultColumns).list[i],
          GeneralBloc.getResultColumnByKey("short_content", resultColumns).list[
            i
          ],
          imageUrl
        );

        dataAnnouncementBanner.push(bean);
      }
    }
    return dataAnnouncementBanner;
  }

  static async getAnnouncementBanners(page) {
    let dataAnnouncementBanner = [];
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }
    let response = await HomeWebService.getAnnouncementBanner(null, null, page);
    // cahnge into recCount
    if (response.success) {
      let recCount = response.data.resultColumns[0].list.length;
      for (let i = 0; i < recCount; i++) {
        // not called again becauase the data not 10 >
        let resultColumns = response.data.resultColumns;

        let imageUrl = HomeWebService.getAnnouncementImageUrl(
          token,
          "announcement",
          GeneralBloc.getResultColumnByKey("announcement_id", resultColumns)
            .list[i]
        );

        let bean = new AppCarouselBean(
          GeneralBloc.getResultColumnByKey(
            "announcement_id",
            resultColumns
          ).list[i],
          GeneralBloc.getResultColumnByKey("ann_date", resultColumns).list[i],
          GeneralBloc.getResultColumnByKey("title", resultColumns).list[i],
          GeneralBloc.getResultColumnByKey("short_content", resultColumns).list[
            i
          ],
          imageUrl
        );

        dataAnnouncementBanner.push(bean);
      }
    }
    return [dataAnnouncementBanner, response.data];
  }

  static async getAnnouncementLists() {
    let dataAnnouncementList = [];
    let response = await HomeWebService.getAnnouncementBanner(1);
    // cahnge into recCount
    if (response.success) {
      let recCount = response.data.recCount;
      for (let i = 0; i < recCount; i++) {
        dataAnnouncementList.push(
          new AppNewsCardBean(
            response.data.resultColumns[3].list[i],
            response.data.resultColumns[5].list[i],
            response.data.resultColumns[4].list[i],
            response.data.resultColumns[0].list[i]
          )
        );
      }
    }
    //console.log(dataAnnouncementList.slice(0, 3));
    return dataAnnouncementList.slice(0, 3);
  }

  static async getDashboardCountForModule(
    module,
    moduleId,
    dateFilter,
    fromDate,
    toDate
  ) {
    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let payload = {
      "proposal-dt-range": dateFilter,
      dtFrom: getDateFilter.beginDate,
      dtTo: getDateFilter.endDate,
    };

    if (dateFilter != "range") {
      delete payload.dtFrom;
      delete payload.dtTo;
    }

    let response = await GeneralBloc.getStatusBox(
      module,
      moduleId,
      false,
      false,
      payload
    );
    return response;
  }

  static async getProposalDataCount(entity, dateFilter, fromDate, toDate) {
    let token = "";
    let dataProposalCount = [];

    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    //console.log(getDateFilter);

    let response = await HomeWebService.getProposalCount(
      token,
      entity,
      getDateFilter.beginDate,
      getDateFilter.endDate
    );
    for (let i = 0; i < response.data.resultColumns[0].list.length; i++) {
      let record = [];
      record = new AppClaimStatusBean(
        "Received.svg",
        response.data.resultColumns[1].list[i],
        response.data.resultColumns[0].list[i]
      );
      dataProposalCount.push(record);
      //  new AppClaimStatusBean("Received.svg", "Accepted", 30),
    }
    return dataProposalCount;
  }

  static async getIssuedCasesDataCount(entity, dateFilter, fromDate, toDate) {
    let token = "";
    let dataProposalCount = [];

    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    //console.log(getDateFilter);

    let response = await HomeWebService.getIssuedCaseCount(
      token,
      entity,
      getDateFilter.beginDate,
      getDateFilter.endDate
    );
    //console.log("RESPONSE PROPOSAL", response);
    for (let i = 0; i < response.data.resultColumns[0].list.length; i++) {
      let record = [];
      record = new AppClaimStatusBean(
        "Received.svg",
        response.data.resultColumns[1].list[i],
        response.data.resultColumns[0].list[i]
      );
      dataProposalCount.push(record);
      //  new AppClaimStatusBean("Received.svg", "Accepted", 30),
    }
    return dataProposalCount;
  }
}

export default HomeBloc;
