import { Avatar, Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import ProfileBloc from "../../../bloc/profile_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import Base_screen from "../base/base_screen/base_screen";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import HomeBloc from "../../../bloc/home_bloc";
import Config from "../../../Config";

import "./profile_screen.css";

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      route: routeParams,
      userDataHeader: null,
      userSettings: {},
    };
  }

  getUserDataHeader() {
    return this.state.userDataHeader;
  }
  getUserData() {
    return this.state.userData;
  }
  getEntity() {
    return this.state.route.entity;
  }

  getUserSettings() {
    return this.state.userSettings;
  }

  generateProfileSettingValue() {
    let userSettings = this.getUserSettings().info[0].value;
    let userData = this.getUserData();
    let value = userData[userSettings];
    return value;
  }

  componentDidMount() {
    let userData = UserAccessBloc.getUserData();
    let userSettings = UserAccessBloc.getUserSettings();
    let name = "";
    let role = "";
    let branch = "";
    let profile = [];
    if (userData) {
      name = userData ? userData.displayName : "";
      role = userData ? userData.designationTitle : "";
      profile = userSettings ? userSettings.module.profile : [];
      this.setState({
        userData: userData,
        userDataHeader: HomeBloc.getUserData(name, role, branch),
        userSettings: profile,
      });
    }
  }

  getInitial(name) {
    let result = GeneralBloc.mergeIntoInitialName(name);
    return result;
  }

  render() {
    return (
      <>
        <Base_screen
          listener={() => {
            this.getEntity();
          }}
        >
          {this.getUserData() ? (
            <>
              <App_header
                data={this.getUserDataHeader()}
                route={this.getEntity()}
              />
              <div
                style={{
                  marginTop: 110,
                  paddingLeft: 42,
                  paddingRight: 42,
                  display: "flex",
                  width: "100%",
                }}
              >
                <App_header_title_simple title="View Profile" />
              </div>
              <div className=" profile__container">
                <div className="d-flex justify-content-center">
                  <Avatar
                    className="d-flex justify-content-center"
                    style={{
                      color: "#EE7623",
                      backgroundColor: "#F2F2F2",
                    }}
                    size={100}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{ fontSize: 40 }}
                    >
                      {" "}
                      {this.getInitial(
                        this.getUserData().displayName.length > 0
                          ? this.getUserData().displayName
                          : "N/A"
                      )}
                    </div>
                  </Avatar>
                </div>
                <div className="profile__detail">
                  <div className="profile__detail-title">Account Details</div>
                  <Row>
                    <Col span={12}>
                      <div className="profile__customer-detail-content">
                        <div className="profile__customer-detail-title">
                          Agent Name / Username
                        </div>
                        <div className="profile__customer-detail-tag">
                          {(
                            this.getUserData().displayName ||
                            this.getUserData().username
                          ).length > 0
                            ? this.getUserData().displayName ||
                              this.getUserData().username
                            : "N/A"}
                        </div>
                      </div>

                      <div className="profile__customer-detail-content">
                        <div>Agent ID / User ID</div>
                        <div className="profile__customer-detail-tag">
                          {(
                            this.getUserData().bancaIdNo ||
                            this.getUserData().username
                          ).length > 0
                            ? this.getUserData().bancaIdNo ||
                              this.getUserData().username
                            : "N/A"}
                        </div>
                      </div>
                      {
                        this.getUserSettings().info.length >0 && <div className="profile__customer-detail-content">
                        <div>{this.getUserSettings().info[0].title}</div>
                        <div className="profile__customer-detail-tag">
                          {this.generateProfileSettingValue().length > 0
                            ? this.generateProfileSettingValue()
                            : "N/A"}
                        </div>
                      </div>
                      }
                      
                    </Col>
                    {this.getUserSettings().showBranchInfor===true/* this.getUserData().roles[0].mapCode != "BDM" && this.getUserData().roles[0].mapCode != "BranchM" */ ? (
                      <Col span={12}>
                        <div className="profile__customer-detail-content">
                          <div className="profile__customer-detail-title">
                            {this.getUserData().roles[0].branch.length > 0
                              ? "Branch Code"
                              : null}
                          </div>
                          <div className="profile__customer-detail-tag">
                            {" "}
                            {this.getUserData().roles[0].branch}
                          </div>
                        </div>

                        <div className="profile__customer-detail-content">
                          <div>
                            {this.getUserData().roles[0].branchName.length > 0
                              ? " Branch Name"
                              : null}
                          </div>
                          <div className="profile__customer-detail-tag">
                            {this.getUserData().roles[0].branchName}
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </div>
            </>
          ) : null}
        </Base_screen>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
  globalFilterBy: state.filter.value,
  globalSearchBy: state.searchBy.value,
  userConfigGlobal: state.userConfig.value,
});

export default withRouter(connect(mapStateToProps)(ProfileScreen));
