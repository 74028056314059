class JSScriptBloc {
  static compute(input, code, entity, module) {
    switch (entity) {
      case "BCA": {
        switch (code) {
          case "REG":
            return this.hsbcRegionCriteriaResponseParser(input);
          case "BRH":
            return this.hsbcBranchCriteriaResponseParser(input);
          case "SEN":
            return this.hsbcSalesExecCriteriaResponseParser(input);
          case "integrationRequestParser":
            return this.hsbcIntegrationRequestParser(input, module);
          case "integrationCountRequestParser":
            return this.hsbcIntegrationCountRequestParser(input);
        }
        break;
      }
      case "AIS": {
        switch (code) {
          case "SH":
            return this.allianceSalesHeadCriteriaResponseParser(input);
          case "RSM":
            return this.allianceRegSalesManagerCriteria(input);
          case "USM":
            return this.allianceUnitSalesManagerCriteria(input);
          case "SEN":
            return this.allianceSalesExecCriteria(input);
          case "integrationRequestParser":
            return this.allianceIntegrationRequestParser(input, module);
          case "integrationCountRequestParser":
            return this.allianceIntegrationCountRequestParser(input);
        }
        break;
      }
    }
  }

  static allianceSalesHeadCriteriaResponseParser(input) {
    let response = input.response;
    let result = response.result;
    let resultColumns = result.resultColumns || [];
    let data = [];
    for (let i = 0; i < resultColumns[0].list.length; i++) {
      let record = {};
      let key = resultColumns[0]["list"][i];
      let value = resultColumns[1]["list"][i];
      record = {
        code: key,
        name: value,
        status: false,
      };
      data.push(record);
    }
    let output = data;
    // output;
    return output;
  }

  static allianceRegSalesManagerCriteria(input) {
    let response = input.response;
    let result = response.result;
    let resultColumns = result.resultColumns || [];
    let data = [];
    for (let i = 0; i < resultColumns[0].list.length; i++) {
      let record = {};
      let key = resultColumns[0]["list"][i];
      let value = resultColumns[1]["list"][i];
      record = {
        code: key,
        name: value,
        status: true,
      };
      data.push(record);
    }
    let output = data;
    // output;
    return output;
  }

  static allianceUnitSalesManagerCriteria(input) {
    let response = input.response;
    let result = response.result;
    let resultColumns = result.resultColumns || [];
    let data = [];
    for (let i = 0; i < resultColumns[0].list.length; i++) {
      let record = {};
      let key = resultColumns[0]["list"][i];
      let value = resultColumns[1]["list"][i];
      record = {
        code: key,
        name: value,
        status: true,
      };
      data.push(record);
    }
    let output = data;
    // output;
    return output;
  }

  static allianceSalesExecCriteria(input) {
    let response = input.response;
    let result = response.result;
    let resultColumns = result.resultColumns || [];
    let data = [];
    for (let i = 0; i < resultColumns[0].list.length; i++) {
      let record = {};
      let key = resultColumns[0]["list"][i];
      let value = resultColumns[1]["list"][i];
      record = {
        code: key,
        name: value,
        status: true,
      };
      data.push(record);
    }
    let output = data;
    // output;
    return output;
  }

  static allianceIntegrationRequestParser(input, module) {
    let request = input.request;
    let filters = request.filters;
    let filtersAll = request.filtersAll;
    let salesHeads = filters["SH"];
    let regionalSalesManagers = filters["RSM"];
    let unitSalesManagers = filters["USM"];
    let salesExec = filters["SEN"];
    let statuses = request.statuses;
    let isAllSalesHead = filtersAll["SH"] == true;
    let isAllRegSalesManager = filtersAll["RSM"] == true;
    let isAllUnitSalesManager = filtersAll["USM"] == true;
    let isAllSales = filtersAll["SEN"] == true;
    let groupFilterUserType = 1;
    let groupFilterList = salesHeads;
    if (isAllRegSalesManager == false) {
      groupFilterUserType = 2;
      groupFilterList = regionalSalesManagers;
    }
    if (isAllUnitSalesManager == false) {
      groupFilterUserType = 3;
      groupFilterList = unitSalesManagers;
    }
    if (isAllSales == false) {
      groupFilterUserType = 4;
      groupFilterList = salesExec;
    }
    if (!groupFilterList) {
      groupFilterUserType = null;
    }
    let payload = {
      id: request.listId,
      pageNumber: request.page,
      criParams: {
        "proposal-dt-range": request.dateFilter,
        dtFrom: request.fromDate,
        dtTo: request.dateFilter == "range" ? request.toDate : null,
        "proposal-attr-search": request.searchBy,
        groupFilterUserType: groupFilterUserType,
        groupFilterUserIds: groupFilterList,
        searchBy: request.keyword,
        regions: [],
        branches: [],
        agents: [],
        statuses: statuses || [],
      },
      sortBys: [
        {
          fieldName: "pCoveredName",
          asc: true,
        },
      ],
    };
    let output = payload;
    // output;
    return output;
  }

  static allianceIntegrationCountRequestParser(input) {
    let request = input.request;
    let filters = request.filters;
    let filtersAll = request.filtersAll;
    let salesHeads = filters["SH"];
    let regionalSalesManagers = filters["RSM"];
    let unitSalesManagers = filters["USM"];
    let salesExec = filters["SEN"];
    let statuses = request.statuses;
    let isAllSalesHead = filtersAll["SH"] == true;
    let isAllRegSalesManager = filtersAll["RSM"] == true;
    let isAllUnitSalesManager = filtersAll["USM"] == true;
    let isAllSales = filtersAll["SEN"] == true;
    let groupFilterUserType = 1;
    let groupFilterList = salesHeads;
    if (isAllRegSalesManager == false) {
      groupFilterUserType = 2;
      groupFilterList = regionalSalesManagers;
    }
    if (isAllUnitSalesManager == false) {
      groupFilterUserType = 3;
      groupFilterList = unitSalesManagers;
    }
    if (isAllSales == false) {
      groupFilterUserType = 4;
      groupFilterList = salesExec;
    }
    if (!groupFilterList) {
      groupFilterUserType = null;
    }
    let payload = {
      id: request.listId,
      criParams: {
        "proposal-dt-range": request.dateFilter,
        dtFrom: request.fromDate,
        dtTo: request.dateFilter == "range" ? request.toDate : null,
        "proposal-attr-search": request.searchBy,
        groupFilterUserType: groupFilterUserType,
        groupFilterUserIds: groupFilterList,
        searchBy: request.keyword,
      },
    };
    let output = payload;
    // output;
    return output;
  }

  static hsbcRegionCriteriaResponseParser(input) {
    let response = input.response;
    let preSelect = input.preSelect || false;
    let result = response.result;
    let resultColumns = result.resultColumns || [];
    let data = [];
    for (let i = 0; i < resultColumns[0].list.length; i++) {
      let record = {};
      let key = resultColumns[0]["list"][i];
      let value = resultColumns[1]["list"][i];
      record = {
        code: key,
        name: value,
        status: preSelect,
      };
      data.push(record);
    }
    let output = data;
    // output;
    return output;
  }

  static hsbcBranchCriteriaResponseParser(input) {
    let response = input.response;
    let result = response.result;
    let resultColumns = result.resultColumns || [];
    let data = [];
    for (let i = 0; i < resultColumns[0].list.length; i++) {
      let record = {};
      let key = resultColumns[0]["list"][i];
      let value = resultColumns[1]["list"][i];
      record = {
        code: key,
        name: value,
        status: true,
      };
      data.push(record);
    }
    let output = data;
    // output;
    return output;
  }

  static hsbcSalesExecCriteriaResponseParser(input) {
    let response = input.response;
    let result = response.result || [];
    let data = [];
    for (let i = 0; i < result.length; i++) {
      let record = {};
      let key = result[i].id;
      if (!key || key.length <= 0) {
        continue;
      }
      let value = result[i].name;
      record = {
        code: key,
        name: value,
        status: true,
      };
      data.push(record);
    }
    let output = data;
    // output;
    return output;
  }

  static hsbcIntegrationRequestParser(input, module) {
    let request = input.request;
    let filters = request.filters;
    let filtersAll = request.filtersAll;
    let regions = filters["REG"];
    let branches = filters["BRH"];
    let salesExec = filters["SEN"];
    let statuses = request.statuses;
    let isAllRegions = filtersAll["REG"] == true;
    let isAllBranches = filtersAll["BRH"] == true;
    let isAllSales = filtersAll["SEN"] == true;
    let payload = {
      id: request.listId,
      pageNumber: request.page,
      criParams: {
        "proposal-dt-range": request.dateFilter,
        dtFrom: request.fromDate,
        dtTo: request.dateFilter == "range" ? request.toDate : null,
        "proposal-attr-search": request.searchBy,
        searchBy: request.keyword,
        regions: isAllRegions ? null : regions || [],
        branches: isAllBranches || !isAllSales ? null : branches || [],
        agents: isAllSales ? null : salesExec || [],
        statuses: statuses || [],
      },
      sortBys: [
        {
          fieldName:
            module == "claim"
              ? "pCoveredName"
              : module == "certificateCollection" ||
                module == "certificateCollection-upcoming"
              ? "pContributionDueDt"
              : "pPartName",
          asc: module == "certificateCollection" ? false : true,
        },
      ],
    };
    let output = payload;
    // output;
    return output;
  }

  static hsbcIntegrationCountRequestParser(input) {
    let request = input.request;
    let filters = request.filters;
    let filtersAll = request.filtersAll;
    let regions = filters["REG"];
    let branches = filters["BRH"];
    let salesExec = filters["SEN"];
    let statuses = request.statuses;
    let isAllRegions = filtersAll["REG"] == true;
    let isAllBranches = filtersAll["BRH"] == true;
    let isAllSales = filtersAll["SEN"] == true;
    let payload = {
      id: request.listId,
      criParams: {
        "proposal-dt-range": request.dateFilter,
        dtFrom: request.fromDate,
        dtTo: request.dateFilter == "range" ? request.toDate : null,
        "proposal-attr-search": request.searchBy,
        searchBy: request.keyword,
        regions: isAllRegions ? null : regions || [],
        branches: isAllBranches || !isAllSales ? null : branches || [],
        agents: isAllSales ? null : salesExec || [],
      },
    };
    let output = payload;
    // output;
    return output;
  }
}

export default JSScriptBloc;
