import React, { Component } from 'react';
import './app_filter_buttons.css'
import FilterButton from '../app_filter_button/app_filter_button';
import { notification } from 'antd';
import { Link, Redirect, withRouter } from "react-router-dom";
import Config from '../../../Config';
import GeneralBloc from '../../../bloc/general_bloc';
import App_dashboard_header from '../app_proposal_dashboard/app_dashboard_header';
import AuthBloc from '../../../bloc/auth_bloc';
import AnnouncementsBloc from '../../../bloc/announcements_bloc';
import moment from 'moment';
import { Space, Pagination } from "antd";
import { ReactComponent as LeftArrowIcon } from "../../../Arrow_Left.svg";
import { ReactComponent as RightArrowIcon } from "../../../Arrow_Right.svg";
import AppLoadingDialog from '../../dialog/app_loading_dialog';
import UtilBloc from '../../../bloc/util_bloc';
import AppEmptyData from '../app_empty_data/app_empty_data';

class AppFilterButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterButtonConfig: this.props.filterButtonConfig,
      selectedStatus: this.props.defaultSelected,
      notificationTemplate: this.props.notificationTemplate,
      findArrayNotificationTitle:this.props.findArrayNotificationTitle,
      findArrayNotificationDesc:this.props.findArrayNotificationDesc,
      notificationList: [
      ],
      clientCode: null,
      currentPage: 1,
      totalData: 0,
      pageSize: 10,
      loading: true
    };
  }
  //Get notificationTemplate
  stateNotificationTemplate() {
    return this.state.notificationTemplate;
  }

  stateFindArrayNotificationTitle(){
    return this.state.findArrayNotificationTitle
  }

  stateFindArrayNotificationDesc(){
    return this.state.findArrayNotificationDesc
  }

  //Get stateFilterButtonConfig
  stateFilterButtonConfig() {
    return this.state.filterButtonConfig
  }

  //renderFilterButtons status
  renderFilterButtons() {
    let filterCriterias = this.stateFilterButtonConfig();
    return filterCriterias.map((item, index) => {
      return <FilterButton
        label={item.label}
        count={item.count}
        selected={item.selected}
        onFilterChange={
          (status) => {
            this.setState({
              loading: true
            })
            this.checkedOrUncheckButton(!item.selected, index)
          }
        } />
    })
  }

  //checkedOrUncheckButton
  checkedOrUncheckButton(status, index) {
    let filterCriterias = this.stateFilterButtonConfig();
    let newFilterCriterias = [];
    filterCriterias.map((button, pos) => {
      if (index == pos) {
        button.selected = status;
      }
      newFilterCriterias.push(button);
    })

    this.setState({
      filterButtonConfig: newFilterCriterias,
      currentPage: 1
    }, () => {
      this.getSelectedStatus()
    })

  }

  //getSelectedStatus 
  getSelectedStatus() {
    let filterCriterias = this.stateFilterButtonConfig();
    let selectedStatus = [];
    filterCriterias.map((item, index) => {
      if (item.selected == true) {
        selectedStatus.push(item.code);
      }
    })
    //Save to localStorage 
    /* if (this.props.from == "dashboard") {
      localStorage.setItem("filterCriterias", JSON.stringify(filterCriterias))
    } */
    localStorage.setItem("filterCriterias", JSON.stringify(filterCriterias))
    this.setState({
      selectedStatus
    }, async () => {
      this.getNotificationData()
    })
    this.props.filterStatus(selectedStatus)
  }

  //getTitleDependOnTypeNotification
  getTitleDependOnTypeNotification(item) {
    let clientName = item.clientName?.trim() || "-";
    let eventOn = item.eventOn ? moment(item.eventOn).format("Do MMMM") : "-";
    let eventMoney = item.eventMoney==0.00 ? "XXX.XXX" : item.eventMoney;
    let prodName = item.prodName || "-";
    let clientCode = item.clientCode || null;
    let type = "";
    let title = "";
    let templateTitle="";
    if (item.eventOn) {
      type = UtilBloc.compareDateToCurrentDate(item.eventOn)
    }
    let notificationTemplate = this.stateNotificationTemplate()
    //item.type="POLICY_MATURE";
    //item.type="POLICY_PAID_UP";
    //Add more checking
    templateTitle=notificationTemplate[item.type][type].title;
    if((item.eventMoney==0.00 || item.eventMoney=="") && item.type=="POLICY_PREMIUM_DUE"){
      if(type=="today"){
        templateTitle=`Certificate collection for <b><span id=${clientCode}>${clientName}</span></b> under product <b>${prodName}</b> is Today!`;
        return templateTitle;
      }

      if(type=="incoming"){
        templateTitle=`Certificate collection for <b><span id=${clientCode}>${clientName}</span></b> under product <b>${prodName}</b> is <b>due soon on</b> ${eventOn}!`;
        return templateTitle;
      }

      if(type=="pass"){
        templateTitle=`Certificate collection for <b><span id=${clientCode}>${clientName}</span></b> under product <b>${prodName}</b> is <b>overdue on</b> ${eventOn}!`;
        return templateTitle;
      }
    }

    if((item.eventMoney==0.00 || item.eventMoney=="") && item.type=="POLICY_PAID_UP"){
      if(type=="today"){
        templateTitle=`Contribution <b>to be paid up</b> by Today for <b><span id=${clientCode}>${clientName}</span></b> under product <b>${prodName}</b>!`;
        return templateTitle;
      }

      if(type=="incoming"){
        templateTitle=`Contribution <b>to be paid up by</b> ${eventOn} for <b><span id=${clientCode}>${clientName}</span></b> under product <b>${prodName}</b>!`;
        return templateTitle;
      }

      if(type=="pass"){
        templateTitle=`Contribution <b>paid up by</b> ${eventOn} for <b><span id=${clientCode}>${clientName}</span></b> under product <b>${prodName}</b>!`;
        return templateTitle;
      }
    }
        
    title = UtilBloc.replaceBulk(templateTitle, this.stateFindArrayNotificationTitle(), [clientCode, clientName, prodName, eventMoney, eventOn]);
    
    return title
  }

  //getDescDependOnTypeNotification
  getDescDependOnTypeNotification(item) {
    let certNum = item.certNum || item.policies[0].certNum || "-";
    let clientMobile = item.clientMobile || "-";
    let clientEmail = item.clientEmail || "-";
    let eventMoney = item.eventMoney==0.00 || item.eventMoney==""? "XXX.XXX" : item.eventMoney;
    let recMap = item.recMap || item.policies[0].recMap ||  "";
    let eventOn = moment(item.eventOn).format("DD/MM/yyyy") || "-";
    let desc = "";
    let type = ""
    type = UtilBloc.compareDateToCurrentDate(item.eventOn)
    let notificationTemplate = this.stateNotificationTemplate()    
    if((item.eventMoney!=0.00 || item.eventMoney!="") && item.type=="POLICY_MATURE"){
      type="incoming";          
    } 
    
    desc = UtilBloc.replaceBulk(notificationTemplate[item.type][type].desc, this.stateFindArrayNotificationDesc(), [recMap, certNum, clientMobile, clientEmail, eventMoney, eventOn]);             
    return desc
  }

  getClientCodeFromIdSpan(e, clientName) {
    let clientCode = e.target.id;
    if (clientCode) {
      this.setState({
        clientCode,
        clientName,
        currentPage: 1,
        loading:true
      }, () => {
        this.getNotificationData()
      })
    }
  }

  getRecMapFromIdSpan(e,item) {
    let recMap = e.target.id;    
    if (recMap) {
      this.handleGetProposalDetail(recMap,item)
    }
  }

  handleGetProposalDetail(recMap,item) {
    let encodedRecMap = encodeURIComponent(recMap);    
    let type = ""
    type = UtilBloc.compareDateToCurrentDate(item.eventOn)
    let notificationTemplate = this.stateNotificationTemplate();
    let destination=Config.routes.generic.proposal;
    destination=notificationTemplate[item.type][type].destination;
    window.location.href = `/${Config.context}/${this.props.route.entity}/${destination}/${encodedRecMap}`
  }

  //getColorDependOnTypeNotification
  getStyleDependOnTypeNotification(item) {
    let style = {};
    //list, field(need to search) and value
    let status = UtilBloc.findItemInArray(this.stateFilterButtonConfig(), "code", item.type)
    if (status.length > 0) {
      style = status[0].style;
    }
    return style;
  }

  renderIndividualNotification(item, index) { 
    console.log("Item",item)   
    return <div className='d-flex align-items-center' style={{ gap: "10px" }}>
      <div className='notification_dot' style={this.getStyleDependOnTypeNotification(item)}>
        {index + 1}
      </div>
      <div>
        <div onClick={(e) => {
          /* if (this.props.from != "dashboard") {
            this.getClientCodeFromIdSpan(e, item.clientName)
          } */
            this.getClientCodeFromIdSpan(e, item.clientName)
        }} className='notification_title' dangerouslySetInnerHTML={{
          __html: this.getTitleDependOnTypeNotification(item)
        }} >

        </div>
        <div className='notification_desc' onClick={(e) => {          
          /* if (this.props.from != "dashboard") {
            this.getRecMapFromIdSpan(e,item)
          } */
            this.getRecMapFromIdSpan(e,item)
        }} dangerouslySetInnerHTML={{
          __html: this.getDescDependOnTypeNotification(item)
        }}>

        </div>
      </div>
    </div>
  }

  async getNotificationCount() {
    let notificationCount = await AnnouncementsBloc.getNotificationCount(this.state.clientCode);
    let filterButtonConfig = this.state.filterButtonConfig;
    let gotNotification = false;
    if (notificationCount) {
      filterButtonConfig.map((ev) => {
        if (notificationCount[ev.code] > 0) {
          gotNotification = true
        }
        ev.count = notificationCount[ev.code]
      })
      this.setState({
        notificationCount,
        gotNotification
      }, () => {
        this.props.gotNotification(gotNotification)
      })
    }    
  }

  async getNotificationList() {
    let payload = {
      "types": this.state.selectedStatus.length > 0 ? this.state.selectedStatus : [],
      "clientCode": this.state.clientCode,
      "page": this.state.currentPage,
      "pageSize": this.state.pageSize
    }

    if (this.state.selectedStatus.length == 0) {
      this.setState({
        notificationList: [],
        totalData: 0,
        loading: false
      })
    }
    let notificationList = await AnnouncementsBloc.getNotificationList(payload);
    //console.log("getNotificationList", notificationList)
    if (notificationList && this.state.selectedStatus.length > 0) {
      this.setState({
        notificationList: notificationList.records || [],
        totalData: notificationList.totalRecords,
        loading: false
      })
    }
  }

  async getNotificationData() {
    await this.getNotificationCount();
    await this.getNotificationList();
  }

  async componentDidMount() {
    let filterCriterias = JSON.parse(localStorage.getItem("filterCriterias"))
    if (filterCriterias) {
      this.setState({
        filterButtonConfig: filterCriterias
      }, () => {
        this.getSelectedStatus()
      })
    } else {
      this.getNotificationData()
    }
  }

  //Get current page
  stateCurrentPage() {
    return this.state.currentPage || 1;
  }

  //get Total Records from API
  stateTotalData() {
    return this.state.totalData || 0;
  }

  //handleShowIndexOfPages
  handleShowIndexOfPages() {
    let startItem = (this.stateCurrentPage() - 1) * 10 + 1;
    let endItem = startItem - 1 + this.state.notificationList.length;
    let record = {
      startItem: startItem,
      endItem: endItem,
    };
    return record;
  }

  handleChange(page) {
    this.setState({
      currentPage: page,
      loading: true
    }, () => {
      this.getNotificationData()
    })

  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  //backToHome
  backToHome() {
    let clientCode = this.state.clientCode
    if (clientCode) {
      this.setState({
        clientCode: null,
        currentPage: 1
      }, () => {
        this.getNotificationData();
      })
    } else {
      window.location.href = `/${Config.context}/${this.props.route.entity}/${Config.routes.generic.home}`
    }
  }

  zeroState(showImage) {
    return <div
      className={`w-full d-flex justify-content-center ${showImage == true ? "" : "empty_state_notification"}`}
      style={{
        marginTop: 63,

      }}
    >
      <div>
        {
          showImage && <img
            src={
              GeneralBloc.getAssetsContextPath() +
              `/assets/images/Illustration/Data not Found.svg`
            }
            alt="Data Not Found"
          />
        }

        <div
          style={{
            color: "black",
            fontWeight: 600,
            textAlign: "center",
            fontSize: 14,
            paddingTop: 12,
          }}
        >
          {"No data to be displayed."}
        </div>
        <div
          style={{
            color: "#9599B6",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {"You may change the filter to aid your search."}
        </div>
      </div>
    </div>
  }

  render() {
    return (
      /* this.state.gotNotification==true && */

      <div>
        {this.state.loading && this.loadingContent()}
        {
          this.props.from != "dashboard" ? <div style={{ marginTop: "-20px", marginBottom: "20px" }}>
            <div className="header-title">
              <div className="d-flex">
                <div className="mr-3 mt-1 header-title__icon">
                  <img
                    src={
                      GeneralBloc.getAssetsContextPath() +
                      `/assets/icons/Backward.svg`
                    }
                    alt="Backward Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.backToHome();
                    }}
                  />
                </div>
                <div className="">
                  <div className="header-title__title">Daily Reminders</div>
                </div>
              </div>
            </div>
          </div> :
            null
        }

        <div className="mb-4 d-flex flex-wrap" style={{ gap: "14px" }}>
          {this.stateFilterButtonConfig() ?
            this.renderFilterButtons() : null
          }
        </div>
        {/* Show descriptions depend on each status */}

        {
          this.state.notificationList.length > 0 && <>
            <div className='selected_button_description'
              dangerouslySetInnerHTML={{
                __html: this.state.clientCode ? `Showing results for <b>”${this.state.clientName ? this.state.clientName.trim() : ""}”</b>` : ""
              }}>

            </div>
            {
              this.props.from != "dashboard" ? null :
                <div className='d-flex justify-content-end' style={{ marginTop: "-36px" }}>
                  <Space className="dashboard-header d-flex w-100 justify-content-end align-items-center">
                    <div
                      className="d-flex align-items-center"
                    >
                      <Link
                        to={`/${Config.context}/${this.props.route.entity}/${Config.routes.generic.dailyReminder}`}
                        style={{
                          color: "black",
                        }}
                        className="d-flex align-items-center"
                      >
                        <div className="dashboard-header__view-more mr-3">View More</div>
                        <img
                          src={
                            GeneralBloc.getAssetsContextPath() +
                            `/assets/icons/ChevronRight.svg`
                          }
                          alt="Chervon Right Icon"
                        />
                      </Link>
                    </div>
                  </Space>
                </div>
            }
          </>
        }

        {/* Listing in here */}
        {
          this.state.notificationList && this.state.notificationList.length > 0 ? <div className='notifications_container mt-4'>
            {
              this.state.notificationList.map((notification, index) => {
                if (this.props.maxRecords && index < this.props.maxRecords) {
                  return (
                    <>
                      <div>
                        {
                          this.renderIndividualNotification(notification, index)
                        }
                      </div>
                      <hr style={{ backgroundColor: "#E0E0E0" }} />
                    </>
                  );
                }

                if (!this.props.maxRecords) {
                  return (
                    <>
                      <div>
                        {
                          this.renderIndividualNotification(notification, index)
                        }
                      </div>
                      <hr style={{ backgroundColor: "#E0E0E0" }} />
                    </>
                  );
                }
              })
            }
          </div>
            :
            this.zeroState(this.props.from != "dashboard" ? true : false)
        }

        <div>
          {this.state.notificationList && this.state.notificationList.length > 0 && this.props.from != "dashboard" ? (
            <div style={{ padding: 16 }}
              className="align-items-center d-flex justify-content-between">
              <div>
                Showing {this.handleShowIndexOfPages().startItem} -{" "}
                {this.handleShowIndexOfPages().endItem} of{" "}
                {this.stateTotalData()} items
              </div>
              <div className="d-flex align-items-center" style={{ marginRight: "-35px" }} >
                <div className="page_title_paggination">Page</div>
                <Pagination
                  current={this.stateCurrentPage()}
                  total={this.stateTotalData()}
                  onChange={(page) => {
                    this.handleChange(page)
                  }}
                  style={{ bottom: "0px" }}
                  nextIcon={<RightArrowIcon />}
                  prevIcon={<LeftArrowIcon />}
                  showLessItems
                  defaultPageSize={10}
                  size="default"
                />
                <div
                  className="page_total_paggination"
                >
                  of {Math.ceil(this.stateTotalData() / 10)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default AppFilterButtons;
