import { DatePicker, Space } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./app_filters.css";
import { ReactComponent as CalendarIcon } from "../../../Calendar.svg";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

class AppDateRangeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {    
  }

  onChange(value) {
    let dateRange = [];

    let dateFrom = value[0].format("YYYY-MM-DD");
    dateRange.push(dateFrom);

    let dateTo = value[1].format("YYYY-MM-DD");
    dateRange.push(dateTo);

    this.setState({
      dateRange: [moment(dateFrom, "YYYY-MM-DD"), moment(dateTo, "YYYY-MM-DD")],
    });
    this.props.onSelectedDateRange(dateRange);
    // this.setState({
    //   dateRange: [],
    // });
  }

  disabledDate(current) {
    let dateStart = moment().year(5);
    let dateEnd = moment().endOf("today");

    return !(dateStart.isSameOrBefore(current) && dateEnd.isAfter(current));
  }

  render() {    
    return (
      <>
        <Space
          direction="vertical"
          size={12}
          style={{ width: this.props.width || 228, height: 52 }}
        >
          <RangePicker
            value={
              this.props.isNotRange
                ? null
                : this.props.defaultValueFromDate
                ? [
                    moment(this.props.value[0], "YYYY-MM-DD"),
                    moment(this.props.value[1], "YYYY-MM-DD"),
                  ]
                : null
            }
            disabledDate={this.disabledDate}
            defaultValue={
              this.props.isNotRange
                ? null
                : this.props.defaultValueFromDate
                ? [
                    moment(this.props.defaultValueFromDate, "YYYY-MM-DD"),
                    moment(this.props.defaultValueToDate, "YYYY-MM-DD"),
                  ]
                : null
            }
            style={{ height: 52 }}
            size={"large"}
            onChange={this.onChange}
            format={["DD/MM/YYYY", "DD/MM/YY"]}
            disabled={this.props.disabled}
            suffixIcon={
              <CalendarIcon style={{ height: "14px", width: "14px" }} />
            }
            separator={"-"}
          />
        </Space>
      </>
    );
  }
}

export default withRouter(AppDateRangeFilter);
