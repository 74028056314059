import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_claim_status.css";

class AppClaimStatus extends Component {
  constructor(props) {
    super(props);
  }

  propIsLoading(){
    return this.props.loading || false;
  }

  render() {
    //console.log("Render status with item = " + JSON.stringify(this.props.data));
    return (
      <>
        <div className="claim-status">
          <div className="claim-status__title">
            {this.props.data.translation || this.props.data.title}
          </div>
          <div className="d-flex align-items-center">
            <img
              src={GeneralBloc.getImagePath(this.props.data.inactiveIcon)}
              alt={this.props.data.translation || this.props.title}
              className="mr-3"
              width="32px"
              height="32px"
            />
            <div className="claim-status__data-count">
              {this.props.data.count}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AppClaimStatus);
