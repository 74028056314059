import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/authReducer";
import filterReducer from "./reducers/filterReducer";
import searchByReducer from "./reducers/searchByReducer";
import userConfigReducer from "./reducers/userConfigReducer";
import userDataReducer from "./reducers/userDataReducer";
import dtOverallFilterReducer from "./reducers/dtOverallFilterReducer";
import dtUpcomingFilterReducer from "./reducers/dtUpcomingFilterReducer";
import dateFilterReducer from "./reducers/dateFilterReducer";
import filterDialogReducer from "./reducers/filterDialogReducer";
import overallListingReducer from "./reducers/overallListingReducer";
import upcomingListingReducer from "./reducers/upcomingListingReducer";

export const store = configureStore({
  reducer: {
    userConfig: userConfigReducer,
    userData: userDataReducer,
    user: userReducer,
    filter: filterReducer,
    searchBy: searchByReducer,
    dtOverallFilter: dtOverallFilterReducer,
    dtUpcomingFilter: dtUpcomingFilterReducer,
    dateFilter: dateFilterReducer,
    filterDialog: filterDialogReducer,
    overallListing: overallListingReducer,
    upcomingListing: upcomingListingReducer,
  },
});
