/* eslint-disable react/jsx-pascal-case */
import { Col, Collapse, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CertificateCollectionStatusDetailBloc from "../../../bloc/certificate-collection_status_detail_bloc";
import ProposalDetailBloc from "../../../bloc/proposal_detail_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import App_button2 from "../../component/app_button/app_button2";
import BankRepresentativeCard from "../../component/app_content_detail/bank_representative_card";
import CertificateDetailsCard from "../../component/app_content_detail/certificate_details_card";
import CertificateDetailsDateCard from "../../component/app_content_detail/certificate_details_date_card";
import ContributionDetailsCard from "../../component/app_content_detail/contribution_details_card";
import CustomerDetailsCard from "../../component/app_content_detail/customer_details_card";
import FundDetailsCard from "../../component/app_content_detail/fund_details_card";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import Base_screen from "../base/base_screen/base_screen";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import GeneralBloc from "../../../bloc/general_bloc";
import IssuedCasesDetailBloc from "../../../bloc/issued_cases_detail_bloc";

const { Panel } = Collapse;

class CertificateCollectionStatusDetailScreen extends Component {
  constructor(props) {
    const { host, hostname, href, origin, pathname, port, protocol, search } =
    window.location;
    let paths = pathname.split("/");
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      route: routeParams,
      itemId: routeParams.itemId,
      client: Config.context,
      userData: {},
      certificateCollectionStatus: [],
      paths: paths
    };
  }

  stateItemId() {
    return this.state.itemId;
  }

  stateModule() {
      return "certificateCollection";
  }

  stateDetailRoute() {
    return this.props.location.detailRoute || GeneralBloc.showCurrentModule(this.state.paths);
 }


  stateEntity() {
    return this.state.route.entity;
  }

  async componentDidMount() {}

  async fetchDetails() {
    this.setState({
      loading: true,
    });

    let userData = await CertificateCollectionStatusDetailBloc.getUserData(
      this.props.userGlobal.displayName.length > 0
        ? this.props.userGlobal.displayName
        : "",
      this.props.userGlobal.designationTitle.length
        ? this.props.userGlobal.designationTitle
        : ""
    );

    let propData =
      await CertificateCollectionStatusDetailBloc.getCertificateCollectionDetail(
        this.props.userGlobal.roles[0].entity,
        this.props.match.params.itemId
      );
    
      /* let fundData = await IssuedCasesDetailBloc.getFundDetail(
        this.props.userGlobal.roles[0].entity,
        this.props.match.params.itemId
      );
 */
    this.setState({
      loading: false,
      userData: userData,
      //fundData,
      certificateCollectionStatus: propData,
    });
    //console.log(this.state.certificateCollectionStatus);
  }

  renderCustomerDetails() {
    return this.state.certificateCollectionStatus.customerDetails.participant.map(
      (item) => {
        return (
          <div className="proposal__customer-detail-content">
            <div className="proposal__customer-detail-tag">
              Participant Date of Birth
            </div>
            <div>20/04/1980</div>
          </div>
        );
      }
    );
  }

  renderField(title, value) {
    <div className="proposal__customer-detail-content">
      <div className="proposal__customer-detail-tag">{title || ""}</div>
      <div>{value || ""}</div>
    </div>;
  }

  goSubmittedDocumentDetails() {
    let encodedRecMap = encodeURIComponent(this.stateItemId());
    return this.props.history.push(
      `/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.document}/submitted/${encodedRecMap}/${this.stateDetailRoute()}`
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    //console.log(this.state.certificateCollectionStatus.fundDetailsList);
    return (
      <>
        <Base_screen
          listener={() => {
            this.fetchDetails();
          }}
        >
          <div>
            {this.state.loading && this.loadingContent()}
            <App_header
              data={this.state.userData}
              route={this.state.route.entity}
            />
            <App_sidebar route={this.state.route.entity}>
              <div
                className="d-flex"
                style={{ marginRight: "30px", marginLeft: "10px" }}
              >
                <App_header_title_simple
                  menu={"menu2"}
                  title={
                    this.state.certificateCollectionStatus.customerDetails
                      ? this.state.certificateCollectionStatus.customerDetails
                          .participant.name.value
                      : ""
                  }
                  description={
                    "Certificate Number :  " +
                    (this.state.certificateCollectionStatus.certDetails
                      ? this.state.certificateCollectionStatus.certDetails
                          .certNum.value != null
                        ? this.state.certificateCollectionStatus.certDetails
                            .certNum.value
                        : ""
                      : "")
                  }
                />
                <App_button2
                  title={"View All Submitted Documents"}
                  onClickAction={() => this.goSubmittedDocumentDetails()}
                />
              </div>
              <div className="proposal">
                <div className="proposal__customer">
                  <Row>
                    <Col span={15}>
                      {this.state.certificateCollectionStatus
                        .customerDetails ? (
                        <CustomerDetailsCard
                          module={this.stateModule()}
                          entity={this.stateEntity()}
                          data={
                            this.state.certificateCollectionStatus
                              .customerDetails
                          }
                        />
                      ) : (
                        <div />
                      )}
                      {this.state.certificateCollectionStatus.certDetails ? (
                        <CertificateDetailsCard
                          module={this.stateModule()}
                          entity={this.stateEntity()}
                          data={
                            this.state.certificateCollectionStatus.certDetails
                          }
                        />
                      ) : (
                        <div />
                      )}
                      {/* {this.state.certificateCollectionStatus.beneficiaryDetailsList ? (
                      <BeneficiaryDetailsCard
                        data={
                          this.state.certificateCollectionStatus.beneficiaryDetailsList
                        }
                      />
                    ) : (
                      <div />
                    )} */}
                      {this.state.certificateCollectionStatus
                        .bankRepresentativeDetails &&
                      UserAccessBloc.showBankRepresentative(
                        this.stateModule()
                      ) ? (
                        <BankRepresentativeCard
                          module={this.stateModule()}
                          entity={this.stateEntity()}
                          data={
                            this.state.certificateCollectionStatus
                              .bankRepresentativeDetails
                          }
                        />
                      ) : (
                        <div />
                      )}
                    </Col>

                    <Col span={9}>
                      <Row>
                        <Col span={24}>
                          {this.state.certificateCollectionStatus
                            .contributionDetails ? (
                            <ContributionDetailsCard
                              module={this.stateModule()}
                              entity={this.stateEntity()}
                              data={
                                this.state.certificateCollectionStatus
                                  .contributionDetails
                              }
                            />
                          ) : (
                            <div />
                          )}
                          {this.state.certificateCollectionStatus
                            .certDateDetails ? (
                            <CertificateDetailsDateCard
                              module={this.stateModule()}
                              entity={this.stateEntity()}
                              data={
                                this.state.certificateCollectionStatus
                                  .certDateDetails
                              }
                            />
                          ) : (
                            <div />
                          )}
                          {this.state.certificateCollectionStatus && this.state.certificateCollectionStatus.fundDetailsList 
                             ? (
                            <FundDetailsCard
                              module={this.stateModule()}
                              entity={this.stateEntity()}
                              caption={
                                this.state.certificateCollectionStatus
                                  .fundDetailsCaption
                              }
                              disclaimer={
                                this.state.certificateCollectionStatus
                                  .fundValuationDate ?? null
                              }
                              data={
                                this.state.certificateCollectionStatus.fundDetailsList
                              }
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </App_sidebar>
          </div>
        </Base_screen>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps)(CertificateCollectionStatusDetailScreen)
);
