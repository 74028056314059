import { Component } from "react";
import AppHeaderBean from "../data/bean/app_header_bean";
import BusinessOpportunitiesWebService from "../service/business_opoortunities_web_service";
import AuthBloc from "./auth_bloc";

class BusinessOpportunitiesDetailBloc extends Component {
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getBusinessOpportunitiesDetail(entity, recMap) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response =
      await BusinessOpportunitiesWebService.getBusinessOpportunitiesDetail(
        token,
        entity,
        recMap
      );

    if (response.success) {
      result = response.data;
    }
    return result;
  }
}

export default BusinessOpportunitiesDetailBloc;
