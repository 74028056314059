import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { Modal, ModalBody, Button } from "reactstrap";
import { Input } from "antd";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_searching_dialog.css";
import { ReactComponent as ClearIcon } from "../../../Clear.svg";
import { ReactComponent as SearchIcon } from "../../../Search.svg";

const styleInput = {
  backgroundColor: "transparent",
  border: "0px solid transparent",
  borderBottom: "2px solid #808EA8",
  borderRadius: "0px",
  fontSize: "14px",
  color: "#183028",
  fontWeight: "600",
};

class AppSearchingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: this.props.selections || [],
      fullList: this.props.selections,
      countSelectedOptions: 0,
      isOpen: this.props.isOpen,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  propTitle() {
    return this.props.title;
  }
  propPlaceHolder() {
    return this.props.placeholder;
  }
  propSubTitle() {
    return this.props.subTitle;
  }
  propPositiveButton() {
    return this.props.positiveButton;
  }
  propNegativeButton() {
    return this.props.negativeButton;
  }

  propSelections() {
    return this.props.selections;
  }
  propMinKeywordLength() {
    return this.props.minKeywordLength || 3;
  }

  //States
  stateValue() {
    return this.state.value;
  }
  stateError() {
    return this.state.error;
  }
  stateExpanded() {
    return this.state.expanded;
  }
  stateKeyword() {
    return this.state.keyword;
  }
  stateFullList() {
    return this.state.fullList;
  }
  stateFilteredList() {
    return this.state.filteredList;
  }
  stateCountSelectedOptions() {
    return this.state.countSelectedOptions;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.selections !== prevProps.selections) {
      //Refresh
      this.setState({
        filteredList: this.props.selections || [],
        fullList: this.props.selections,
        countSelectedOptions: 0,
      });
    }

    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        isOpen: this.props.isOpen,
      });
    }
  }

  getCheckBoxStatus(status) {
    if (!status) {
      return (
        GeneralBloc.getAssetsContextPath() + "/assets/images/unchecked.png"
      );
    } else {
      return GeneralBloc.getAssetsContextPath() + "/assets/images/checked.png";
    }
  }

  selectAndDeselectAllOptions(status) {
    let newFilteredList = [];
    this.stateFilteredList().map((option) => {
      option.status = status;
      newFilteredList.push(option);
    });
    this.setState({
      filteredList: newFilteredList,
    });
  }

  selectAndDeselectOption(option) {
    let filteredList = this.stateFilteredList();
    let newFilteredList = [];
    filteredList.map((ev) => {
      if (option.code == ev.code) {
        ev.status = !option.status;
      }
      newFilteredList.push(ev);
    });
    this.setState({
      filteredList: newFilteredList,
    });
  }

  getFilteredSelections(keyword) {
    let fullList = this.stateFullList();

    //Do not perform search if keyword is null or less than 3 characters
    if (
      !keyword ||
      keyword == "" ||
      keyword.length < this.propMinKeywordLength()
    ) {
      this.setState({
        error: "",
        filteredList: fullList,
      });
      return fullList;
    } else {
      let filteredList = [];

      fullList.map((option) => {
        let optionKeyword = option.name;
        if (optionKeyword.search(new RegExp(keyword, "i")) >= 0) {
          filteredList.push(option);
        }
      });

      if (filteredList.length > 0) {
        this.setState({
          error: "",
          filteredList: filteredList,
        });
        return filteredList;
      } else {
        this.setState({
          error: "Not found",
          filteredList: filteredList,
        });
        return filteredList;
      }
    }
  }

  getTotalSelectedOptions() {
    let countSelectedOptions = 0;
    let filteredList = this.stateFilteredList();
    filteredList.map((ev) => {
      if (ev.status) {
        countSelectedOptions = countSelectedOptions + 1;
      }
    });
    return countSelectedOptions;
  }

  searchDropdownField() {
    return (
      <div style={{ display: "block", width: "100%" }}>
        <Input
          bordered={false}
          allowClear={{ clearIcon: <ClearIcon /> }}
          style={styleInput}
          defaultValue={this.stateKeyword() || ""}
          prefix={<SearchIcon />}
          onChange={(e) => {
            //setting new keyword based on latest search text
            this.setState({
              keyword: e.target.value,
            });
            this.getFilteredSelections(e.target.value);
          }}
          placeholder={this.propPlaceHolder()}
        />
        {this.props.selections.length > 0 ? (
          this.selectAnUnselect()
        ) : (
          <div className="searching_result_no_data">
            <p>No records found.</p>
          </div>
        )}

        <div className="searching_options_container mt-3">
          {this.stateFilteredList().map((option, index) => {
            return (
              <div
                className="searching_option"
                onClick={() => {
                  this.selectAndDeselectOption(option);
                }}
              >
                <div>
                  <img
                    src={this.getCheckBoxStatus(option.status)}
                    onClick={() => {}}
                  />
                </div>
                <div>{option.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  selectAnUnselect() {
    return (
      <div className="select_deselect_container">
        {this.stateKeyword() ? (
          <div className="searching_result_text" style={{ marginTop: "20px" }}>
            <span>{this.stateFilteredList().length}</span> result for{" "}
            <span>‘{this.stateKeyword()}’</span>
          </div>
        ) : (
          <div></div>
        )}
        <div className="d-flex" style={{ gap: "10px" }}>
          <div
            className="select_deselect"
            onClick={() => {
              this.selectAndDeselectAllOptions(true);
            }}
          >
            Select all
          </div>
          <div
            className="select_deselect"
            onClick={() => {
              this.selectAndDeselectAllOptions(false);
            }}
          >
            De-select all
          </div>
        </div>
      </div>
    );
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onCloseDialog();
      this.setState({
        isOpen: false,
      });
    }
  }

  render() {
    return (
      <Modal
        backdrop="static"
        centered
        style={{ maxWidth: "580px" }}
        isOpen={this.state.isOpen}
      >
        <ModalBody>
          <div
            className="row no-gutters notice_modal_content"
            ref={this.setWrapperRef}
          >
            <div
              className="col d-flex text-right close_button mb-4"
              style={{ marginTop: "-14px", justifyContent: "space-between" }}
            >
              <div className="homescreen__announcement-title">
                {this.propTitle()}
              </div>
              {/* <img
                src={
                  GeneralBloc.getAssetsContextPath() +
                  "/assets/images/Clear_Icon.svg"
                }
                className="dialog_close_button"
                onClick={() => this.props.onCloseDialog()}
              /> */}
            </div>
            <div className="col-md-12 text-left">
              {this.searchDropdownField()}
              <div
                className="d-flex mt-4 float-right"
                style={{
                  gap: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {parseInt(this.getTotalSelectedOptions()) > 0 ? (
                  <div className="searching_result_text">
                    <span>{this.getTotalSelectedOptions()} selected</span>
                  </div>
                ) : null}
                <Button
                  size="large"
                  style={{
                    backgroundColor: "#183028",
                    fontSize: "14px",
                    width: "64px",
                    height: "40px",
                    borderRadius: 0,
                  }}
                  disabled={
                    parseInt(this.getTotalSelectedOptions()) > 0 ? false : true
                  }
                  onClick={(e) =>
                    this.props.getSelectedOptions(this.stateFullList())
                  }
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default withRouter(AppSearchingDialog);
