import { Checkbox, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import AuthBloc from "../../../bloc/auth_bloc";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import { logOut, setAuth } from "../../../data/redux/reducers/authReducer";
import "./login_screen.css";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import UtilBloc from "../../../bloc/util_bloc";
import AppFieldError from "../../component/form/app_field_error/app_field_error";

class LoginScreenAdmin extends Component {
  constructor(props) {
    super(props);

    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      client: Config.context,
      route: routeParams,
      username: "",
      password: "",
      isLoggedIn: false,
      loading: false,
      passwordVisible: false,
      errorpassword: null,
      errorusername: null,
      errorgodModeforUser: null,
      validatedpassword: false,
      validatedusername: false,
      validatedgodModeforUser: false,
      formError: "",
      isHeadBanca: false,
      godMode: false,
      godModeforUser: "",
    };
  }

  stateDisableButton() {    
    return this.state.validatedpassword && this.state.validatedusername && this.state.validatedgodModeforUser;
  }

  changeHandler(e, name, errorName) {
    let error = null;
    error = UtilBloc.validateField(e.target.value, name);    
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      ["error" + errorName]: error,
      ["validated" + errorName]: UtilBloc.isNullOrEmpty(error) ? true : false,
      formError: null,
    });
  }

  setPasswordVisible() {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  }

  async loginHander(username, password, godModeforUser) {
    this.setState({
      loading: true,
    });

    let result = await AuthBloc.login(username, password, godModeforUser);
    if (result.success) {
      let data = result.data;

      if (data.availBanks) {
        let newData = result.data;
        if (data.availBanks.length > 0) {
          newData.roles[0].entity = result.data.availBanks[0]?.id;
          newData.roles[0].entityName = result.data.availBanks[0]?.name;
          data = newData;
        }
      }      
      this.props.setAuth(data);
      AuthBloc.setGodMode(true)
      let needChangePasswordAfterLogin = data.needChangePasswordAfterLogin;
      if (needChangePasswordAfterLogin) {
        window.location.href = `/${this.state.client}/change-password`;
        return;
      }

      await this.setState({
        ...this.state,
        isLoggedIn: true,
        loading: false,
        isHeadBanca: AuthBloc.isHeadOfBanca(data.roles[0].id),
      });
    } else {
      this.setState({
        ...this.state,
        isLoggedIn: false,
        formError: result.message,
        loading: false,
      });
    }
  }

  renderLoginPage() {
    return (
      <div className="login__screen">
        <div className="login__wrapper">
          {this.state.loading && this.loadingContent()}
          <div className="">
            <div className="login__container">
              <div className="login__logo-container">
                <img
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    `/assets/images/Logo/FWD Takaful Logo.svg`
                  }
                  alt="FWD Takaful"
                  width={145}
                  height={72}
                />
              </div>
              <div className="login__forms">
                <div className="login__header">Login</div>
                <div className="login__input">
                  <Input
                    style={{
                      height: "48px",
                    }}
                    prefix={
                      <img
                        src={
                          GeneralBloc.getAssetsContextPath() +
                          `/assets/icons/GodIcon.svg`
                        }
                        alt="Account"
                      />
                    }
                    placeholder="Agent ID / User ID"
                    // value={this.state.username}
                    value={this.state.username}
                    onChange={(e) => {
                      this.changeHandler(e, "username", "username");
                    }}
                    name="username"
                    maxLength={18}
                  />
                  <div className="mt-2 login_error">
                    <AppFieldError text={this.state.errorusername} />
                  </div>
                </div>
                <div className="login__input">
                  <Input.Password
                    style={{
                      height: "48px",
                    }}
                    prefix={
                      <img
                        src={
                          GeneralBloc.getAssetsContextPath() +
                          `/assets/icons/Password.svg`
                        }
                        alt="Password"
                      />
                    }
                    type="password"
                    placeholder="Password"
                    //className="login__input"
                    value={this.state.password}
                    defaultValue={""}
                    onChange={(e) => {
                      this.changeHandler(e, "password", "password");
                    }}
                    name="password"
                    visibilitytoggle={{
                      visible: this.state.passwordVisible,
                      onVisibleChange: this.setPasswordVisible,
                    }}
                    maxLength={18}
                  />
                  <div className="mt-2 login_error">
                    <AppFieldError text={this.state.errorpassword} />
                    {!this.state.errorpassword &&
                    !this.state.errorusername && !this.state.errorgodModeforUser &&
                    this.state.formError ? (
                      <div className="mt-2">
                        <AppFieldError text={this.state.formError} />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="login__input">
                  <Input
                    style={{
                      height: "48px",
                    }}
                    prefix={
                      <img
                        src={
                          GeneralBloc.getAssetsContextPath() +
                          `/assets/icons/Account.svg`
                        }
                        alt="Account"
                      />
                    }
                    placeholder="God Mode for User ID"
                    // value={this.state.username}
                    value={this.state.godModeforUser}
                    onChange={(e) => {
                      this.changeHandler(e, "godModeforUser", "godModeforUser");
                    }}
                    name="godModeforUser"
                    maxLength={18}
                  />
                  <div className="mt-2 login_error">
                    <AppFieldError text={this.state.errorgodModeforUser} />
                  </div>
                </div>

                <div
                  className="d-flex align-items-center"
                  style={{
                    marginBottom: "40px",
                  }}
                >
                  {/* <Checkbox className="px-3" />
                  <div className="login__checkbox-description">
                    Keep me logged in
                  </div> */}
                </div>

                <Button
                  disabled={!this.stateDisableButton()}
                  size="large"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#183028",
                    width: "100%",
                    borderRadius: 0,
                    color: "white",
                    marginBottom: "36px",
                  }}
                  onClick={() =>
                    this.loginHander(
                      this.state.username,
                      this.state.password,
                      this.state.godModeforUser
                    )
                  }
                >
                  Login
                </Button>

                <a
                  className="login__forgot-password d-flex justify-content-center"
                  href={`/${Config.context}/reset-password`}
                >
                  <u>Forgot Password?</u>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoggedIn ? (
          this.state.isHeadBanca ? (
            <Redirect
              to={`/${this.state.client}/${Config.routes.generic.account}`}
            />
          ) : (
            <Redirect
              to={`/${this.state.client}/${RouteBloc.contextByEntity(
                this.props.userGlobal.roles[0].entity
              )}/${Config.routes.generic.home}`}
            />
          )
        ) : (
          this.renderLoginPage()
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = { setAuth, logOut };

// export default withRouter(LoginScreen);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreenAdmin)
);
