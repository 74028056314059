import Config from "../Config";

export default class LanguageBloc {

  //Get user latest selected language
  static getSelectedLanguage() {
    let selectedLanguage = localStorage.getItem(Config.cache.languageKey);
    return selectedLanguage ? selectedLanguage : Config.locale.en;
  }

}