import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    
});

export const userConfigReducer = createSlice({
  name: "userConfig",
  initialState,
  reducers: {
    setUserConfig: (state, action) => {
      state.value = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = userConfigReducer;
export const { setUserConfig } = actions;

export default reducer;
