import Config from "../Config";
import RouteParams from "../data/bean/route/route_params";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";
import UtilBloc from "./util_bloc";

class RouteBloc {

  static saveSelectedBank(id,name){
    let bank={
      id:id,
      name:name
    }
    localStorage.setItem("selectedBank",JSON.stringify(bank));
  }

  static getSelectedBank(){
    return JSON.parse(localStorage.getItem("selectedBank"));
  }

  static contextByEntity(entity) {
    switch (entity) {
      case Config.entity.hsbc.code:
        return Config.entity.hsbc.route;
      case Config.entity.alliance.code:
        return Config.entity.alliance.route;
    }
    return "";
  }

  static availBanks(entity){
    let bankName=RouteBloc.getSelectedBank().name;
    let availBanks=null;    
    if(AuthBloc.getDataUser()){
      availBanks=AuthBloc.getDataUser().availBanks;
      if(UtilBloc.isNullOrEmpty(availBanks) && (bankName!=entity)){
        window.location.href = `/${Config.context}/${Config.routes.generic.login}`
      } else if(availBanks && availBanks.length >0) {
        let obj = availBanks.find(bank => bank.name === entity);
        if(UtilBloc.isNullOrEmpty(obj)){
          window.location.href = `/${Config.context}/${Config.routes.generic.login}`
        }
      }
    }
  }

  static getCodeByBankName(entity) {    
    let id="";
    let name="";
    //this.availBanks(entity)
    if(entity==Config.entity.hsbc.route){
      id=Config.entity.hsbc.code;
      name=entity;      
      RouteBloc.saveSelectedBank(id,name)
    }
    if(entity==Config.entity.alliance.route){      
      id=Config.entity.alliance.code;
      name=entity;
      RouteBloc.saveSelectedBank(id,name)
    }
    return id;
  }

  //Extracting important session info from URL path
  static extractRouteParamsFromProps(props) {
    const urlParams = new URLSearchParams(props.location.search);    
    let entity = props.match.params.entity;
    let itemId = props.match.params.itemId;
    let docClass = props.match.params.docClass;
    let routeParams = new RouteParams({
      entity: entity,
      itemId: itemId,
      docClass: docClass,
    });
    return routeParams;
  }
}

export default RouteBloc;
