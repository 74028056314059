import React from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import { Document, Page } from "react-pdf";
import AllPagesPDFViewer from "../pdf/allpages_pdfviewer";
import GeneralBloc from "../../../bloc/general_bloc";
import "./terms_and_condition_dialog.css";
import AppGroupButtonDialog from "../app_group_button_dialog/app_group_button_dialog";

class TermsAndConditionDialog extends React.Component {
  //props:title, description, buttonLabel
  constructor(props) {
    super(props);
    this.state = {
      mobilesize: false,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  updateDimensions = () => {
    //console.log('TNC Dialog : Inner width = ' + window.innerWidth);
    if (window.innerWidth <= 425) {
      this.setState({ mobilesize: true });
    } else {
      this.setState({ mobilesize: false });
    }
  };

  closeDialog = () => {
    this.props.onDialogClose();
  };

  viewPDF() {
    return <div className="col-md-12 text-left" style={{ height: '100%' }}>
      {this.props.showClose == true && (
        <div style={{ "text-align": "right" }}>
          <div>
            <img
              src={
                GeneralBloc.getAssetsContextPath() +
                "/assets/images/Clear_Icon.svg"
              }
              className="terms_and_condition_close_button"
              onClick={() => this.closeDialog()}
            />
          </div>
        </div>
      )}
      <div className="pdf_viewer_container noselect" style={{ overflowY: 'scroll', height: '100%' }}>
        <div className="noselect" style={{ height: '100%' }}>
          <AllPagesPDFViewer pdf={this.props.content} mobile={this.state.mobilesize} width={window.innerWidth} height={window.innerHeight} />
        </div>
      </div>
      {this.props.showDownload === true && (
        <AppGroupButtonDialog
          pdf={this.props.content}
          name={this.props.name}
          type={this.props.type}
          pdsBlobData={this.props.pdsBlobData}
        />
      )}
    </div>
  }

  viewImage() {
    return <div className="col-md-12 text-left">
      {this.props.showClose == true && (
        <div style={{ "text-align": "right" }}>
          <div>
            <img
              src={
                GeneralBloc.getAssetsContextPath() +
                "/assets/images/Clear_Icon.svg"
              }
              className="terms_and_condition_close_button"
              onClick={() => this.closeDialog()}
            />
          </div>
        </div>
      )}
      <div className="w-100">
        <img className="img-fluid" style={{height:"480px",width:"800px",objectFit:"cover",maxHeight:"480px"}}     
          src={this.props.content}
        />
      </div>
      
    </div>
  }

  render() {
    const noPadding = {
      padding: "0px",
      margin: "0px",
      maxHeight: "531px",
    };

    const modalHeight = {
      height: this.state.mobilesize ? "100%" : this.props.type=="pdf" ? "90vh" :"80vh"
    };

    var btnLabel = this.props.buttonLabel;

    return (
      <Modal
        className="modal-termcondition"
        backdrop="static"
        size="lg"
        style={{ width: "100%" }}
        // style={{ maxWidth: "1300px", width: "80%" }}
        centered
        isOpen={this.props.isOpen}
      >
        <ModalBody style={modalHeight}>
          <div className="row terms-modal-content">
            {
              this.props.type == "pdf" ? this.viewPDF() : this.viewImage()
            }
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default withRouter(TermsAndConditionDialog);
