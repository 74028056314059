import React from "react";
import UserAccessBloc from "../../../../bloc/user_access_bloc";
import AppLoadingDialog from "../../../dialog/app_loading_dialog";

import { connect } from "react-redux";
import { setAuth, logOut } from "../../../../data/redux/reducers/authReducer";
import { setFilterBy } from "../../../../data/redux/reducers/filterReducer";
import { setSearchBy } from "../../../../data/redux/reducers/searchByReducer";
import { setUserConfig } from "../../../../data/redux/reducers/userConfigReducer";
import { setUserData } from "../../../../data/redux/reducers/userDataReducer";
import { setDTOverallFilter } from "../../../../data/redux/reducers/dtOverallFilterReducer";
import { setDTUpcomingFilter } from "../../../../data/redux/reducers/dtUpcomingFilterReducer";
import GeneralBloc from "../../../../bloc/general_bloc";
import HomeBloc from "../../../../bloc/home_bloc";
import { withRouter } from "react-router-dom";
import Splash_screen from "./splash_screen";
import RouteBloc from "../../../../bloc/route_bloc";
import Config from "../../../../Config";
import Base_empty_screen from "../base_empty_screen";

class BaseScreen extends React.Component {
  constructor(props) {
    super(props);
    let module = props.module;
    let entity = props.entity;
    let listener = props.listener;
    let loading = props.loading;
    let checkAccess = props.checkAccess || true;
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      module,
      entity,
      checkAccess: checkAccess,
      isCompleted: false,
      route: routeParams,
    };
  }

  allLoadItemsCompleted() {
    return this.state.isCompleted;
  }
  stateModule() {
    return this.state.module;
  }
  propLoading() {
    return this.props.loading || false;
  }

  async componentDidMount() {
    await RouteBloc.getCodeByBankName(this.state.entity);
    await this.initLoadingItems();
  }

  //check access of entity
  checkAccessEntity() {
    let entity = this.state.route.entity;
    let userData = UserAccessBloc.getUserData();

    if (!userData) return false;
    let roles = userData.roles || [];
    let hasAccessToEnt = false;

    for (var i = 0; i < roles.length; i++) {
      let role = roles[i];
      let roleEntity = role.entity;
      let accessingEntity = Config.entityMapping[entity];
      let matchEnt = false;
      if (roleEntity == "FWD") {
        matchEnt = true;
      } else {
        matchEnt = accessingEntity == roleEntity;
      }
      hasAccessToEnt = hasAccessToEnt || matchEnt;
    }

    //console.log('Has access to ent = ' + entity + ' = ' + hasAccessToEnt);
    this.setState({
      hasAccess: hasAccessToEnt,
    });
    return hasAccessToEnt;
  }

  //Init the items to load
  initLoadingItems() {
    let module = this.generateModule();
    this.loadCompleteListener(module);
  }
  generateModule() {
    let id = "";
    if (this.props.module == "claim") {
      id = "df-master-config-claim-attr-search";
    } else {
      id = "df-master-config-attr-search";
    }

    return id;
  }

  async loadCompleteListener(module) {
    let userSettings = this.loadUserSettings();
    let filterSettings = this.loadDateRangeOverallFilter();
    let searchSettings = this.loadSearchBys(module);
    let userDataSettings = this.loadUserData();
    let userAuthData = this.loadAuthData();
    this.checkAccessEntity();

    // let dtUpcomingFilterSettings = this.loadDateRangeUpcomingFilter();
    // let dtOverallFilterSettings = this.loadDateRangeOverallFilter();

    await userSettings;
    await filterSettings;
    await searchSettings;
    await userDataSettings;
    await userAuthData;
    // await dtUpcomingFilterSettings;
    // await dtOverallFilterSettings;

    if (this.props.listener) {
      this.props.listener();
    }

    this.setState({
      isCompleted: true,
    });
  }
  //Redirect to login screen if error 401 from api to get frontend config
  redirectToLogin() {
    window.location.href = `/${Config.context}/${Config.routes.generic.login}`;
  }

  //Get user settings
  async loadUserSettings() {
    UserAccessBloc.getUserSetting()
      .then((userSettings) => {
        if (userSettings) {
          this.props.setUserConfig(userSettings);
        }
      })
      .catch((e) => {
        this.redirectToLogin();
      });
  }

  async loadUserData() {
    let userData = UserAccessBloc.getUserData();

    let name = userData ? userData.displayName : "";
    let role = userData ? userData.designationTitle : "";
    let branch = "";

    let userDataBean = HomeBloc.getUserData(name, role, branch);

    this.props.setUserData({
      data: userDataBean,
    });
  }

  async loadAuthData() {
    let userData = UserAccessBloc.getUserData();
    if (userData) {
      this.props.setAuth(userData);
    }
  }

  async loadDateRangeOverallFilter() {
    let result = await GeneralBloc.getDateRangeFilter();
    if (result) {
      this.props.setFilterBy(result.data);
      this.props.setDTOverallFilter(result.data);
    }
  }

  async loadDateRangeUpcomingFilter() {
    let result = await GeneralBloc.getDateRangeUpcomingFilter();
    if (result) {
      this.props.setDTUpcomingFilter(result.data);
    }
  }

  async loadSearchBys(id) {
    let result = await GeneralBloc.getSearchBys(id);
    if (result) {
      this.props.setSearchBy(result.data);
    }
  }

  //The loading state for the screen
  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {/* <AppLoadingDialog isLoading={true} /> */}
        <Splash_screen />
      </div>
    );
  }

  //Error content
  errorContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Base_empty_screen />
      </div>
    );
  }

  render() {
    if (this.propLoading() || !this.allLoadItemsCompleted()) {
      return <div>{this.loadingContent()}</div>;
    }
    if (this.state.hasAccess == false && this.state.checkAccess) {
      return this.errorContent();
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  userConfigGlobal: state.userConfig.value,
  userDataGlobal: state.userData.value,
  userGlobal: state.user.value,
  globalFilterBy: state.filter.value,
  globalSearchBy: state.searchBy.value,
  dtUpcomingFilterGlobal: state.dtOverallFilter.value,
  dtOverallFilterGlobal: state.dtUpcomingFilter.value,
});

const mapDispatchToProps = {
  setAuth,
  logOut,
  setFilterBy,
  setSearchBy,
  setUserConfig,
  setUserData,
  setDTOverallFilter,
  setDTUpcomingFilter,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseScreen)
);
