import { Collapse } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import App_sidebar from "../../component/app_sidebar/app_sidebar";

import { ReactComponent as ArrowIcon } from "../../../Arrow_Down.svg";

import "./proposal_outstanding_screen.css";

const { Panel } = Collapse;
const text = <p style={{ paddingLeft: 24 }}>Lorem ipsum</p>;

const title = (
  <div className="proposal-outstanding__customer-detail-title pl-4">
    Submitted Documents
  </div>
);

class ProposalOutstandingScreen extends Component {
  render() {
    return (
      <>
        <App_header />
        <App_sidebar>
          <App_header_title_simple
            menu={"menu2"}
            title={"All Submitted Documents"}
          />
          <div className="proposal-outstanding">
            <div className="proposal-outstanding">
              <Row>
                <Col span={12}>
                  <div className="proposal-outstanding__customer-detail">
                    <Row>
                      <Col span={12}>
                        <Collapse
                          bordered={false}
                          defaultActiveKey={["1"]}
                          expandIconPosition={"right"}
                          //   expandIcon={ArrowIcon}
                        >
                          <Panel header={title} key="1">
                            {text}
                          </Panel>
                        </Collapse>
                      </Col>

                      {/* <div span="12" class="col">
                          <div className="ant-collapse ant-collapse-icon-position-start ant-collapse-borderless ml-0">
                            <div className="ant-collapse-item ant-collapse-item-active">
                              <div
                                className="ant-collapse-header"
                                aria-expanded="true"
                                aria-disabled="false"
                                role="button"
                                tabindex="0"
                              >
                                <div className="ant-collapse-expand-icon">
                                  <span
                                    role="img"
                                    aria-label="right"
                                    className="anticon anticon-right ant-collapse-arrow"
                                  >
                                    <svg
                                      viewBox="64 64 896 896"
                                      focusable="false"
                                      data-icon="right"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                      style={{ transform: "rotate(90deg)" }}
                                    >
                                      <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                    </svg>
                                  </span>
                                </div>
                                <span className="ant-collapse-header-text">
                                  <div className="proposal-outstanding__customer-detail-title">
                                    Submitted Documents
                                  </div>
                                </span>
                              </div>
                              <div
                                className="ant-collapse-content ant-collapse-content-active"
                                style={{}}
                              >
                                <div className="ant-collapse-content-box">
                                  <p style={{ paddingLeft: "24px" }}>
                                    A dog is a type of domesticated animal.
                                    Known for its loyalty and faithfulness, it
                                    can be found as a welcome guest in many
                                    households across the world.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                    </Row>
                    {/* <Row>
                      <Col span={12}>
                        <div className="proposal-outstanding__customer-detail-content">
                          <div className="proposal-outstanding__customer-detail-tag">
                            Participant Name
                          </div>
                          <div>Agustine Suwandi</div>
                        </div>
                        <div className="proposal-outstanding__customer-detail-content">
                          <div className="proposal-outstanding__customer-detail-tag">
                            Participant Date of Birth
                          </div>
                          <div>Agustine Suwandi</div>
                        </div>
                        <div className="proposal-outstanding__customer-detail-content">
                          <di
                            className="proposal-outstanding__customer-detail-tag"
                            v
                          >
                            Mobile Number
                          </di>
                          <div>Agustine Suwandi</div>
                        </div>
                        <div className="proposal-outstanding__customer-detail-content">
                          <div className="proposal-outstanding__customer-detail-tag">
                            Covered Name
                          </div>
                          <div>Agustine Suwandi</div>
                        </div>
                        <div className="proposal-outstanding__customer-detail-content">
                          <div className="proposal-outstanding__customer-detail-tag">
                            Covered Person Date of Birth
                          </div>
                          <div>Agustine Suwandi</div>
                        </div>
                      </Col>
                    </Row> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </App_sidebar>
      </>
    );
  }
}

export default withRouter(ProposalOutstandingScreen);
