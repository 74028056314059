import React from "react";
import UtilBloc from "../../../../bloc/util_bloc";

class AppFieldError extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  //Available properties
  propText() {
    return this.props.text;
  }

  componentDidMount() {}

  render() {
    return (
      <div
        style={{
          fontSize: "12px",
          color: "#CC3333",
          marginTop: "4px",
        }}
      >
        {!UtilBloc.isNullOrEmpty(this.propText()) ? this.propText() : null}
      </div>
    );
  }
}

export default AppFieldError;
