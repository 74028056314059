import { data } from "jquery";
import React, { Component } from "react";
import AppClaimStatusBean from "../data/bean/app_claim_status_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import IssuedCasesWebService from "../service/issued_cases_web_service";
import ProposalWebServices from "../service/proposal_web_services";
import HomeWebService from "../service/home_web_service";
import AppTableStatus from "../ui/component/app_table_status/app_table_status";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";
import DataResult from "../data/bean/data_result";

class ProposalBloc extends Component {
  static async getProposalStatuses(
    entity,
    module,
    moduleId,
    dateFilter,
    fromDate,
    toDate,
    searchBy,
    keyword,
    regions,
    branches,
    agents,
    userConfigGlobal
  ) {
    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let payload={
      "proposal-dt-range": dateFilter,
      dtFrom: getDateFilter.beginDate,
      dtTo: getDateFilter.endDate,
      "proposal-attr-search": searchBy,
      searchBy: keyword,
      regions: regions,
      branches: branches,
      agents: agents,
    }

    if(dateFilter!="range"){
      delete payload.dtFrom;
      delete payload.dtTo
    }

    let response = await GeneralBloc.getStatusBox(
      module,
      moduleId,
      false,
      false,
      payload,
      userConfigGlobal
    );
    return response;
  }

  static async downloadFile(module, id) {
    let resp = await HomeWebService.downloadFile(module, id);
    if (resp.data) {
      return new DataResult(true, resp.message, resp);
    } else {
      return new DataResult(false, resp.message, null);
    }
  }

  static async getSubmittedDocuments(docClass, recMap) {
    let resp = await ProposalWebServices.getSubmittedDocuments(
      docClass,
      recMap
    );
    if (resp.success) {
      return new DataResult(true, resp.message, resp.data);
    } else {
      return new DataResult(false, resp.message, null);
    }
  }

  static getCollumnTable() {
    return [
      {
        title: "Certificate Number",
        dataIndex: "certificateNumber",

        // specify the condition of filtering result
        // here is that finding the name started with `value`
      },
      {
        title: "Product Name",
        dataIndex: "productName",
      },
      {
        title: "Participant Name",
        dataIndex: "participantName",
      },
      {
        title: "Participant ID",
        dataIndex: "participantID",
      },
      {
        title: "Participant Mobile Number",
        dataIndex: "mobileNo",
      },
      {
        title: "Application Status",
        dataIndex: "applicationStatus",
      },
      {
        title: "Proposal Submission Date",
        dataIndex: "proposalSubmissionDate",
      },
      {
        title: "Aging Days",
        dataIndex: "agingDays",
      },
    ];
  }
  static getDummyProposalDataTable() {
    return [
      {
        key: "1",
        certificateNumber: "1222233330001",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Outstanding" status={"blue"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "2",
        certificateNumber: "1222233330002",
        productName: 42,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Pending" status={"yellow"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "3",
        certificateNumber: "1222233330003",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Pending" status={"yellow"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "4",
        certificateNumber: "1222233330004",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Outstanding" status={"blue"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "5",
        certificateNumber: "1222233330005",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Pending" status={"yellow"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "6",
        certificateNumber: "1222233330006",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Outstanding" status={"blue"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "7",
        certificateNumber: "1222233330007",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Pending" status={"yellow"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "8",
        certificateNumber: "1222233330008",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Outstanding" status={"blue"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "9",
        certificateNumber: "1222233330009",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Outstanding" status={"blue"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "10",
        certificateNumber: "1222233330010",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Outstanding" status={"blue"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "11",
        certificateNumber: "1222233330011",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Pending" status={"yellow"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
      {
        key: "12",
        certificateNumber: "1222233330012",
        productName: 32,
        participantName: "Nurul Husna binti Abdullah",
        participantID: 50783259,
        mobileNo: "012564898",
        applicationStatus: (
          <AppTableStatus statusName="Outstanding" status={"blue"} />
        ),
        proposalSubmissionDate: "25/04/2022",
        agingDays: "100 days left",
      },
    ];
  }
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getProposalDataTable(
    entity,
    dateFilter,
    fromDate,
    toDate,
    regions,
    page,
    searchBy,
    keyword,
    branches,
    agents,
    statuses
  ) {
    let dataProposal = [];
    let collumnTable = [];
    let totalData = 0;

    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let getDateFilter = await GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let response = await ProposalWebServices.getProposal(
      token,
      entity,
      page,
      fromDate,
      toDate,
      regions,
      searchBy,
      keyword,
      branches,
      agents,
      statuses,
      dateFilter
    );

    let dataSet = {};
    if (response.success) {
      dataSet = await GeneralBloc.getListingTable(response, "proposal");
    }    
    let result = {
      dataCollumn: dataSet.columnTable || [],
      dataProposal: dataSet.data || [],
      totalData: dataSet.total || [],
    };
    return result;
  }
  static async getProposalDetail(entity, recMap) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response = await ProposalWebServices.getProposalDetail(
      token,
      entity,
      recMap
    );

    if (response.success) {
      result = response.data;
    }
    return result;
  }
}

export default ProposalBloc;
