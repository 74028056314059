import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_claim_status.css";

class AppClaimStatusMiniSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
    };
  }
 
  render() {
    return (
      <>
        <div
          className={`claim-status-mini-skeleton ${this.props.data.type}`}
          style={{
            background: "#F0F0F0"
          }}
        >
          <div className="claim-status-mini__image">
            <div
              height={32}
              width={32}
              className=""
            />
          </div>
          <div className="claim-status-mini-skeleton__data-count-skeleton">
            {this.props.data.count}
          </div>
          <div className="claim-status__title-skeleton">{this.props.data.title}</div>
        </div>
      </>
    );
  }
}

export default withRouter(AppClaimStatusMiniSkeleton);
