import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    
});

export const userDataReducer = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.value = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = userDataReducer;
export const { setUserData } = actions;

export default reducer;
