import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Input } from "antd";
import GeneralBloc from "../../../bloc/general_bloc";
import { Button } from "reactstrap";

class AppSearchFilter extends Component {
  constructor(props) {
    super(props);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onClickSearch() {
    this.props.onClickSearch();
  }

  onChange(keyword) {
    this.props.onChangeKeyword(keyword.target.value);
  }

  render() {
    return (
      <>
        <div className="d-flex w-100">
          <Input
            prefix={
              <img
                src={
                  GeneralBloc.getAssetsContextPath() +
                  `/assets/icons/Search.svg`
                }
                alt="Search"
                className="mr-2"
              />
            }
            size="large"
            className={this.props.className || "mr-3"}
            placeholder={this.props.title}
            // disabled={this.props.isDisabledSearchFilter}
            style={{ backgroundColor: "white", height: 52 }}
            onChange={this.onChange}
            defaultValue={this.props.value}
            value={this.props.value}
          />
          <Button
            size="large"
            style={{
              backgroundColor: "#183028",
              width: "216px",
              borderRadius: 0,
            }}
            disabled={this.props.isValid}
            onClick={this.onClickSearch}
          >
            Search
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(AppSearchFilter);
