import axios from "axios";
import React, { Component } from "react";
import BaseWebService from "./base_web_service";

class HomeWebService extends Component {

  static async getNotificationCount(token,clientCode) {
    let headers = await BaseWebService.standardHeaders();
    if(!clientCode){
      clientCode=""
    }
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/push/todayPushNotiCount`,
      {
        "clientCode":clientCode
      },
      {
        headers: headers,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getNotificationList(token,payload) {
    let headers = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/push/todayPushNotiList`,
      payload,
      {
        headers: headers,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getAnnouncementBanner(token, entity, page) {
    let headers = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: "announcement-banner",
        pageNumber: page,
        criParams: null,
        sortBys: [
          {
            "fieldName": "ann.announcement_id",
            "asc": "true",
          }
        ],
      },
      {
        headers: headers,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getAnnouncementDetails(token, entity,id) {    
    let headers = await BaseWebService.standardHeaders();
    let resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/announcement/`+id,      
      {
        headers: headers
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async downloadFile(module,id) {
    let headers = await BaseWebService.headers();
    let resp = await axios.get(
      BaseWebService.baseUrl()+ `/download/${module}/${id}`,
      {
        responseType: 'blob',
        headers: headers,
      }
    );        
    return resp;
  }

  static getAnnouncementImageUrl(token, module, id){
    return `${process.env.REACT_APP_BASE_URL}/v1/download/${module}/${id}`;
  }

  static async getAnnouncementImage(token, module, id) {
    let resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/download/${module}/${id}`,
      {
        headers: BaseWebService.headers(),
      }
    );    
    return resp;
  }

  static async getProposalCount(token, entity, fromDate, toDate) {
    let headers = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: `df-proposal-count-${entity}`,

        criParams: {
          "proposal-dt-range": "range",
          dtFrom: fromDate,
          dtTo: toDate,
        },
        sortBys: null,
      },
      {
        headers: headers,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getIssuedCaseCount(token, entity, fromDate, toDate) {
    let headers = await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
      {
        id: `df-issued-case-count-${entity}`,

        criParams: {
          "proposal-dt-range": "range",
          dtFrom: fromDate,
          dtTo: toDate,
        },
        sortBys: null,
      },
      {
        headers: headers,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
}

export default HomeWebService;
