/* eslint-disable react/jsx-pascal-case */
import { Col, Collapse, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IssuedCasesDetailBloc from "../../../bloc/issued_cases_detail_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import AppLoadingDialog from "../../../ui/dialog/app_loading_dialog";
import App_button2 from "../../component/app_button/app_button2";
import BankRepresentativeCard from "../../component/app_content_detail/bank_representative_card";
import CertificateDetailsCard from "../../component/app_content_detail/certificate_details_card";
import CertificateDetailsDateCard from "../../component/app_content_detail/certificate_details_date_card";
import ContributionDetailsCard from "../../component/app_content_detail/contribution_details_card";
import CustomerDetailsCard from "../../component/app_content_detail/customer_details_card";
import FundDetailsCard from "../../component/app_content_detail/fund_details_card";
import BeneficiaryDetailsCard from "../../component/app_content_detail/beneficiary_details_card";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import Base_screen from "../base/base_screen/base_screen";
import LetterOfAcceptance from "../../component/app_content_detail/letter_of_acceptance";
import AuthBloc from "../../../bloc/auth_bloc";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import GeneralBloc from "../../../bloc/general_bloc";

const { Panel } = Collapse;

class IssuedCasesDetail extends Component {
  constructor(props) {
    const { host, hostname, href, origin, pathname, port, protocol, search } =
    window.location;
    let paths = pathname.split("/");
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      route: routeParams,
      itemId: routeParams.itemId,
      client: Config.context,
      userData: {},
      issuedCasesDetail: [],
      paths: paths,
    };
  }

  stateItemId() {
    return this.state.itemId;
  }

  stateModule() {
     return "issuedCase";
  }

  stateDetailRoute() {
    return this.props.location.detailRoute || GeneralBloc.showCurrentModule(this.state.paths);
 }

  stateEntity() {
    return this.state.route.entity;
  }

  async componentDidMount() {}

  async fetchDetails() {
    this.setState({
      loading: true,
    });

    let userData = await IssuedCasesDetailBloc.getUserData(
      this.props.userGlobal.displayName.length > 0
        ? this.props.userGlobal.displayName
        : "",
      this.props.userGlobal.designationTitle.length
        ? this.props.userGlobal.designationTitle
        : ""
    );

    let propData = await IssuedCasesDetailBloc.getIssuedCaseDetail(
      this.props.userGlobal.roles[0].entity,
      this.props.match.params.itemId
    );

    /* let fundData = await IssuedCasesDetailBloc.getFundDetail(
      this.props.userGlobal.roles[0].entity,
      this.props.match.params.itemId
    ); */
    //console.log("propDatadcdda", fundData);
    this.setState({
      loading: false,
      userData: userData,
      issuedCasesDetail: propData,
      //fundData
    });
    //console.log(this.state.issuedCasesDetail);
  }

  renderCustomerDetails() {
    return this.state.issuedCasesDetail.customerDetails.participant.map(
      (item) => {
        return (
          <div className="proposal__customer-detail-content">
            <div className="proposal__customer-detail-tag">
              Participant Date of Birth
            </div>
            <div>20/04/1980</div>
          </div>
        );
      }
    );
  }

  renderField(title, value) {
    <div className="proposal__customer-detail-content">
      <div className="proposal__customer-detail-tag">{title || ""}</div>
      <div>{value || ""}</div>
    </div>;
  }

  goSubmittedDocumentDetails() {
    let encodedRecMap = encodeURIComponent(this.stateItemId());
    return this.props.history.push(
      `/${this.state.client}/${this.state.route.entity}/${Config.routes.generic.document}/submitted/${encodedRecMap}/${this.stateDetailRoute()}`
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    return (
      <Base_screen
        listener={() => {
          this.fetchDetails();
        }}
      >
        <div>
          {this.state.loading && this.loadingContent()}
          <App_header
            data={this.state.userData}
            route={this.state.route.entity}
          />
          <App_sidebar route={this.state.route.entity}>
            <div
              className="d-flex"
              style={{ marginRight: "30px", marginLeft: "10px" }}
            >
              <App_header_title_simple
                menu={"menu2"}
                title={
                  this.state.issuedCasesDetail.customerDetails
                    ? this.state.issuedCasesDetail.customerDetails.participant
                        .name.value
                    : ""
                }
                description={
                  "Certificate Number : " +
                  (this.state.issuedCasesDetail.certDetails
                    ? this.state.issuedCasesDetail.certDetails.certNum.value !=
                      null
                      ? this.state.issuedCasesDetail.certDetails.certNum.value
                      : ""
                    : "")
                }
              />
              <App_button2
                title={"View All Submitted Documents"}
                onClickAction={() => this.goSubmittedDocumentDetails()}
              />
            </div>
            <div className="proposal">
              <div className="proposal__customer">
                <Row>
                  <Col span={15}>
                    {this.state.issuedCasesDetail.customerDetails ? (
                      <CustomerDetailsCard
                        module={this.stateModule()}
                        entity={this.stateEntity()}
                        data={this.state.issuedCasesDetail.customerDetails}
                      />
                    ) : (
                      <div />
                    )}
                    {this.state.issuedCasesDetail.certDetails ? (
                      <CertificateDetailsCard
                        module={this.stateModule()}
                        entity={this.stateEntity()}
                        data={this.state.issuedCasesDetail.certDetails}
                      />
                    ) : (
                      <div />
                    )}
                    {this.state.issuedCasesDetail.beneficiaryDetailsCaption && this.state.issuedCasesDetail.beneficiaryDetailsList.length ? (
                      <BeneficiaryDetailsCard
                        module={this.stateModule()}
                        entity={this.stateEntity()}
                        data={UserAccessBloc.showBeneficiaryDetails(
                          this.state.issuedCasesDetail.beneficiaryDetailsList
                        )}
                        title={
                          this.state.issuedCasesDetail.beneficiaryDetailsCaption
                        }
                      />
                    ) : (
                      <div />
                    )}
                    {this.state.issuedCasesDetail.bankRepresentativeDetails &&
                    UserAccessBloc.showBankRepresentative(
                      this.stateModule()
                    ) ? (
                      <BankRepresentativeCard
                        module={this.stateModule()}
                        entity={this.stateEntity()}
                        data={
                          this.state.issuedCasesDetail.bankRepresentativeDetails
                        }
                      />
                    ) : (
                      <div />
                    )}
                  </Col>

                  <Col span={9}>
                    <Row>
                      <Col span={24}>
                        {this.state.issuedCasesDetail.contributionDetails &&
                        this.stateEntity() == Config.entity.hsbc.route ? (
                          <ContributionDetailsCard
                            module={this.stateModule()}
                            entity={this.stateEntity()}
                            data={
                              this.state.issuedCasesDetail.contributionDetails
                            }
                          />
                        ) : (
                          <div />
                        )}

                        {/* {this.state.issuedCasesDetail.letterOfAcceptanceDetails && (this.stateEntity()==Config.entity.alliance.route) ? (
                          <LetterOfAcceptance
                            module={this.stateModule()}
                            entity={this.stateEntity()}
                            data={this.state.issuedCasesDetail.letterOfAcceptanceDetails}
                          />
                        ) : (
                          <div />
                        )} */}
                        {UserAccessBloc.showLetterOfAcceptance(
                          this.stateModule()
                        ) && (
                          <LetterOfAcceptance
                            module={this.stateModule()}
                            entity={this.stateEntity()}
                            data={
                              this.state.issuedCasesDetail
                                .letterOfAcceptanceDetails
                            }
                          />
                        )}

                        {this.state.issuedCasesDetail.certDateDetails ? (
                          <CertificateDetailsDateCard
                            module={this.stateModule()}
                            entity={this.stateEntity()}
                            certStatus={this.state.issuedCasesDetail.certDetails.certStatus.value}
                            data={this.state.issuedCasesDetail.certDateDetails}
                          />
                        ) : (
                          <div />
                        )}
                        {this.state.issuedCasesDetail.fundDetailsList &&
                        this.state.issuedCasesDetail.fundDetailsCaption ? (
                          <FundDetailsCard
                            module={this.stateModule()}
                            entity={this.stateEntity()}
                            caption={
                              this.state.issuedCasesDetail.fundDetailsCaption
                            }
                            disclaimer={
                              this.state.issuedCasesDetail.fundValuationDate ??
                              null
                            }
                            data={this.state.issuedCasesDetail.fundDetailsList}
                          />
                        ) : (
                          <div />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </App_sidebar>
        </div>
      </Base_screen>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps)(IssuedCasesDetail));
