import { DatePicker, Checkbox, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import AuthBloc from "../../../bloc/auth_bloc";
import GeneralBloc from "../../../bloc/general_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import { logOut, setAuth } from "../../../data/redux/reducers/authReducer";
import "./login_screen.css";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import UtilBloc from "../../../bloc/util_bloc";
import AppFieldError from "../../component/form/app_field_error/app_field_error";
import { ReactComponent as CalendarIcon } from "../../../Calendar.svg";
import { ReactComponent as AccountIcon } from "../../../Account.svg";
import moment from "moment";

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    const urlParams = new URLSearchParams(props.location.search);
    this.state = {
      client: Config.context,
      route: routeParams,
      username: "",
      password: "",
      isLoggedIn: false,
      loading: false,
      passwordVisible: false,
      errorpassword: null,
      errorusername: null,
      enableButton: false,
      validatedusername: false,
      validatedpassword: false,
      formError: "",
      status: urlParams.get("status") || false,
    };
  }

  stateDisableButton() {
    return this.state.validatedpassword && this.state.validatedusername;
  }

  changeHandler(e, name, errorName) {
    let error = UtilBloc.validateField(e.target.value, name);
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      ["error" + errorName]: error,
      ["validated" + errorName]: UtilBloc.isNullOrEmpty(error) ? true : false,
    });
  }

  changeHandlerDatePicker(nameField, valueField, name, errorName) {
    let error = UtilBloc.validateField(valueField, name);
    this.setState({
      ...this.state,
      [nameField]: valueField,
      ["error" + errorName]: error,
      ["validated" + errorName]: UtilBloc.isNullOrEmpty(error) ? true : false,
    });
  }

  setPasswordVisible() {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  }

  async resetPasswordHander() {
    this.setState({
      loading: true,
    });
    let userName = this.state.username;
    let password = this.state.password;
    let result = await AuthBloc.resetPassword(userName, password);
    this.setState({
      loading: false,
    });
    if (result.success) {
      this.setState({
        status: true,
      });
    } else {
      this.setState({
        formError: result.message,
      });
    }
  }

  resetPasswordContainer() {
    return (
      <div className="login__forms">
        <div className="login__header">Reset Password</div>
        <div className="mb-4 login_description" style={{ marginTop: "-20px" }}>
          Enter associated agent ID to reset password.
        </div>
        <div className="login__input">
          <Input
            style={{
              height: "48px",
              paddingTop: "10px",
              paddingLeft: "20px"
            }}
            prefix={<AccountIcon style={{
              marginLeft: "-5px",
              marginRight: "4px"
            }} />}
            placeholder="Agent ID / User ID"
            // value={this.state.username}
            value={this.state.username}
            onChange={(e) => {
              this.changeHandler(e, "username", "username");
            }}
            name="username"
            maxLength={18}
          />
          <div className="mt-2">
            <AppFieldError text={this.state.errorusername} />
          </div>
        </div>
        <div className="login__input">
          {/* <Input
            style={{
              height: "48px",
            }}
            placeholder="Date of Birth (DD/MM/YYYY)"
            //className="login__input"
            prefix={<CalendarIcon />}
            value={this.state.password}
            defaultValue={""}
            onChange={async (e) => {
              await this.changeHandler(e, "date of birth", "password");
              await this.validateForm();
            }}
            name="password"
            visibilitytoggle={{
              visible: this.state.passwordVisible,
              onVisibleChange: this.setPasswordVisible,
            }}
            maxLength={50}
          /> */}
          <DatePicker                      
            onChange={async (e) => {
              let dateFormat = e.format("DD/MM/YYYY");
              await this.changeHandlerDatePicker("password", dateFormat, "date of birth", "password");
              await this.validateForm();
            }}
            placeholder="Date of Birth (DD/MM/YYYY)"
            style={{ height: "48px", width: "100%", border: "1px solid #B5B5BE" }}
            name="password"
            suffixIcon={
              <CalendarIcon />
            }                     
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current > moment(customDate, "YYYY-MM-DD");
            }}
            format={["DD/MM/YYYY", "DD/MM/YY"]}

          />
          {/* <AppFieldError text={this.state.errorpassword} /> */}
        </div>
        <div style={{ marginTop: "-10px" }}>
          <AppFieldError text={this.state.errorConfirmPassword} />
          {!this.state.errorConfirmPassword &&
            !this.state.errorPassword &&
            this.state.formError ? (
            <AppFieldError text={this.state.formError} />
          ) : null}
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            marginBottom: "40px",
          }}
        ></div>
        <Button
          disabled={!this.stateDisableButton()}
          size="large"
          htmlType="submit"
          style={{
            backgroundColor: "#183028",
            width: "100%",
            borderRadius: 0,
            color: "white",
            marginBottom: "36px",
          }}
          onClick={() => this.resetPasswordHander()}
        >
          Submit
        </Button>

        <a
          className="login__forgot-password d-flex justify-content-center"
          href={`/${Config.context}/login`}
        >
          <u>Back to Login</u>
        </a>
      </div>
    );
  }

  successResetPasswordContainer() {
    return (
      <div className="login__forms">
        <div className="login__header">Check Your Email</div>
        <div className="mb-4 login_description" style={{ marginTop: "-20px" }}>
          Email has been sent. Please check your email and follow the
          instructions to reset your password.
        </div>
        <a
          className="login__forgot-password d-flex justify-content-center"
          href={`/${Config.context}/login`}
        >
          <u>Back to Login</u>
        </a>
      </div>
    );
  }

  renderResetPasswordPage() {
    return (
      <div className="login__screen">
        <div className="login__wrapper">
          {this.state.loading && this.loadingContent()}
          <div
            className="login__container"
            style={{ width: this.state.status ? "520px" : "" }}
          >
            <div className="login__logo-container">
              <img
                src={
                  GeneralBloc.getAssetsContextPath() +
                  `/assets/images/Logo/FWD Takaful Logo.svg`
                }
                alt="FWD Takaful"
                className="login__logo-image"
              />
            </div>
            {!this.state.status
              ? this.resetPasswordContainer()
              : this.successResetPasswordContainer()}
          </div>
        </div>
      </div>
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  render() {
    return <>{this.renderResetPasswordPage()}</>;
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = { setAuth, logOut };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen)
);
