import { Component } from "react";
import AppHeaderBean from "../data/bean/app_header_bean";

class ProfileBloc extends Component {
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }
  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }
}

export default ProfileBloc;
