class AppNewsCardBean {
  constructor(id, title, content, timestamp) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.timestamp = timestamp;
  }
}

export default AppNewsCardBean;
