import { Space } from "antd";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_dashboard_header.css";

class AppDashboardHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <Space className="dashboard-header d-flex w-100 justify-content-between align-items-center">
          <div className="dashboard-header__title">{this.props.title}</div>
          <div
            className="d-flex align-items-center"
            onClick={this.props.onClick}
          >
            <Link
              to={this.props.link}
              style={{
                color: "black",
              }}
              className="d-flex align-items-center"
            >
              <div className="dashboard-header__view-more mr-3">View More</div>
              <img
                src={
                  GeneralBloc.getAssetsContextPath() +
                  `/assets/icons/ChevronRight.svg`
                }
                alt="Chervon Right Icon"
              />
            </Link>
          </div>
        </Space>
        {this.props.children}
      </>
    );
  }
}

export default withRouter(AppDashboardHeader);
