import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import AnnouncementsBloc from "../../../bloc/announcements_bloc";
import GeneralBloc from "../../../bloc/general_bloc";
import Config from "../../../Config";
import "./app_announcement.css";

class AppAnnouncement extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <Link
          to={`/${this.props.client}/${this.props.route}/${Config.routes.generic.announcement}/${this.props.data.id}`}
          style={{ color: "black" }}
        >
          <div className="announcement">
            <div className="d-flex">
              <div className="announcement_image_container">
              <img
                src={
                  this.props.data.image
                }                
                height={151}
                width={270}
                alt="announcement"
              />
              </div>
              
              <div className="announcement__container">
                <div className="announcement__title line-clamp">
                  {this.props.data.title}
                </div>
                <div className="announcement__content line-clamp">
                 {
                  this.props.data.content
                 }
                </div>
                <div className="announcement__timestamp">
                  {this.props.data.timestamp}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  }
}

export default withRouter(AppAnnouncement);
