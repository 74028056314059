const Config = {
  context: "portal",
  channel: "portal",
  entityMapping: {
    Alliance: "AIS",
    HSBC: "BCA",
  },
  entity: {
    hsbc: {
      code: "BCA",
      logo: "HSBCLogo.png",
      route: "HSBC",
      designationTitle: true,
    },
    alliance: {
      code: "AIS",
      logo: "AllianceLogo.png",
      route: "Alliance",
      designationTitle: false,
    },
  },
  locale: {
    en: "EN",
    bm: "BM",
  },
  modules: {
    dashboardOverall: "dashboard-overall",
    dashboardUpcoming: "dashboard-upcoming",
    proposal: "proposal",
    issuedCase: "issuedCase",
    claim: "claim",
    certificateCollection: "certificateCollection",
    certificateCollectionUpcoming: "certificateCollection-upcoming",
    businessOpportunity: "businessOpportunity",
    businessOpportunityUpcoming: "businessOpportunity-upcoming",
    form: "form",
  },
  HSBC: {
    proposal: {
      contributionDueDetails: [
        "holidayIndicator",
        "dueAmount",
        "dueDate",
        "lastContributionDue",
        "dueTerm",
        "additionalCharge",
      ],
      certificateDetailsDate: [
        "proposalSubmissionDate",
        "certAcceptanceDate",
        "riskCommencementDate",
      ],
      certificateDetails: [
        {
          item1: "productName",
          item2: "certStatus",
        },
        {
          item1: "certNum",
          item2: "sumCovered",
        },
        {
          item1: "coverageTerm",
          item2: "agingDays",
        },
        {
          item1: "contribution",
          item2: "contributionFreq",
        },
        {
          item1: "basicContribution",
          item2: "riderContribution",
        },
        {
          item1: "regularTopupContribution",
          item2: null,
        },
      ],
      bankRepresentativeDetails: [
        {
          item1: "regionCode",
          item2: "regionName",
        },
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
      ],
    },
    certificateCollection: {
      contributionDueDetails: [
        "holidayIndicator",
        "dueAmount",
        "dueDate",
        "lastContributionDue",
        "dueTerm",
        "additionalCharge",
      ],
      certificateDetailsDate: [
        "riskCommencementDate",
        "issuedDate",
        "contributionCessationDate",
        "maturityDate",
      ],
      certificateDetails: [
        {
          item1: "productName",
          item2: "certStatus",
        },
        {
          item1: "certNum",
          item2: "sumCovered",
        },
        {
          item1: "coverageTerm",
        },
        {
          item1: "contribution",
          item2: "contributionFreq",
        },
        {
          item1: "basicContribution",
          item2: "riderContribution",
        },
        {
          item1: "totalCollectedContribution",
          item2: "annualisedContribution",
        },
        {
          item1: "regularTopupContribution",
          item2: "totalAdhocTopupContribution",
        },
        {
          item1: "contributionPaymentTerm",
          item2: "paymentType",
        },
        {
          item1: "cashValue",
        },
      ],
      bankRepresentativeDetails: [
        {
          item1: "regionCode",
          item2: "regionName",
        },
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
      ],
    },
    issuedCase: {
      contributionDueDetails: ["dueAmount", "dueDate"],
      certificateDetailsDate: [
        "riskCommencementDate",
        "issuedDate",
        "contributionCessationDate",
        "maturityDate",
      ],
      certificateDetails: [
        {
          item1: "productName",
          item2: "certStatus",
        },
        {
          item1: "certNum",
          item2: "sumCovered",
        },
        {
          item1: "coverageTerm",
        },
        {
          item1: "contribution",
          item2: "contributionFreq",
        },
        {
          item1: "basicContribution",
          item2: "riderContribution",
        },
        {
          item1: "totalCollectedContribution",
          item2: "annualisedContribution",
        },
        {
          item1: "regularTopupContribution",
          item2: "totalAdhocTopupContribution",
        },
        {
          item1: "contributionPaymentTerm",
          item2: "paymentType",
        },
        {
          item1: "cashValue",
          item2: "regularPayoutIndicator",
        },
        {
          item1: "regularPayoutAmt",
          item2: "regularPayoutFreq",
        },
        {
          item1: "regularPayoutCount",
          item2: null,
        },
      ],
      beneficiaryDetails: [
        {
          item1: "name",
          item2: "id",
          item3: "relationship",
          item4: "sharePercentage",
        },
      ],
      bankRepresentativeDetails: [
        {
          item1: "regionCode",
          item2: "regionName",
        },
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
      ],
    },
    businessOpportunity: {
      certificateDetailsDate: [
        "contPaidUpDate",
        "firstRegularPayoutDate",
        "maturityDate",
      ],
      certificateDetails: [
        {
          item1: "productName",
          item2: "certStatus",
        },
        {
          item1: "certNum",
          item2: "sumCovered",
        },
        {
          item1: "coverageTerm",
          item2: "coveragePeriod",
        },
        {
          item1: "contribution",
          item2: "contributionFreq",
        },
        {
          item1: "basicContribution",
          item2: "riderContribution",
        },
        {
          item1: "totalCollectedContribution",
          item2: "annualisedContribution",
        },
        {
          item1: "regularTopupContribution",
          item2: "totalAdhocTopupContribution",
        },
        {
          item1: "contributionPaymentTerm",
          item2: "paymentType",
        },
        {
          item1: "cashValue",
          item2: "surrenderValue",
        },
        {
          item1: "regularPayoutAmt",
          item2: "regularPayoutFreq"
        },
        {
          item1: "regularPayoutIndicator",
          item2: null
        }
      ],
      certificateDetailsFullyPaidUp: [
        {
          item1: "productName",
          item2: "certStatus",
        },
        {
          item1: "certNum",
          item2: "sumCovered",
        },
        {
          item1: "coverageTerm",
          item2: "coveragePeriod",
        },
        {
          item1: "contribution",
          item2: "contributionFreq",
        },
        {
          item1: "basicContribution",
          item2: "riderContribution",
        },
        {
          item1: "totalCollectedContribution",
          item2: "annualisedContribution",
        },
        {
          item1: "regularTopupContribution",
          item2: "totalAdhocTopupContribution",
        },
        {
          item1: "contributionPaymentTerm",
          item2: "paymentType",
        },
        {
          item1: "cashValue",
          item2: "surrenderValue",
        },
        {
          item1: "regularPayoutAmt",
          item2: "regularPayoutFreq"
        },
        {
          item1: "regularPayoutIndicator",
          item2: "regularPayoutCount"
        }
      ],
      certificateDetailsMatured: [
        {
          item1: "productName",
          item2: "certStatus",
        },
        {
          item1: "certNum",
          item2: "sumCovered",
        },
        {
          item1: "coverageTerm",
          item2: "coveragePeriod",
        },
        {
          item1: "contribution",
          item2: "contributionFreq",
        },
        {
          item1: "basicContribution",
          item2: "riderContribution",
        },
        {
          item1: "totalCollectedContribution",
          item2: "annualisedContribution",
        },
        {
          item1: "regularTopupContribution",
          item2: "totalAdhocTopupContribution",
        },
        {
          item1: "contributionPaymentTerm",
          item2: "paymentType",
        },
        {
          item1: "cashValue",
          item2: "surrenderValue",
        },
        {
          item1: "regularPayoutAmt",
          item2: "regularPayoutFreq"
        },
        {
          item1: "regularPayoutIndicator",
          item2: "regularPayoutCount"
        },
        {
          item1: "maturityPayoutAmt",
          item2: "totalMaturityValue",
        }
      ],
      bankRepresentativeDetails: [
        {
          item1: "regionCode",
          item2: "regionName",
        },
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
      ],
    },
    claim: {
      contributionDueDetails: [
        "holidayIndicator",
        "dueAmount",
        "dueDate",
        "lastContributionDue",
        "dueTerm",
        "additionalCharge",
      ],
      certificateDetailsDate: ["riskCommencementDate", "issuedDate"],
      certificateDetails: [
        {
          item1: "productName",
          item2: null,
        },
        {
          item1: "certNum",
          item2: "sumCovered",
        },
        {
          item1: "coverageTerm",
          item2: "coveragePeriod",
        },
        {
          item1: "tpdClaimStatus",
          item2: "tpdCriticalIllnessIndicator",
        },
        {
          item1: "criticalIllnessRiderSumCovered",
          item2: null,
        },
      ],
      claimDetails: [
        {
          item1: "claimRefNo",
          item2: "claimSubmissionDate",
        },
        {
          item1: "claimType",
          item2: "claimStatus",
        },
        {
          item1: "lastUpdatedDate", 
          item2: null,
        },
        {
          item1: "participantName",
          item2: "participantId",
        },
        {
          item1: "participantMobileNum",
          item2: "participantEmail",
        },
      ],
      bankRepresentativeDetails: [
        {
          item1: "regionCode",
          item2: "regionName",
        },
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
      ],
    },
  },
  Alliance: {
    proposal: {
      contributionDueDetails: [
        "holidayIndicator",
        "dueAmount",
        "dueDate",
        "lastContributionDue",
        "dueTerm",
        "additionalCharge",
      ],
      certificateDetailsDate: [
        "proposalSubmissionDate",
        "certAcceptanceDate",
        "riskCommencementDate",
      ],
      certificateDetails: [
        {
          item1: "productName",
          item2: "bankRefNum",
        },
        {
          item1: "certNum",
          item2: "certStatus",
        },
        {
          item1: "sumCovered",
          item2: "contribution",
        },
        {
          item1: "criticalIllnessRider",
          item2: "criticalIllnessIndicator",
        },
        {
          item1: "criticalIllnessRiderSumCovered",
          item2: null,
        },
        {
          item1: "certTerm",
          item2: "constructionYear",
        },
        {
          item1: "paymentType",
          item2: "agingDays",
        },
      ],
      bankRepresentativeDetails: [
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
        {
          item1: "rptMgrName",
        },
      ],
    },
    issuedCase: {
      contributionDueDetails: ["dueAmount", "dueDate"],
      certificateDetailsDateInForce: ["proposalSubmissionDate", "riskCommencementDate","issuedDate"],
      certificateDetailsDateAccepted: [ "proposalSubmissionDate","certAcceptanceDate","riskCommencementDate"],
      certificateDetailsInForce: [
        {
          item1: "productName",
          item2: "bankRefNum",
        },
        {
          item1: "certNum",
          item2: "certStatus",
        },
        {
          item1: "sumCovered",
          item2: "contribution",
        },
        {
          item1: "criticalIllnessRider",
          item2: "criticalIllnessIndicator",
        },
        {
          item1: "criticalIllnessRiderSumCovered",
          item2: null,
        },
        {
          item1: "certTeam",
          item2: "constructionYear",
        },
        {
          item1: "paymentType",
          item2: null,
        },
      ],
      certificateDetailsAccepted: [
        {
          item1: "productName",
          item2: "bankRefNum",
        },
        {
          item1: "certNum",
          item2: "certStatus",
        },
        {
          item1: "sumCovered",
          item2: "contribution",
        },
        {
          item1: "criticalIllnessRider",
          item2: "criticalIllnessIndicator",
        },
        {
          item1: "criticalIllnessRiderSumCovered",
          item2: null,
        },
        {
          item1: "certTeam",
          item2: "constructionYear",
        },
        {
          item1: "paymentType",
          item2: "agingDays",
        },
      ],
      bankRepresentativeDetails: [
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
        {
          item1: "rptMgrName",
        },
      ],
    },
    claim: {
      contributionDueDetails: [
        "holidayIndicator",
        "dueAmount",
        "dueDate",
        "lastContributionDue",
        "dueTerm",
        "additionalCharge",
      ],
      certificateDetailsDate: ["riskCommencementDate", "issuedDate"],
      certificateDetails: [
        {
          item1: "productName",
          item2: "bankRefNum",
        },
        {
          item1: "certNum",
          item2: null,
        },
        {
          item1: "sumCovered",
          item2: "contribution",
        },
        {
          item1: "criticalIllnessRider",
          item2: "criticalIllnessIndicator",
        },
        {
          item1: "criticalIllnessRiderSumCovered",
          item2: null,
        },
      ],
      bankRepresentativeDetails: [
        {
          item1: "branchCode",
          item2: "branchName",
        },
        {
          item1: "agentId",
          item2: "agentName",
        },
        {
          item1: "rptMgrName",
        },
      ],
    },
  },
  labelPrefix: {
    errorTitle: "ERRTITLE_",
    errorDesc: "ERRDESC_",
  },
  cache: {
    languageKey: "fwdbanca_SELECTED_LANG",
    localeKey: "fwdbanca_LOCALE_LIBRARY",
    sessionID: "SESSIONID_",
    productScreens: "fwdbanca_PRODUCT_SCREENS_",
    screenDetail: "fwdbanca_SCREEN_DETAIL_",
    partnerDetail: "fwdbanca_PARTNER_DETAIL_",
  },
  client: {
    type: {
      customer: "CUSTOMER",
      payor: "PAYOR",
    },
    idType: {
      nric: "NRIC",
      passport: "Passport",
    },
    gender: {
      male: "Male",
      female: "Female",
    },
  },
  routes: {
    assetsContext: "/portal",
    entity: {
      hsbc: "hsbc",
      alliance: "alliance",
    },
    generic: {
      login: "login",
      resetPassword: "reset-password",
      changePassword: "change-password",
      account: "account",
      profile: "profile",
      home: "home",
      connectivity: "connectivity",
      dailyReminder: "daily-reminders",
      announcement: "announcement",
      proposal: "proposal",
      document: "document",
      issuedCase: "issued-case",
      certificateCollection: "certificate-collection",
      certificateCollectionUpcoming: "certificate-collection-upcoming",
      claim: "claim",
      businessOpportunity: "business-opportunity",
      businessOpportunityUpcoming: "business-opportunity-upcoming",
      form: "form",
      loginAdmin: "login-admin",
    },
    journey: {
      personalDetail: "personalDetail",
    },
  },
  error: {
    labelNotFound: "<Missing label>",
  },
  searchCriteriaValidationType: {
    //1 = no symbols
    //2 = no numbers
    //3 = no letters & symbols
    //4 = no validation
    certificateNumber: 1,
    participantName: 2,
    participantId: 1,
    mobileNumber: 3,
    coveredName: 2,
    coveredId: 1,
    claimRefNo: 4,
    bankReferenceNumber: 4,
  },
};

export default Config;
