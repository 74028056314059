/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Menu, Radio, Select } from "antd";
import { Option } from "antd/lib/mentions";
import GeneralBloc from "../../../bloc/general_bloc";
import App_daterange_filter from "../app_filters/app_daterange_filter";
import App_dropdown_filter from "../app_filters/app_dropdown_filter";
import "./app_header_title.css";
import { ReactComponent as DropDownIcon } from "../../../ArrowDownIcon.svg";

class AppHeaderTitleFilter extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeModule = this.handleChangeModule.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.state = {
      dropdownOpen: false,
      menu2: this.getMenu(),
    };
  }

  getMenu() {
    return (
      <Menu
        items={[
          {
            label: (
              <div
                className="header-title__dropdown-item"
                onClick={() => {
                  this.props.exportFile();
                }}
              >
                Export as CSV
              </div>
            ),
            key: "0",
          },
        ]}
      />
    );
  }

  exportFile(e) {}

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleChange(e) {
    let value = e.target.value;
    this.props.onSelectedSearchType(value);
    this.setState({
      value: value,
    });
  }

  handleChangeModule(value) {
    this.props.handleChangeModule(value);
  }

  renderDashboardParts() {
    let filters = GeneralBloc.getDashboardModule();

    return filters.map((item) => {
      return (
        <Option
          value={item.value}
          className="dropdown-filter__item"
          style={{ fontWeight: 500 }}
        >
          {item.caption}
        </Option>
      );
    });
  }

  render() {
    return (
      <>
        <div className="header-title">
          <div className="d-flex">
            <div
              className="mr-3 header-title__icon"
              // onClick={this.props.history.goBack}
            >
              <div onClick={this.props.history.goBack}>
                <img
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    `/assets/icons/Backward.svg`
                  }
                  alt="Backward Icon"
                  className="pt-1"
                />
              </div>
            </div>
            <div className="header-title__title">{this.props.title}</div>
          </div>
          <div className="header-title__radio-input">
            {this.props.show ? (
              <div>
                <div
                  className="dropdown-filter d-flex justify-content-center align-items-center mr-3"
                  style={{ width: "200px", height: "48px" }}
                >
                  <Select
                    style={{
                      width: 200,
                    }}
                    size={"large"}
                    placeholder={this.props.placeholder}
                    onChange={this.handleChangeModule}
                    bordered={false}
                    defaultValue={this.props.defaultValue}
                    suffixIcon={<DropDownIcon />}
                  >
                    {this.renderDashboardParts()}
                  </Select>
                </div>
              </div>
            ) : null}

            <div className="d-flex ">
              <Radio.Group
                name="radiogroup"
                defaultValue={this.props.valueSearchType}
                value={this.props.defaultValueSearchType}
                className="header-title__radio-container"
                onChange={this.handleChange}
                buttonStyle={"outline"}
              >
                <Radio
                  style={
                    this.props.defaultValueSearchType == "byGroup"
                      ? {
                          backgroundColor: "#EE7623",
                          paddingTop: 11.5,
                          paddingBottom: 11.5,
                          paddingLeft: 16,
                          paddingRight: 16,
                          color: "white",
                          marginRight: 0,
                          width: 180,
                        }
                      : {
                          paddingTop: 11.5,
                          paddingBottom: 11.5,
                          paddingLeft: 16,
                          paddingRight: 16,
                          width: 180,
                        }
                  }
                  value={"byGroup"}
                >
                  <div>By Group Filter</div>
                </Radio>
                <Radio
                  style={
                    this.props.defaultValueSearchType == "bySearch"
                      ? {
                          backgroundColor: "#EE7623",
                          paddingTop: 11.5,
                          paddingBottom: 11.5,
                          paddingLeft: 16,
                          paddingRight: 16,
                          color: "white",
                          marginRight: 0,
                          width: 180,
                        }
                      : {
                          paddingTop: 11.5,
                          paddingBottom: 11.5,
                          paddingLeft: 16,
                          paddingRight: 16,
                          width: 180,
                        }
                  }
                  value={"bySearch"}
                >
                  <div>By Search</div>
                </Radio>
              </Radio.Group>
            </div>
            <Dropdown
              overlay={this.state.menu2}
              trigger={[this.props.disabled ? "" : "click"]}
            >
              <Button
                className="d-flex align-items-center"
                style={{
                  // backgroundColor: this.props.disabled ? "#f5f5f5" : "white",
                  backgroundColor: this.props.disabled ? "#f5f5f5" : "white",
                  borderColor: this.props.disabled ? "grey" : "#183028",
                  height: "46px",
                }}
                size={"large"}
                disabled={this.props.disabled}
              >
                <img
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    `/assets/icons/Download2.svg`
                  }
                  alt="Download Icon"
                />
                <div className="mx-3">Export</div>
                <img
                  src={
                    GeneralBloc.getAssetsContextPath() +
                    `/assets/icons/ArrowDownIcon.svg`
                  }
                  alt="Backward Icon"
                />
              </Button>
            </Dropdown>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AppHeaderTitleFilter);
