import axios from "axios";
import React, { Component } from "react";
import BaseWebService from "./base_web_service";

export default class IssuedCasesWebService extends Component {
  static async getIssuedCase(
    token,
    entity,
    page,
    fromDate,
    toDate,
    regions,
    searchBy,
    keyword,
    branches,
    agents,
    statuses,
    dateFilter
  ) {
    let payload={
      id: "df-issued-case-list",
      pageNumber: page,
      criParams: {
        "proposal-dt-range": dateFilter,
        dtFrom: fromDate,
        dtTo: toDate,
        "proposal-attr-search": searchBy,
        searchBy: keyword,
        //   regions: regions,
        regions: regions,
        branches: branches,     
        agents: agents,
        statuses: statuses,   
      },
      sortBys: [
        {
          fieldName: "pPartName",
          asc: true,
        },
      ],
    }

    if(dateFilter!="range"){
      delete payload.criParams.dtFrom;
      delete payload.criParams.dtTo;      
    }
    let header=await BaseWebService.standardHeaders();
    let resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/list`,
        payload,
      {
        headers: header,
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }
  static async getIssuedCaseDetail(token, entity, recMap) {
    let header=await BaseWebService.standardHeaders();
    let resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/issuedCaseDetails/${recMap}`,
      {
        headers: header
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  static async getFundDetail(token, entity, recMap) {
    let header=await BaseWebService.standardHeaders();
    let resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/fundList/${recMap}`,
      {
        headers: header
      }
    );
    let response = BaseWebService.baseResponseFromWeb(resp.data);
    return response;
  }

  
}
