import AppClaimStatusBean from "../data/bean/app_claim_status_bean";
import BaseWebService from "../service/base_web_service";
import GeneralWebService from "../service/general_web_service";
import AppTableStatus from "../ui/component/app_table_status/app_table_status";
import AuthBloc from "./auth_bloc";
import SubmittedDocumentBean from "../data/bean/submitted_document_bean";
import UserAccessBloc from "./user_access_bloc";
import { Image } from "antd";
import UtilBloc from "./util_bloc";
import { globalState } from "../data/global/globalState";
import Config from "../Config";

class GeneralBloc {

  static getFilterButtons(){
    return [
      {
        label: "Birthday",
        code: "BIRTHDAY",
        count: 0,
        selected: true
      },
      {
        label: "Certificate Due",
        code: "POLICY_PREMIUM_DUE",
        count: 0,
        selected: false
      },
      {
        label: "Certificate Matured",
        code: "POLICY_MATURE",
        count: 0,
        selected: false
      },
      {
        label: "Certificate Paid Up ",
        code: "POLICY_PAID_UP",
        count: 0,
        selected: false
      }
    ]
  }

  static getDashboardModule() {
    return [
      {
        caption: "Overall Dashboard",
        value: "Overall Dashboard",
      },
      {
        caption: "Upcoming Events",
        value: "Upcoming Events",
      },
    ];
  }

  //Module toggle to turn on/off of the modules
  static proposalEnabled() {
    let moduleStatus = `${process.env.REACT_APP_MODULE_PROPOSAL}`;
    return moduleStatus == 'true';
  }
  static issuedCaseEnabled() {
    let moduleStatus = `${process.env.REACT_APP_MODULE_ISSUED_CASE}`;
    return moduleStatus == 'true';
  }
  static claimEnabled() {
    let moduleStatus = `${process.env.REACT_APP_MODULE_CLAIM}`;
    return moduleStatus == 'true';
  }
  static certCollectionEnabled() {
    let moduleStatus = `${process.env.REACT_APP_MODULE_CERT_COLLECTION}`;
    return moduleStatus == 'true';
  }
  static certCollectionUpcomingEnabled() {
    let moduleStatus = `${process.env.REACT_APP_MODULE_CERT_COLLECTION_UPCOMING}`;
    return moduleStatus == 'true';
  }
  static bizOppEnabled() {
    let moduleStatus = `${process.env.REACT_APP_MODULE_BIZ_OPP}`;
    return moduleStatus == 'true';
  }
  static bizOppUpcomingEnabled() {
    let moduleStatus = `${process.env.REACT_APP_MODULE_BIZ_OPP_UPCOMING}`;
    return moduleStatus == 'true';
  }


  static getShortDashboardModule(part) {
    let newPath = "";
    if (part == "Overall Dashboard") {
      newPath = "dashboard";
    } else if (part == "Upcoming Events") {
      newPath = "event";
    }
    return newPath;
  }

  static getSwitchUpcomingOrDashboard(route, part) {
    let newRoute = "";
    if (part == "Overall Dashboard") {
      if (route == Config.routes.generic.certificateCollectionUpcoming) {
        newRoute = Config.routes.generic.certificateCollection;
      }

      if (route == Config.routes.generic.businessOpportunityUpcoming) {
        newRoute = Config.routes.generic.businessOpportunity;
      }
    } else if (part == "Upcoming Events") {
      if (route == Config.routes.generic.certificateCollection) {
        newRoute = Config.routes.generic.certificateCollectionUpcoming;
      }

      if (route == Config.routes.generic.businessOpportunity) {
        newRoute = Config.routes.generic.businessOpportunityUpcoming;
      }
    }
    return newRoute;
  }

  static getModuleDetailsFromUserSetting(userSettings, module) {
    return userSettings.module[module];
  }

  static encodeAndDecodeURL(url) {
    let encoded = encodeURIComponent(url);
    let decoded = decodeURIComponent(encoded);
    return decoded;
  }

  static formatDate(date) {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();

    return dd + "/" + mm + "/" + yyyy;
  }

  //Get url to image by image code
  static getImageURLByCode(code) {
    return BaseWebService.baseUrl() + "/asset/image/" + code;
  }

  static getAssetsContextPath() {
    return "/portal";
  }

  static getImagePath(code) {
    let webUrl = BaseWebService.baseUrl() + "/download/image/" + code;
    let localUrl =
      GeneralBloc.getAssetsContextPath() + `/assets/icons/status/${code}`;
    return webUrl;
  }

  static async getDateRangeFilter(id) {
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }
    let response = await GeneralWebService.getDateRangeFilter(token, id);
    return response;
  }

  static async getDateRangeUpcomingFilter(id) {
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }
    let response = await GeneralWebService.getDateRangeUpcomingFilter(
      token,
      id
    );
    return response;
  }

  static async getSearchBys(id) {
    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }
    let response = await GeneralWebService.getSearchBys(token, id);
    return response;
  }

  static resultColumnToObject(result) {
    let resultColumn = result;

    let mappedData = [];
    for (var i = 0; i < resultColumn.length; i++) {
      let record = resultColumn[i];
      let columnKey = record.key;
      for (var j = 0; j < record.list.length; j++) {
        let recordValue = record.list[j];
        if (i == 0) {
          mappedData.push({});
        }
        mappedData[j][columnKey] = recordValue;
      }
    }
    return mappedData;
  }

  static async getDetailContent(response, module) {}

  static async getListingTable(response, module, userConfigCache) {
    let data = [];
    let collumnTable = [];
    let totalData = 0;
    let recCount = response.data.recCount;
    totalData = recCount;

    let userConfig = await UserAccessBloc.getUserConfigForModule(
      null,
      null,
      module,
      userConfigCache
    );
    let listSetting = userConfig.list;
    let fieldStyle;
    let fieldIcon;
    let fieldAlign;
    if (listSetting) {
      fieldStyle = listSetting.fieldStyle;
      fieldIcon = listSetting.fieldIcon;
      fieldAlign = listSetting.fieldAlign;
    }
    for (let i = 0; i < response.data.resultColumns[0].list.length; i++) {
      let record = {};

      for (let j = 0; j < response.data.resultColumns.length; j++) {
        let key = response.data.resultColumns[j]["key"];
        let value = response.data.resultColumns[j]["list"][i];
        if (value == null || value == "") {
          value = "N/A";
        }
        if (fieldStyle && fieldStyle[key]) {
          let keyFieldStyle = fieldStyle[key];
          let styleSet = false;
          for (var x = 0; x < (keyFieldStyle || []).length; x++) {
            let curKeyStyleValue = keyFieldStyle[x].value;

            if (curKeyStyleValue == value) {
              let curKeyStyleTranslation = keyFieldStyle[x].translation;
              styleSet = true;
              record[key] = (
                <AppTableStatus
                  statusName={curKeyStyleTranslation || value}
                  textColor={keyFieldStyle[x].textColor}
                  background={keyFieldStyle[x].background}
                />
              );
            }
          }
          if (!styleSet) {
            record[key] = value;
          }
        } else if (fieldIcon && fieldIcon[key]) {
          let keyFieldIcon = fieldIcon[key];
          let iconSet = false;
          for (var x = 0; x < (keyFieldIcon || []).length; x++) {
            let curKeyIconValue = keyFieldIcon[x].value;
            if (curKeyIconValue == value) {
              iconSet = true;
              record[key] = (
                <img
                  id={value}
                  src={GeneralBloc.getImagePath(keyFieldIcon[x].icon)}
                  height={20}
                  width={20}
                />
              );
            }
          }
          if (!iconSet) {
            record[key] = value;
          }
        } else {
          record[key] = value;
        }
      }
      data.push(record);
    }
    for (let x = 0; x < response.data.resultColumns.length; x++) {
      let displayFlex = response.data.resultColumns[x]["displayFlex"];
      if (displayFlex === 0) {
        continue;
      }
      let recordCollumn = {};

      let ObjectOfKeyFieldAlign = {};
      let indexOfMatchedCollumn = 0;

      if (fieldAlign) {
        ObjectOfKeyFieldAlign = Object.keys(fieldAlign);
        indexOfMatchedCollumn = ObjectOfKeyFieldAlign.indexOf(
          response.data.resultColumns[x].key
        );
      }

      if (
        response.data.resultColumns[x].key ==
        ObjectOfKeyFieldAlign[indexOfMatchedCollumn]
      ) {
        recordCollumn = {
          title: response.data.resultColumns[x].caption,
          dataIndex: response.data.resultColumns[x].key,
          responsive: ["md"],
          align: fieldAlign[ObjectOfKeyFieldAlign[indexOfMatchedCollumn]],
          // sorts by seq
        };
      } else {
        recordCollumn = {
          title: response.data.resultColumns[x].caption,
          dataIndex: response.data.resultColumns[x].key,
          responsive: ["md"],
          // sorts by seq
        };
      }

      collumnTable.push(recordCollumn);
    }
    return {
      columnTable: collumnTable,
      data: data,
      total: totalData,
    };
  }

  static async viewOrDownloadFile(id, view) {
    let result = await UtilBloc.downloadFile("form", id);
    if (result.success) {
      let filename = `${id}.${"pdf"}`;
      UtilBloc.downloadBinary(result.data.data, filename);
    }
  }

  static async getModuleCounts(module, byPassPage, criParams) {
    let token = "";
    const session = await AuthBloc.checkSession();
    // console.log(session);

    if (session) {
      token = session.accessToken;
    }

    let userSession = await AuthBloc.checkSession();
    let bankId = userSession.roles[0].entity;
    let roleCode = userSession.roles[0].mapCode;

    let response = await GeneralWebService.getModuleCount(
      module,
      token,
      bankId,
      criParams,
      byPassPage
    );

    let result = response.data;
    let countData = {};
    let resultColumn = null;
    if (result) {
      resultColumn = result.resultColumns;
      let mappedData = this.resultColumnToObject(resultColumn);
      for (var i = 0; i < mappedData.length; i++) {
        let map = mappedData[i];
        countData[map["pCertStatus"]] = map["figure"];
      }
    }
    return countData;
  }

  static getFilteredByDate(dateFilter, fromDate, toDate) {
    let d = new Date();
    let beginDate = "";
    let endDate = "";

    switch (dateFilter) {
      case "":
        endDate = "2050-01-01";
        beginDate = "1900-01-01";
        break;
      case "-30d":
        endDate = d.toISOString().split("T")[0];
        d.setDate(d.getDate() - 30);
        beginDate = d.toISOString().split("T")[0];
        break;
      case "-60d":
        endDate = d.toISOString().split("T")[0];
        d.setDate(d.getDate() - 60);
        beginDate = d.toISOString().split("T")[0];
        break;
      case "-90d":
        endDate = d.toISOString().split("T")[0];
        d.setDate(d.getDate() - 90);
        beginDate = d.toISOString().split("T")[0];
        break;
      case "range":
        beginDate = fromDate;
        endDate = toDate;
        break;
      default:
        break;
    }
    let result = { beginDate: beginDate, endDate: endDate };
    return result;
  }

  static getSubmittedDocuments(list) {
    let newList = [];
    /*  "id":"NBUGroup2@@2198@@63417",
         "docClass":"NBUGroup2",
         "docId":"NBU-NAPP-02",
         "category":"Proposal Documents",
         "subCategory":"Proposal Form",
         "nric":"900909123457",
         "applicationNum":"0054480002",
         "certNum":"BL004558",
         "receivedDate":"2022-08-11",
         "boxId":"",
         "claimNum":"",
         "otherId":"900909123457" */
    if (list) {
      list.map((ev) => {
        newList.push(
          new SubmittedDocumentBean(ev.id, ev.subCategory, null, "pdf", true)
        );
      });
    }

    return newList;
  }

  static async checkAccessModule(module, userConfigCache) {
    let moduleSetting = await UserAccessBloc.getUserConfigForModule(
      null,
      null,
      module,
      userConfigCache
    );
    let check = true;
    if (UtilBloc.isNullOrEmpty(moduleSetting)) {
      check = false;
    }
    return check;
  }

  static getResultColumnByKey(key, resultColumn) {
    if (!resultColumn || resultColumn.length <= 0) return null;
    let filtered = resultColumn.filter((it) => it.key == key);

    if (filtered.length <= 0) return null;
    return filtered[0];
  }

  static async getStatusBox(
    module,
    moduleId,
    showForEmpty,
    byPassPage,
    criParams,
    userConfigGlobal
  ) {
    let moduleSetting = await UserAccessBloc.getUserConfigForModule(
      null,
      null,
      module,
      userConfigGlobal
    );
    let boxes = [];
    if (moduleSetting) {
      let statuses = moduleSetting.status || [];
      let counts = await this.getModuleCounts(moduleId, byPassPage, criParams);
      console.log("Counts!", counts);
      for (var i = 0; i < statuses.length; i++) {
        let status = statuses[i];
        let mapCode = status.mapCode;
        let count = counts[mapCode] || 0;
        if (count > 0 || showForEmpty == true) {
          boxes.push(
            new AppClaimStatusBean(
              status.icon,
              status.inactiveIcon,
              status.name,
              count,
              status.code,
              status.background,
              status.translation
            )
          );
        }
      }
    }
    return boxes;
  }

  static async getDynamicFilter(endpoint, request) {
    let token = "";
    const session = await AuthBloc.checkSession();
    if (session) {
      token = session.accessToken;
    }

    let response = await GeneralWebService.getDynamicFilter(
      token,
      endpoint,
      request
    );
    return response;
  }

  // static testScript(input){
  //   var response = input.response;
  //   var result = response.result;
  //   var resultColumns = result.resultColumns || [];
  //   var data = [];
  //   for (var i = 0; i < resultColumns[0].list.length; i++) {
  //     var record = {};
  //     let key = resultColumns[0]['list'][i];
  //     let value = resultColumns[1]['list'][i];
  //     record = {
  //       code: key,
  //       name: value,
  //       status: true
  //     };
  //     data.push(record);
  //   }
  //   var output = data;
  //   output;
  // }

  static async getBranchesList(regions) {
    let token = "";
    const session = await AuthBloc.checkSession();
    if (session) {
      token = session.accessToken;
    }

    let response = await GeneralWebService.getBranchList(token, regions);
    let data = [];
    if (response.success) {
      for (let i = 0; i < response.data.resultColumns[0].list.length; i++) {
        let record = {};

        let key = response.data.resultColumns[0]["list"][i];
        let value = response.data.resultColumns[1]["list"][i];
        record = {
          code: key,
          name: value,
          status: true,
        };
        data.push(record);
      }
    }
    return data;
  }

  static async getSearchCriteriaList(regions, id) {
    let token = "";
    const session = await AuthBloc.checkSession();
    if (session) {
      token = session.accessToken;
    }

    let response = await GeneralWebService.getSearchCriteriaList(
      token,
      regions,
      id
    );

    let data = [];
    if (response.success) {
      // let record = {};
      for (let i = 0; i < response.data.resultColumns[0].list.length; i++) {
        let record = {};
        for (let j = 0; j < response.data.resultColumns.length; j++) {
          record[response.data.resultColumns[j]["key"]] =
            response.data.resultColumns[j]["list"][i];

          record.status = false;
        }
        data.push(record);
      }
    }
    return data;
  }

  static async getAgentLists(regions, branches) {
    let token = "";
    const session = await AuthBloc.checkSession();
    if (session) {
      token = session.accessToken;
    }

    let response = await GeneralWebService.getAgentList(
      token,
      regions,
      branches
    );

    let data = [];
    if (response.success) {
      for (let i = 0; i < response.data.length; i++) {
        let record = {};
        record = {
          code: response.data[i].id,
          name: response.data[i].name,
          status: true,
        };
        data.push(record);
      }
    }
    return data;
  }

  static getRegionSelectionsFromUserInfo(userInfo) {
    let regions = [];
    for (let i = 0; i < userInfo.roles.length; i++) {
      let userRole = userInfo.roles[i];
      let record = {};
      record = {
        code: userRole.region,
        name: userRole.regionName,
        status: false,
      };
      regions.push(record);
    }
    return regions;
  }

  static getRegionsFromUserInfo(userInfo) {
    let regions = [];
    for (let i = 0; i < userInfo.roles.length; i++) {
      let userRole = userInfo.roles[i];
      regions.push(userRole.region);
    }
    return regions;
  }

  static async getDateRangeFromConfig(userConfig, module) {
    let dateRangeSetting =
      (await UserAccessBloc.getUserDateRangeForModule(
        null,
        null,
        module,
        userConfig
      )) || {};
    let options = dateRangeSetting.options || [];
    let captions = [];
    let codes = [];
    for (var i = 0; i < options.length; i++) {
      let opt = options[i];
      let caption = opt.title;
      let code = opt.code;
      captions.push(caption);
      codes.push(code);
    }

    return {
      captions: captions,
      codes: codes,
      default: dateRangeSetting.default,
      defaultPlaceholder: dateRangeSetting.defaultPlaceholder,
    };
  }

  static mergeIntoInitialName(name) {
    let stringArray = name.split(" ");
    let first = stringArray[0] ? stringArray[0].charAt(0) : "";
    let last = stringArray[stringArray.length - 1]
      ? stringArray[stringArray.length - 1].charAt(0)
      : "";
    return [first, last];
  }

  static convertToCurrencyRM(amount) {
    const formatter = new Intl.NumberFormat('ms-MY', { style: 'currency', currency: 'MYR' });
    const formattedAmount = formatter.format(amount);

    return  formattedAmount;
  }

  static showCurrentModule(routePath) {
    let urlRoutes = Config.routes.generic;
    let routes = Object.values(urlRoutes);
    let indexOfActiveKey = 0;
    routePath.forEach((item) => {
      routes.map((route, position) => {
        if (route == item) {
          indexOfActiveKey = position;
        }
      });
    });
    return routes[indexOfActiveKey];
  }
}

export default GeneralBloc;
