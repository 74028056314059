import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import DashboardBloc from "../../../bloc/home_bloc";
import Config from "../../../Config";
import "./app_news_card.css";

class AppNewsCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Link
          to={`/${this.props.client}/${this.props.route}/${Config.routes.generic.announcement}/${this.props.data.id}`}
          style={{ color: "black" }}
        >
          <div
            className="news-card w-full"
          >
            <div className="news-card__title">{this.props.data.title}</div>
            <div className="news-card__content-wrapper">
              <div className="pt-2">
                 <div className="news-card__short-content">
                   {this.props.data.content}
                  </div>
                <div className="news-card__timestamp">
                  {this.props.data.timestamp}
                  {this.props.key}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  }
}

export default withRouter(AppNewsCard);
