import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_claim_status.css";

class AppClaimStatusSkeleton extends Component {
  constructor(props) {
    super(props);
  }

  propIsLoading(){
    return this.props.loading || false;
  }

  render() {
    //console.log("Render status with item = " + JSON.stringify(this.props.data));
    return (
      <>
        <div className="claim-status-skeleton">
          <div className="claim-status__title">
            {"    "}
          </div>
          <div className="d-flex align-items-center">
            <div
              className="mr-3"
              width="32px"
              height="32px"
              color=""
            />
            <div className="claim-status__data-count">
              {""}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AppClaimStatusSkeleton);
