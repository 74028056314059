import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
  errmsg: null,
  result: {
    id: "",
    name: "",
    desp: "",
    criFormPage: null,
    entryFormPage: null,
    recCount: null,
    rowsOfPage: null,
    resultColumns: [
      {
        displayFlex: null,
        sortCondition: null,
        caption: "",
        captionGroup: null,
        key: "",
        nextLink: "",
        seq: 1,
        list: [],
      },
      {
        displayFlex: null,
        sortCondition: null,
        caption: "",
        captionGroup: null,
        key: "",
        nextLink: "",
        seq: 1,
        list: [],
      },
    ],
  },
  errcode: null,
});

export const searchByReducer = createSlice({
  name: "searchBy",
  initialState,
  reducers: {
    setSearchBy: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = searchByReducer;
export const { setSearchBy } = actions;

export default reducer;
