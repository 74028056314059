import React, { Component } from "react";
import AppClaimStatusBean from "../data/bean/app_claim_status_bean";
import AppHeaderBean from "../data/bean/app_header_bean";
import AppTableStatus from "../ui/component/app_table_status/app_table_status";
import ClaimWebServices from "../service/claim_web_services";
import HomeWebService from "../service/home_web_service";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";
import DataResult from "../data/bean/data_result";
import { ReactComponent as ProgressClaimIcon } from "../ProgressClaim.svg";
import { ReactComponent as ReceivedClaimIcon } from "../ReceivedClaim.svg";
import { ReactComponent as ApprovedIcon } from "../Approved.svg";

class ClaimsBloc extends Component {
  static getStatusTrackingDetailsList(list) {
    let newList = [];
    if (list) {
      list.map((ev, index) => {
        newList.push({
          title: <div style={{fontSize: 10, color:"#9599B6"}}>{ev.date}</div>,
          description:  <div style={{fontSize: 14, color:"#000000"}}>{ev.description}</div>,
          icon: ev.description == "Claim has been submitted."? 
          <ReceivedClaimIcon style={{borderRadius: 4, width: 20, height: 20}}/> 
          : ev.description ==  "Please contact our Customer Care" ? 
          <img
          height={20}
          width={20}
          style={{borderRadius: 4}}
          src={
            GeneralBloc.getAssetsContextPath() +
            `/assets/icons/Please contact our Customer Care.svg`
          }
          alt=""
        />
            :
            ev.description == "Claim is currently being processed." ? 
            <img
           
            src={
              GeneralBloc.getAssetsContextPath() +
              `/assets/icons/VerticalLine.svg`
            }
            alt=""
          />
          :
          <img
          width={20}
          height={20}
          style={{borderRadius: 4}}
          src={
            GeneralBloc.getAssetsContextPath() +
            `/assets/icons/${ev.description}.svg`
          }
          alt=""
        />
        });
      });
    }
    return newList;
  }
  

  static async downloadFile(module, id) {
    let resp = await HomeWebService.downloadFile(module, id);
    if (resp.data) {
      return new DataResult(true, resp.message, resp);
    } else {
      return new DataResult(false, resp.message, null);
    }
  }

  static async getSubmittedDocuments(docClass, recMap) {
    let resp = await ClaimWebServices.getSubmittedDocuments(docClass, recMap);
    if (resp.success) {
      return new DataResult(true, resp.message, resp.data);
    } else {
      return new DataResult(false, resp.message, null);
    }
  }

  static async getClaimStatuses(
    entity,
    module,
    moduleId,
    dateFilter,
    fromDate,
    toDate,
    searchBy,
    keyword,
    regions,
    branches,
    agents
  ) {
    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let payload = {
      "proposal-dt-range": dateFilter,
      dtFrom: getDateFilter.beginDate,
      dtTo: getDateFilter.endDate,
      "proposal-attr-search": searchBy,
      searchBy: keyword,
      regions: regions,
      branches: branches,
      agents: agents,
    };

    if (dateFilter != "range") {
      delete payload.dtFrom;
      delete payload.dtTo;
    }

    let response = await GeneralBloc.getStatusBox(
      module,
      moduleId,
      false,
      false,
      payload
    );
    return response;
  }

  static getUserData(name, role, branch) {
    return new AppHeaderBean(name, role, branch);
  }

  static async getClaimDataTable(
    entity,
    dateFilter,
    fromDate,
    toDate,
    regions,
    page,
    searchBy,
    keyword,
    branches,
    agents
  ) {
    let dataProposal = [];
    let collumnTable = [];
    let totalData = 0;

    let token = "";
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let getDateFilter = GeneralBloc.getFilteredByDate(
      dateFilter,
      fromDate,
      toDate
    );

    let response = await ClaimWebServices.getClaim(
      token,
      entity,
      page,
      getDateFilter.beginDate,
      getDateFilter.endDate,
      regions,
      searchBy,
      keyword,
      branches,
      agents,
      dateFilter
    );

    let dataSet = {};
    if (response.success) {
      dataSet = await GeneralBloc.getListingTable(response, "claim");
    }
    //console.log(totalData);
    let result = {
      dataCollumn: dataSet.columnTable || [],
      dataProposal: dataSet.data || [],
      totalData: dataSet.total || [],
    };
    return result;
  }

  static async getClaimDetail(entity, recMap) {
    let token = "";
    let result = {};
    const session = await AuthBloc.checkSession();

    if (session) {
      token = session.accessToken;
    }

    let response = await ClaimWebServices.getClaimDetail(token, entity, recMap);

    if (response.success) {
      result = response.data;
    }
    return result;
  }
  //

  static getDummyClaimsStatuses() {
    return [
      new AppClaimStatusBean("Received.svg", "Submitted", 30, "submitted"),
      new AppClaimStatusBean("InProgress.svg", "Processing", 30, "processing"),
      new AppClaimStatusBean("Approved.svg", "Approved", 100, "approved"),
      new AppClaimStatusBean(
        "Mixed Decisions.svg",
        "Claim Paid",
        20,
        "claim-paid"
      ),
      new AppClaimStatusBean("Rejected.svg", "Rejected", 15, "rejected"),
    ];
  }

  static getCollumnTable() {
    return [
      {
        title: "Claim Submission Date",
        dataIndex: "claimSubmissionDate",
      },

      {
        title: "Claim Ref No.",
        dataIndex: "claimRefNo",
      },
      {
        title: "Covered Person Name",
        dataIndex: "coveredPersonName",
      },
      {
        title: "Covered Person ID",
        dataIndex: "coveredPersonID",
      },
      {
        title: "Certificate Number",
        dataIndex: "certificateNumber",
      },
      {
        title: "Product Name",
        dataIndex: "productName",
      },
      {
        title: "Claim Status",
        dataIndex: "claimStatus",
      },
    ];
  }
  static getDummyClaimsDataTable() {
    return [
      {
        key: "1",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: <AppTableStatus statusName="Rejected" status={"red"} />,
      },
      {
        key: "2",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "Loss of Life",
        claimStatus: <AppTableStatus statusName="Submitted" status={"blue"} />,
      },
      {
        key: "3",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "Great Lady Rider",
        claimStatus: (
          <AppTableStatus statusName="Processing" status={"yellow"} />
        ),
      },
      {
        key: "4",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: <AppTableStatus statusName="Approved" status={"green"} />,
      },
      {
        key: "5",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: (
          <AppTableStatus statusName="Claim Paid" status={"orange"} />
        ),
      },
      {
        key: "6",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: <AppTableStatus statusName="Rejected" status={"red"} />,
      },
      {
        key: "7",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: <AppTableStatus statusName="Submitted" status={"blue"} />,
      },
      {
        key: "8",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: <AppTableStatus statusName="Approved" status={"green"} />,
      },
      {
        key: "9",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: <AppTableStatus statusName="Submitted" status={"blue"} />,
      },
      {
        key: "10",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: (
          <AppTableStatus statusName="Claim Paid" status={"orange"} />
        ),
      },
      {
        key: "11",
        claimSubmissionDate: "25/01/2022",
        claimRefNo: "174739300",
        coveredPersonName: "Carol Ong Lee Choo",
        coveredPersonID: 50783259,
        certificateNumber: "1203911290",
        productName: "FWD Protect Direct",
        claimStatus: (
          <AppTableStatus statusName="Processing" status={"yellow"} />
        ),
      },
    ];
  }
  static getDummyUserData() {
    return new AppHeaderBean(
      "Janice Wong",
      "Premier Financial Manager",
      "HSBC Amanah Takaful"
    );
  }
}

export default ClaimsBloc;
