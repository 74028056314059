import HomeWebService from "../service/home_web_service";
import DataResult from "../data/bean/data_result";
import GeneralWebService from "../service/general_web_service";
import AuthBloc from "./auth_bloc";
import GeneralBloc from "./general_bloc";

export default class UtilBloc {

    //Check is value is not null && not undefined
    static isValid(value) {
        if (value !== undefined) return true;
        if (value !== null) return true;
        return false;
    }

    //Check if value is null or empty
    static isNullOrEmpty(value) {
        return value === undefined || !value || value === null || value === '' || value === 'undefined';
    }

    //Covert string to capitalize
    static capitalize(string) {
        return string[0].toUpperCase() + string.substr(1).toLowerCase()
    }

    //Format number
    static formatNumber(num) {
        let value = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        if (value === "NaN") {
          return 0;
        }
        return value;
    }

    static getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    
    //Format number with commas
    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static downloadBinary(response, filename) {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response]))
        a.download = filename
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
    }

    static truncateString(str, num) {
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (str.length <= num) {
          return str;
        }
        // Return str truncated with '...' concatenated to the end of str.
        return str.slice(0, num) + "...";
    }


    static addFirstItemInArray(arr,object) {                      
        return [object].concat(arr)
    }

    static async downloadFile(module,id) {                
        let resp = await HomeWebService.downloadFile(module,id);    
        if (resp.data) {            
            return new DataResult(true, resp.message, resp);
        } else {
            return new DataResult(false, resp.message, null);
        }
    }
    
    static async exportFileByModule(
        entity,
        dateFilter,
        fromDate,
        toDate,
        regions,
        page,
        searchBy,
        keyword,
        branches,
        agents,
        idModuleListing
      ) {                
        let token = "";
        const session = await AuthBloc.checkSession();
        
        if (session) {
          token = session.accessToken;
        }
    
        let getDateFilter = GeneralBloc.getFilteredByDate(
          dateFilter,
          fromDate,
          toDate
        );
    
        let response = await GeneralWebService.exportFileByModule(
          token,
          entity,
          page,
          getDateFilter.beginDate,
          getDateFilter.endDate,
          regions,
          searchBy,
          keyword,
          branches,
          agents,
          idModuleListing,
          dateFilter
        );

        return response;
      }

    static validatePassword(value, name) {
        let expregular = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#\$%\^&\*])(?=.{8,})";
        let error = null;
        if (!value) {
          error = `Please enter ${name} password.`;
        } else if (!value.match(expregular)) {
          error = "Password must contain between 8-16 characters, with upper case, lower case, special character and numeric characters.";
        }
        return error
    }

    static validateUserName(value, name) {        
        let error = null;
        if (!value) {
          error = `Please enter user name`;
        }
        return error
    }

    static validateField(value, name) {        
        let error = null;
        if (!value) {
          error = `Please enter ${name}`;
        }
        return error
    }
    
    //compareDateToCurrentDate (yyyy-mm-dd)
    static compareDateToCurrentDate(date){
      var today = new Date().setHours(0,0,0,0);
      var eventOn = new Date(date).setHours(0,0,0,0);
      let type="";
      if(today < eventOn){
        type="incoming";         
      }else if(today > eventOn){
        type="pass";         
      }else{
        type="today";        
      }
      return type;
    }

    static replaceBulk( str, findArray, replaceArray ){
      var i, regex = [], map = {}; 
      for( i=0; i<findArray.length; i++ ){ 
        regex.push( findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1') );
        map[findArray[i]] = replaceArray[i]; 
      }
      regex = regex.join('|');
      str = str.replace( new RegExp( regex, 'g' ), function(matched){
        return map[matched];
      });
      return str;
    }
    //field need to search , and value 
    static findItemInArray(list,field,value){
      let item=[];
      item = list.filter(
        function (ev) {
          return ev[field]==value;
        }
      );
      return item
    }
    
}
