import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AnnouncementsDetailBloc from "../../../bloc/announcements_detail_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import App_announcements_detail from "../../component/app_announcements_detail/app_announcements_detail";
import App_header from "../../component/app_header/app_header";
import App_header_title_simple from "../../component/app_header_title/app_header_title_simple";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import { connect } from "react-redux";
import Config from "../../../Config";
import HomeBloc from "../../../bloc/home_bloc";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import AnnouncementsBloc from "../../../bloc/announcements_bloc";
import Base_screen from "../base/base_screen/base_screen";

class AnnouncementsDetailScreen extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      client: Config.context,
      route: routeParams,
      announcementDetails: null,
      userData: {},
      loading: true
    };
  }

  stateItemId() { return this.state.route.itemId };
  stateAnnouncementDetails() { return this.state.announcementDetails };

  async componentDidMount() {
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  async getAnnouncementDetails() {
    let announcementDetails = await AnnouncementsBloc.getAnnouncementDetails(
      this.props.userGlobal.roles[0].entity,
      this.stateItemId()
    )
    let userData = this.props.userDataGlobal.data; 
    this.setState({
      announcementDetails: announcementDetails,
      userData,
      loading: false
    });
  }

  async init() {
    this.getAnnouncementDetails()
  }

  render() {
    return (
      <>
        <Base_screen
          listener={() => {
            this.init()
          }}
        >
          {this.state.loading && this.loadingContent()}
          <App_header
            data={this.state.userData}
            route={this.state.route.entity}
          />
          <App_sidebar route={this.state.route.entity}>
            <App_header_title_simple title="Announcement Detail" />
            <div className="mt-4">
              {
                this.stateAnnouncementDetails() ? <App_announcements_detail
                  data={this.stateAnnouncementDetails()}
                /> : null
              }
            </div>
          </App_sidebar>
        </Base_screen>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
  globalFilterBy: state.filter.value,
  globalSearchBy: state.searchBy.value,
  userConfigGlobal: state.userConfig.value,
  userDataGlobal: state.userData.value
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnnouncementsDetailScreen)
);

