import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
  filterApply: {},
});

export const filterDialogReducer = createSlice({
  name: "filterDialog",
  initialState,
  reducers: {
    setFilterDialog: (state, action) => {
      state.value = {
        ...initialState,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = filterDialogReducer;
export const { setFilterDialog } = actions;

export default reducer;
