import { Avatar, Dropdown, Menu } from "antd";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import GeneralBloc from "../../../bloc/general_bloc";
import "./app_header.css";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import { connect } from "react-redux";
import { ReactComponent as ProfileIcon } from "../../../Account.svg";
import { ReactComponent as SwitchIcon } from "../../../Switch.svg";
import AuthBloc from "../../../bloc/auth_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import Config from "../../../Config";
import { logOut, setAuth } from "../../../data/redux/reducers/authReducer";
import { ReactComponent as LogoutIcon } from "../../../Logout.svg";

class AppHeader extends Component {
  constructor(props) {
    super(props);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      client: Config.context,
      route: routeParams,
      userData: null,
      loading: false,
    };
  }

  stateUserData() {
    return this.state.userData;
  }
  stateEntityName() {
    return this.state.userData.roles[0].entityName || "";
  }

  componentDidMount() {
    let userData = AuthBloc.getDataUser();
    this.setState({
      userData,
    });    
  }

  logoByEntity() {    
    let basePath = GeneralBloc.getAssetsContextPath() + "/assets/images/Logo/";
    let hsbcEntity = "";
    let allianceEntity = "";    
    if(this.props.userGlobal.availBanks && this.props.userGlobal.availBanks.length >0){
      this.props.userGlobal.availBanks.forEach((element) => {
        if (element.id == Config.entity.hsbc.code) {
          hsbcEntity = element.name;
        }
        if (element.id == Config.entity.alliance.code) {
          allianceEntity = element.name;
        }
      });
    } else {
      this.props.userGlobal.roles.forEach((element) => {
        if (element.entity == Config.entity.hsbc.code) {
          hsbcEntity = element.entity;
        }
        if (element.entity == Config.entity.alliance.code) {
          allianceEntity = element.entity;
        }
      });
    }
    
    if (this.state.route.entity == hsbcEntity) {
      basePath = basePath + Config.entity.hsbc.logo;
    } else if (this.state.route.entity == allianceEntity) {
      basePath = basePath + Config.entity.alliance.logo;
    } else {
      hsbcEntity.length > 0
        ? (basePath = basePath + Config.entity.hsbc.logo)
        : (basePath = basePath + Config.entity.alliance.logo);
    }    
    return basePath;
  }

  companyNameByEntity() {
    switch (this.props.userGlobal.roles[0].entity) {
      case Config.entity.hsbc.code:
        return "HSBC Amanah";
      case Config.entity.alliance.code:
        return "Alliance Islamic Bank Berhad";
      default:
        return null;
    }
  }

  getInitial(name) {
    let result = GeneralBloc.mergeIntoInitialName(name);
    if(result){
      return result;
    }    
    return "";
  }

  redirectToLogin(){
    let godMode=AuthBloc.getGodMode()    
    if(godMode===true || godMode==="true"){      
      window.location.href = `/${Config.context}/login-admin`;
      localStorage.removeItem("godMode");
    } else {
      window.location.href = `/${Config.context}/login`;
    }  
  }

  async logOutPortal() {
    this.setState({
      loading: true,
    });
    AuthBloc.logOutPortal()
      .then((result) => {
        this.redirectToLogin();            
      })
      .catch((e) => {
        this.redirectToLogin(); 
      });
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  getDropDownItemDependOnRole(){
    let items=[];

    items.push(
      {
        label: (
          <a
            href={`/${this.state.client}/${this.props.route}/${Config.routes.generic.profile}`}
            className="header-title__dropdown-item"
          >
            View Profile
          </a>
        ),
        key: "0",
        icon: <ProfileIcon />,
      }
    )

    if(AuthBloc.isHeadOfBanca(this.props.userGlobal.roles[0].id)){
      items.push(
        {
          label: (
            <a
              href={`/${this.state.client}/${Config.routes.generic.account}`}
              className="header-title__dropdown-item"
            >
              Switch Bank
            </a>
          ),
          key: "1",
          icon: <SwitchIcon style={{ marginLeft: -5 }}/>,
        },
      )
    }
    
    items.push(
      {
        label: (
          <a
            className="header-title__dropdown-item"
            rel="noopener noreferrer"
            /* href="/portal" */
            onClick={() => this.logOutPortal()}
          >
            Logout
          </a>
        ),
        icon: <LogoutIcon style={{ marginLeft: -5 }} />,
        key: "2",
      }
    )

  return items;
  }

  render() {
    return (
      <>
        {this.state.loading && this.loadingContent()}
        <div className="header">
          <nav>
            <ul className="menu">
              <li>
                <Link
                  to={`/${this.state.client}/${this.props.route}/${Config.routes.generic.home}`}
                >
                  <img
                    className="logo_bank_icon"
                    src={
                      GeneralBloc.getAssetsContextPath() +
                      `/assets/images/Logo/FWDLogo.png`
                    }
                    alt="FWD Logo"
                  />
                </Link>
                {this.stateUserData() ? (
                  <img
                    className=""
                    src={this.logoByEntity()}
                    height={this.logoByEntity() == "/portal/assets/images/Logo/AllianceLogo.png" ? 46 : 36}
                    width={this.logoByEntity() == "/portal/assets/images/Logo/AllianceLogo.png" ? 136: 126}
                    style={{
                      marginLeft: 35.68,                      
                      fontWeight: 400,
                    }}
                  />
                ) : null}
              </li>
              <div className="d-flex">
                <li>
                  <Dropdown
                    overlay={
                      <Menu
                        style={{
                          marginTop: "12px",
                        }}
                        items={this.getDropDownItemDependOnRole()}
                      />
                    }
                    trigger={["click"]}
                    className="header__profile"
                  >
                    <div className="user-info d-flex align-items-center justify-content-around">  
                      {/* Error in IPAD here */}                    
                      <Avatar
                        className="d-flex justify-content-center"
                        style={{
                          color: "#EE7623",
                          backgroundColor: "#F2F2F2",
                          marginRight: 12,
                        }}
                        size={40}
                      >
                        <div
                          className="d-flex justify-content-center"
                          style={{ fontSize: 18 }}
                        >
                          {" "}
                          {this.getInitial(this.props.userGlobal.displayName)}
                        </div>
                      </Avatar>

                      <div className="mr-3">
                        <div className="user-info__name">
                          {this.props.userGlobal.displayName}
                        </div>
                        { this.props.route ? 
                          (Config.entity[this.props.route.toLowerCase()].designationTitle===true || AuthBloc.isHeadOfBanca(this.props.userGlobal.roles[0].id)) && <div className="user-info__role">
                          {this.props.userGlobal.designationTitle}
                          </div> :null
                        }                        
                        <div className="user-info__role">
                          {this.companyNameByEntity()}
                        </div>
                      </div>
                      {/* Error in IPAD here */} 
                      <img
                        style={{ cursor: "pointer" }}
                        src={
                          GeneralBloc.getAssetsContextPath() +
                          `/assets/icons/ArrowDownIcon.svg`
                        }
                      />
                      
                    </div>
                  </Dropdown>
                </li>
              </div>
            </ul>
          </nav>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
});

const mapDispatchToProps = { setAuth, logOut };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppHeader)
);
