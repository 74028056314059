import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({});

export const dateFilterReducer = createSlice({
  name: "dateFilter",
  initialState,
  reducers: {
    setDateFilter: (state, action) => {
      state.value = {
        ...initialState,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = dateFilterReducer;
export const { setDateFilter } = actions;

export default reducer;
