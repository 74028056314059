class AppCarouselBean {
  constructor(id, date, title, desc, image) {
    this.id = id;
    this.date = date;
    this.title = title;
    this.desc = desc;
    this.image = image;
  }
}

export default AppCarouselBean;
