import { Col, Collapse, Row } from "antd";
import React from "react";
import AppContentFieldItem2 from "./app_content_field_item2";
import AppContentFieldItem4 from "./app_content_field_item4";
import "./fund_details_card.css";

const { Panel } = Collapse;

class FundDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  stateData() {
    return this.state.data;
  }

  stateFundDetails() {
    return this.stateData();
  }

  componentDidMount() {}

  renderFundDetails(data) {
    if (!data || data.length <= 0) return null;

    let children = [];
    for (var i = 0; i < data.length; i++) {
      let fundList = data[i];
      children.push(this.renderRow(this.renderFieldTitle(fundList.name)));
      children.push(this.renderRow(this.renderField(fundList.allocation)));
      children.push(this.renderRow(this.renderField(fundList.unit)));
      children.push(this.renderRow(this.renderField(fundList.pricing)));
    }
    return children;
  }

  renderField(field) {
    if (field)
      return (
        <AppContentFieldItem2
          title={field.caption}
          value={
            field.value == "" || field.value == 0.0 || field.value == null
              ? "N/A"
              : field.value
          }
        />
      );

    return null;
  }

  renderFieldTitle(field) {
    if (field)
      return (
        <AppContentFieldItem4
          title={field.caption}
          value={
            field.value == "" || field.value == 0.0 || field.value == null
              ? "N/A"
              : field.value
          }
        />
      );

    return null;
  }

  renderRow(item1) {
    return (
      <div>
        <Col>{item1}</Col>
      </div>
    );
  }

  render() {
    return (
      <div
        className="fund-detail-card"
        bordered={false}
        expandIconPosition={"end"}
        style={{
          backgroundColor:
            this.stateFundDetails() && this.stateFundDetails().length >= 0
              ? "white"
              : "transparent",
          marginBottom: 16,
          marginRight: 16,
          display: this.stateFundDetails().length <= 0 ? "none" : "block",
        }}
        defaultActiveKey={[1]}
      >
        <div
          key={1}
          // header={this.props.caption || ""}
          className="fund-detail-title"
        >
          {this.props.caption && this.state.data.length > 0
            ? this.props.caption
            : ""}
          {this.renderFundDetails(this.stateFundDetails())}
        </div>
        <div className="fund-detail-disclaimer">
         
          {this.props.disclaimer ? `Disclaimer: The pricing for funds is updated as at ${this.props.disclaimer}` :
           ""
           }
        </div>
      </div>
    );
  }
}

export default FundDetailsCard;