import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ClaimsBloc from "../../../bloc/claims_bloc";
import FormsBloc from "../../../bloc/forms_bloc";
import RouteBloc from "../../../bloc/route_bloc";
import App_claim_status_mini from "../../component/app_claim_status/app_claim_status_mini";
import App_dropdown_filter2 from "../../component/app_filters/app_dropdown_filter2";
import App_search_filter from "../../component/app_filters/app_search_filter";
import App_header from "../../component/app_header/app_header";
import App_header_title_filter from "../../component/app_header_title/app_header_title_filter";
import App_sidebar from "../../component/app_sidebar/app_sidebar";
import App_table from "../../component/app_table/app_table";
import AppHeaderTitleSimple from "../../component/app_header_title/app_header_title_simple";
import ProposalBloc from "../../../bloc/proposal_bloc";
import { connect } from "react-redux";
import { setFilterBy } from "../../../data/redux/reducers/filterReducer";
import AppEmptyData from "../../component/app_empty_data/app_empty_data";
import Config from "../../../Config";
import AppLoadingDialog from "../../dialog/app_loading_dialog";
import UserAccessBloc from "../../../bloc/user_access_bloc";
import "./forms_screen.css";
import UtilBloc from "../../../bloc/util_bloc";
import Base_screen from "../base/base_screen/base_screen";
import GeneralBloc from "../../../bloc/general_bloc";

class FormsScreen extends Component {
  constructor(props) {
    super(props);
    this.handleOnClickSearch = this.handleOnClickSearch.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.viewOrDownloadFile = this.viewOrDownloadFile.bind(this);
    let routeParams = RouteBloc.extractRouteParamsFromProps(props);
    this.state = {
      route: routeParams,
      formsStatuses: [],
      formData: {
        dataCollumn: [],
        dataClaim: [],
        totalData: 0,
      },
      userData: {},
      keyword: "",
      searchBy: "",
      currentPage: 1,
      categories: [],
      module: "form"
    };
  }

  componentDidMount() {
    this.setState({
      claimStatuses: [],
    });
    
  }

  async fetchListing() {
    this.setState({
      loading: true,
    });

    let formData;
    formData = await FormsBloc.getFormDataTable(
      this.props.userGlobal.roles[0].entity,
      null,
      null,
      null,
      null,
      this.state.currentPage || 1,
      this.state.searchBy,
      this.state.keyword,
      null,
      null
    );
    let formFilter=[];
    let userSetting=UserAccessBloc.getUserSettings();
    formFilter=GeneralBloc.getModuleDetailsFromUserSetting(userSetting,"form");
    
    let filter=formFilter.list.filters.formCategory;
    let newCategories=[];
    filter.map((item)=>{
      newCategories.push(item.code)
    })
    let categories = UtilBloc.addFirstItemInArray(newCategories,"All");
    let userData = this.props.userDataGlobal.data;
    this.setState(
      {
        loading: false,
        formData: formData,
        categories: categories,
        userData
      }
    );
  }

  async handleFilterSearch(filterSearchValue) {
    await this.setState({
      ...this.state,
      searchBy: filterSearchValue == "All" ? "" : filterSearchValue,
    });
    await this.fetchListing();
  }

  async handleOnClickSearch() {  
    if (this.state.keyword.length > 3 || UtilBloc.isNullOrEmpty(this.state.keyword)) {
      this.fetchListing();
    }
  }

  handleChangeKeyword(keyword) {
    this.setState({
      ...this.state,
      keyword: keyword,
    });
  }

  async handleChangePage(selectedPage) {
    this.setState(
      {
        currentPage: selectedPage,
      },
      this.fetchListing
    );
  }

  loadingContent() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AppLoadingDialog isLoading={this.state.loading} />
      </div>
    );
  }

  async viewOrDownloadFile(id,name,contentType) {
    this.setState({
      loading: true
    })
    let result = await UtilBloc.downloadFile("form", id)
    if (result.success) {
      this.setState({
        loading: false
      })
      let filename = `${name}.${contentType!= "N/A" ? contentType.toLowerCase() : "pdf"}`
      UtilBloc.downloadBinary(result.data.data, filename)
    }
  }

  async init() {
    await this.fetchListing();    
  }

  render() {
    return (
      <Base_screen
        listener={() => {
          this.init();
        }}
      >
        <div>
          {this.state.loading && this.loadingContent()}
          <App_header
            data={this.state.userData}
            route={this.state.route.entity}
          />
          <App_sidebar route={this.state.route.entity}>
            <AppHeaderTitleSimple title={"Forms"} />
            <div className="d-flex justify-content-between align-items-center mt-5 mb-4">
              <div>
                <App_dropdown_filter2
                  title={"Category:"}
                  onSelectedSearchFilter={this.handleFilterSearch}
                  dataSearchByCaption={
                    this.state.categories
                  }
                  dataSearchByValue={
                    this.state.categories
                  }
                  defaultValue={"All"}
                />
              </div>
              <div className="w-100">
                <App_search_filter
                  title={"Search"}
                  onChangeKeyword={this.handleChangeKeyword}
                  onClickSearch={this.handleOnClickSearch}
                />
              </div>
            </div>

            <div className="">
              {this.state.formData.dataCollumn.length ? (
                <App_table
                  data={this.state.formData.dataClaim}
                  collumn={this.state.formData.dataCollumn}
                  totalData={this.state.formData.totalData}
                  onSelectedPage={this.handleChangePage}
                  currentPage={this.state.currentPage}
                  handleDownloadOnClick={(record) => {                    
                    this.viewOrDownloadFile(record.form_id,record.file_name,record.contentType)
                  }}
                />
              ) : (
                <AppEmptyData />
              )}
            </div>
          </App_sidebar>
        </div>
      </Base_screen>
    );
  }
}

const mapStateToProps = (state) => ({
  userGlobal: state.user.value,
  globalFilterBy: state.filter.value,
  globalSearchBy: state.searchBy.value,
  userConfigGlobal: state.userConfig.value,
  userDataGlobal: state.userData.value
});

const mapDispatchToProps = { setFilterBy };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormsScreen)
);
